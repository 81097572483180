import { IIntervalCategories, IIntervalCategory } from "./model";

export const getIntervalCategory = (state: IStoreState, name: string): IIntervalCategory => (
    state.intervalCategories != null ? state.intervalCategories[name] : null
);

export const getIntervals = (state: IStoreState): IIntervalCategories => (
    state.intervalCategories != null ? state.intervalCategories : null
);

export const getIntervalCategoryEdit = (state: IStoreState, name: string): IIntervalCategory => (
    state.intervalCategoriesEdit != null ? state.intervalCategoriesEdit[name] : null
);