import { ajaxRequest, getLocalizedText, store, t, useIntervalAjaxRequests, useMapState } from "@comact/crc";
import { IQueryResults } from "js/common";
import DashboardExtraContext from "js/dashboards/dashboardsEdits/components/DashboardExtraContext";
import { IDashboardQuery, validateAggregation } from "js/dashboards/model";
import { ISort } from "js/dashboards/widgets/KpiResultsSortBySelector";
import _ from "lodash";
import * as React from "react";
import { API_PREFIX_PRODUCTION_MANAGER, PROJECT_NAME } from "../../constants";
import { IKpiQueryRecipes, IKpiQueryResultsResponse } from "./model";
import { getDashboardQuery, getOrderedResultsIdsByRecipes, makeAddTitleToQueryResults, makeResolveKpiQueryRecipes } from "./selectors";

/**
 * Get data from influx (with query recipes) at a specific interval.
 */
interface IUseGetKpiQueryResultsOptions {
    callerName: string;
    queryRecipes: IKpiQueryRecipes;
    titleFormula?: string;
    limit?: number;
    fill?: "null" | "none";
    intervalSpeed?: number;
    sort?: ISort | null;
    overrideDashboardQueries?: Partial<IDashboardQuery>;
}
export const useGetKpiQueryResults = ({
    callerName = "",
    queryRecipes,
    limit,
    titleFormula = null,
    fill = "null",
    intervalSpeed = 15 * 1000,
    sort = null,
    overrideDashboardQueries = {},
}: IUseGetKpiQueryResultsOptions): IKpiQueryResultsResponse => {
    const { dashboardExtra } = React.useContext(DashboardExtraContext);

    // Make selectors
    const addTitleToQueryResults = React.useMemo(makeAddTitleToQueryResults, []);
    const resolveKpiQueryRecipes = React.useMemo(makeResolveKpiQueryRecipes, []);

    const dashboardQuery = useMapState((state) => ({ ...getDashboardQuery({ state, dashboard: dashboardExtra }), ...overrideDashboardQueries }), [dashboardExtra, overrideDashboardQueries]);

    // Create the queries
    const kpiQueries = useMapState((state) => resolveKpiQueryRecipes({ state, kpiQueryRecipes: queryRecipes }) || [], [queryRecipes]);

    const [kpiQueryResults, setKpiQueryResults] = React.useState<IQueryResults[]>(null);
    const [error, setError] = React.useState<string>(null);
    const [ajaxError, setAjaxError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    // Add the missing titles
    const results = addTitleToQueryResults({ state: store.getState(), results: kpiQueryResults, titleFormula }); // we don't need to put this in a useMapState, it's called too often

    let finalCallerName = callerName;
    if (process.env.NODE_ENV != "production") {
        finalCallerName = callerName + "-" + queryRecipes.kpis.map((k) => k.definitionId ? k.patternKey + "-" + k.definitionId : k.patternKey).join(",");
        if (finalCallerName.length > 100) finalCallerName = finalCallerName.substring(0, 100) + "...";
    }

    useIntervalAjaxRequests({
        createAjaxRequests: () => {
            setLoading(true);

            const ajax = ajaxRequest({
                uniqueID: btoa(JSON.stringify(queryRecipes)) + finalCallerName, // create a unique ajax call for each different queryRecipes combination
                method: "POST",
                timeout: process.env.EXEC_MODE == "icp" ? 10 * 1000 : 5 * 1000,
                data: {
                    kpiQueries,
                    ...dashboardQuery,
                    limit: limit || 100000,
                    fill,
                },
                url: `${API_PREFIX_PRODUCTION_MANAGER}/queries/kpi?${finalCallerName}`,
                error: { description: t("core:errors.errorConnectionAPI", { name: PROJECT_NAME + " (useGetKpiQueryResults)" }) },
            });
            ajax.promise
                .then((rawResults) => {
                    setAjaxError(false);
                    setError(null);
                    setKpiQueryResults(sort ? getResultsOrdered(rawResults, sort, queryRecipes) : rawResults);
                })
                .catch((e) => {
                    if (e?.status == 0) { // no answer
                        setError(null);
                        setAjaxError(true);
                    } else {
                        let errorMessage: string;
                        try {
                            errorMessage = JSON.parse(e?.request?.response).message;
                        } catch {
                            errorMessage = t("widgets.genericError");
                        }
                        setError(errorMessage);
                        setAjaxError(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
            return ajax;
        },
        timeout: intervalSpeed,
        deps: [..._.values(dashboardQuery), sort, queryRecipes, limit, fill],
    });

    const previousResults = React.useRef<IQueryResults[]>();

    const errorMessage: string = error || validateAggregation(dashboardQuery.rangeType, dashboardQuery.aggregation, dashboardQuery.customRange)?.[0];

    if (!_.isEqual(previousResults.current, results)) {
        previousResults.current = results; // data has change, update
    }

    return { results: previousResults.current, errorMessage, ajaxError, loading };
};

/**
 * Get results ordered
 */
const getResultsOrdered = (kpiResults: IQueryResults[], sort: ISort, kpiQueryRecipes: IKpiQueryRecipes) => {
    const findResult = ({ kpiResults: res, kpiId, contextId, nodeId }: { kpiResults: IQueryResults[]; kpiId: string; contextId: string; nodeId: string; }) => {
        const [patternKey, definitionId] = kpiId.split(",");
        return res.find((r) => r.contextId == contextId && r.nodeId == nodeId && r.patternKey == patternKey && r.definitionId == definitionId);
    };

    const { kpiIds, contextIds, nodeIds } = getOrderedResultsIdsByRecipes({ kpiResults, kpiQueryRecipes, sortAsc: sort.asc });

    let ordered: IQueryResults[] = [];

    const orderDir = sort.asc ? "asc" : "desc";

    switch (sort.by) {
        case "context_node_kpi": {
            contextIds.forEach((contextId) => (
                nodeIds.forEach((nodeId) => (
                    kpiIds.forEach((kpiId) => ordered.push(findResult({ kpiResults, contextId, nodeId, kpiId })))
                ))
            ));
            break;
        }
        case "context_kpi_node": {
            contextIds.forEach((contextId) => (
                kpiIds.forEach((kpiId) => (
                    nodeIds.forEach((nodeId) => ordered.push(findResult({ kpiResults, contextId, nodeId, kpiId })))
                ))
            ));
            break;
        }
        case "node_context_kpi": {
            nodeIds.forEach((nodeId) => (
                contextIds.forEach((contextId) => (
                    kpiIds.forEach((kpiId) => ordered.push(findResult({ kpiResults, contextId, nodeId, kpiId })))
                ))
            ));
            break;
        }
        case "node_kpi_context": {
            nodeIds.forEach((nodeId) => (
                kpiIds.forEach((kpiId) => (
                    contextIds.forEach((contextId) => ordered.push(findResult({ kpiResults, contextId, nodeId, kpiId })))
                ))
            ));
            break;
        }
        case "kpi_node_context": {
            kpiIds.forEach((kpiId) => (
                nodeIds.forEach((nodeId) => (
                    contextIds.forEach((contextId) => ordered.push(findResult({ kpiResults, contextId, nodeId, kpiId })))
                ))
            ));
            break;
        }
        case "kpi_context_node": {
            kpiIds.forEach((kpiId) => (
                contextIds.forEach((contextId) => (
                    nodeIds.forEach((nodeId) => ordered.push(findResult({ kpiResults, contextId, nodeId, kpiId })))
                ))
            ));
            break;
        }
        case "nearTarget": {
            ordered = _.orderBy(kpiResults, (r) => {
                const { value, objective } = _.last(r.data);
                if (objective == undefined) return 1; // 100%
                const { target, min, max } = objective;

                const valueProgress = (value - min) / (max - min); // value in percent
                const targetProgress = (target - min) / (max - min); // target in percent
                return Math.abs(valueProgress - targetProgress);
            }, orderDir);
            break;
        }
        case "zone": {
            ordered = _.orderBy(kpiResults, ({ data }) => {
                const lastData = _.last(data);
                if (!lastData) return 0;
                const { objective, value } = lastData;
                const zones = objective?.zones || [];
                const clampedValue = _.clamp(value, objective?.min, objective?.max);
                const currentZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
                if (!currentZone) return 1; // like "empty"
                if (currentZone.value == "error") return 3;
                if (currentZone.value == "warn") return 2;
                if (currentZone.value == "empty") return 1;
                if (currentZone.value == "normal") return 0;
                return 0;
            }, orderDir);
            break;
        }
        case "value": {
            ordered = _.orderBy(kpiResults, (r) => _.last(r.data).value, orderDir);
            break;
        }
        case "name": {
            ordered = _.orderBy(kpiResults, [
                (r) => parseFloat(getLocalizedText(r.title)), // sort first as number
                (r) => getLocalizedText(r.title).toLocaleLowerCase(), // then sort as string
            ], [orderDir, orderDir]);
            break;
        }
        default: {
            return kpiResults; // no order... return original, should not happen
        }
    }

    return ordered;
};