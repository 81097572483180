import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IKpiDataIO, IKpiDataIOs } from "../model";
import { createNewKpiDataIO, IKpiDataIOEditionData } from "./model";

declare global {
    interface IStoreState {
        kpiDataIOsEditor: IKpiDataIOEditionData;
    }
}

const kpiDataIOsEditor = createSlice({
    name: "kpiDataIOsEditor",
    initialState: {} as IKpiDataIOEditionData,
    reducers: {
        setOne: (state, { payload }: PayloadAction<{ machineNodeId: string; id: string; kpiDataIO: Partial<IKpiDataIO>; }>) => {
            state[payload.machineNodeId][payload.id] = {
                ...state[payload.machineNodeId][payload.id],
                ...payload.kpiDataIO,
            };
        },
        createOne: (state, { payload }: PayloadAction<{ machineNodeId: string; }>) => {
            const { machineNodeId } = payload;
            const newKpiDataIO = createNewKpiDataIO({ machineNodeId });
            if (Object.keys(state[payload.machineNodeId]).length < 50) { // FIXME: Ici on limite a 50 car on peux juste avoir 50 dataIO par machine.
                state[machineNodeId][newKpiDataIO.id] = newKpiDataIO;
            }
        },
        deleteOne: (state, { payload }: PayloadAction<{ machineNodeId: string; id: string; }>) => {
            delete state[payload.machineNodeId][payload.id];
        },
        stopEdit: (state, { payload }: PayloadAction<{ machineNodeId: string; }>) => {
            delete state[payload.machineNodeId];
        },
        startEdit: (state, { payload }: PayloadAction<{ machineNodeId: string; kpiDataIOs: IKpiDataIOs; }>) => {
            state[payload.machineNodeId] = payload.kpiDataIOs;
        },
    },
});

export const actionsCreators = {
    ...kpiDataIOsEditor.actions,
};

export default {
    [kpiDataIOsEditor.name]: kpiDataIOsEditor.reducer,
};