import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDatabaseDefinition, IDatabaseDefinitions } from "./model";

declare global {
    interface IStoreState {
        databaseDefinitions: IDatabaseDefinitions;
    }
}
const useStats = "databaseDefinitions";
const isEqual = (a: IDatabaseDefinition, b: IDatabaseDefinition) => a.modificationStamp == b.modificationStamp;

const databaseDefinitions = createSlice({
    name: "databaseDefinitions",
    initialState: {} as IDatabaseDefinitions,
    reducers: {
        set: (state, { payload }: PayloadAction<IDatabaseDefinitions>) => (
            Utils.slices.set({ state, nextState: payload, isEqual, useStats })
        ),
    },
});

export const actionsCreators = {
    ...databaseDefinitions.actions,
};

export default {
    [databaseDefinitions.name]: databaseDefinitions.reducer,
};