import { Utils } from "@comact/crc";
import { IKpiPatternEditor, validateKpiPatternEditor } from "js/kpis/kpiPatternEditors/model";
import { getUnitDisplaySymbol } from "js/mUnits/selectors";
import _ from "lodash";
import { IMachineCodecNode, IScannerCodecNode, NodesModule } from "../../node";

export const getKpiPatternEditor = (state: IStoreState): IKpiPatternEditor => {
    const { kpiPatternEditor, databaseDefinitions, batchDefinitions, contextDefinitions, mUnits } = state;
    const machines = NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentNodeId, ["machineCodec"], true);
    if (kpiPatternEditor == null) return null;

    const chosenDatabaseDefinition = databaseDefinitions[kpiPatternEditor.database];
    const errors = validateKpiPatternEditor(state);
    const relativeTimeUnit = kpiPatternEditor.objectiveTimeBased ? "/" + kpiPatternEditor.objectiveTimeUnit[0].toLowerCase() : "";
    const unitShortText = getUnitDisplaySymbol(mUnits, kpiPatternEditor.unitType, kpiPatternEditor.unitLabel) + relativeTimeUnit;
    const isEditing = !state.kpiPatterns[kpiPatternEditor.id] || !Utils.object.isSameKeysEquals(state.kpiPatterns[kpiPatternEditor.id], kpiPatternEditor);
    const isNew = kpiPatternEditor.id == null || kpiPatternEditor.id.indexOf("new_") == 0;
    return {
        ...kpiPatternEditor,
        availableDatabaseDefinitions: databaseDefinitions,
        availableBatchDefinitions: batchDefinitions,
        contexts: contextDefinitions,
        machines,
        scanners: NodesModule.selectors.getNodesChildrenOfTypeRecursive<IScannerCodecNode>(state, state.currentNodeId, ["scannerCodec"], true),
        errors,
        canSubmit: _.isEmpty(errors),
        chosenDatabaseDefinition,
        unitShortText,
        isEditing,
        isNew,
    };
};