import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { getBatchesByTimeRange, IBatch, IBatchConfig, IBatchDefinition, IBatchDefinitions, IBatches } from "./model";

declare global {
    interface IStoreState {
        batches: IBatches;
        batchesIds: {
            last: string;
            current: string;
        };
        batchConfig: IBatchConfig;
        batchDefinitions: IBatchDefinitions;
    }
}

const useStatsBatches = "batches";

const batches = createSlice({
    name: "batches",
    initialState: {} as IBatches,
    reducers: {
        patch: (state, { payload }: PayloadAction<IBatch[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats: useStatsBatches })
        ),
        setLast: (state, { payload }: PayloadAction<IBatch>) => { // check the "batchesIds" slice
            state[payload.id] = payload;
        },
        setCurrent: (state, { payload }: PayloadAction<IBatch>) => { // check the "batchesIds" slice
            state[payload.id] = payload;
        },
        setByTimeRange: (state, { payload }: PayloadAction<{ batches: IBatches; begin: number; end: number; }>) => {
            // Add or update batches
            _.each(payload.batches, (batch) => {
                if (!state[batch.id] || !_.isEqual(state[batch.id], batch)) { // new or changed
                    state[batch.id] = batch;
                }
            });

            // Find batches in requested period
            const batchesInPeriod = getBatchesByTimeRange(state, payload.begin, payload.end);

            // Delete batches in update period if not in payload (probably deleted on server)
            _.each(batchesInPeriod, (batch) => {
                if (!payload.batches[batch.id]) { // old batch is not there anymore
                    delete state[batch.id]; // deleted
                }
            });
        },

    },
});

const batchesIds = createSlice({
    name: "batchesIds",
    initialState: { last: null, current: null } as IStoreState["batchesIds"],
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(batches.actions.setLast, (state, { payload }) => {
            state.last = payload.id;
        });
        builder.addCase(batches.actions.setCurrent, (state, { payload }) => {
            state.current = payload.id;
        });
    },
});

const batchConfig = createSlice({
    name: "batchConfig",
    initialState: null as IBatchConfig,
    reducers: {
        setBatchConfig: (state, { payload }: PayloadAction<IBatchConfig>) => Utils.slices.deepImmerUpdate(state, payload),
    },
});

const useStatsBatchDefinitions = "batchDefinitions";
const isEqual = (a: IBatchDefinition, b: IBatchDefinition) => a.timestamp == b.timestamp;

const batchDefinitions = createSlice({
    name: "batchDefinitions",
    initialState: null as IBatchDefinitions,
    reducers: {
        setBatchDefinitions: (state, { payload }: PayloadAction<IBatchDefinitions>) => (
            Utils.slices.set({ state, nextState: payload, isEqual, useStats: useStatsBatchDefinitions })
        ),
        patchBatchDefinitions: (state, { payload }: PayloadAction<IBatchDefinition[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats: useStatsBatchDefinitions })
        ),
        deleteBatchDefinitions: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: useStatsBatchDefinitions })
        ),
    },
});

export const actionsCreators = {
    ...batches.actions,
    ...batchConfig.actions,
    ...batchDefinitions.actions,
};

export default {
    [batchesIds.name]: batchesIds.reducer,
    [batches.name]: batches.reducer,
    [batchConfig.name]: batchConfig.reducer,
    [batchDefinitions.name]: batchDefinitions.reducer,
};