import { routing, useMapState, useMapStateMemoize } from "@comact/crc";
import { CSS, Card, Header, ListRow, Title, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import { NodesModule } from "..";
import { IMillNode } from "../model";
import Status, { StatusConnexion } from "./Status";

const MillCardStyled = styled(Card)`
    display: grid;
    grid-gap: ${CSS.gutter}px;
    grid-template-columns: 1fr min-content;
`;

const NodeLocationCard = React.memo(({ id }: { id: string; }) => {
    const name = useMapState((state) => state.nodes[id].name, [id]);
    const mills = useMapStateMemoize((state) => _.map(NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMillNode>(state, id, ["millCodec"]), (m) => ({ id: m.id, name: m.name })), [id]);
    return (
        <div key={id}>
            <Header kPad="squareHalf">
                <Title>{name}</Title>
                <ListRow kPad="none">
                    {_.map(mills, (mill) => (
                        <MillCardStyled key={mill.id} href={routing.createUrl(`/overview/${mill.id}`)} data-test={mill.id}>
                            <Title kSize="regular">{mill.name}</Title>
                            <div>
                                <StatusConnexion id={mill.id} />
                                <Status id={mill.id} showChildren />
                            </div>
                        </MillCardStyled>
                    ))}
                </ListRow>
            </Header>
        </div>
    );
});

export default NodeLocationCard;
