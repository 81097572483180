import { ajaxRequest, getThrottleAjax, t } from "@comact/crc";
import { API_PREFIX_REPORT_TEMPLATES, PROJECT_NAME } from "js/constants";
import _ from "lodash";
import { IReportConfigurationParameterChoices } from "../configurations";
import * as mocks from "./mocks";
import { IReportTemplate } from "./model";
import { actionsCreators } from "./slices";

export const getReportTemplates = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest<{}, IReportTemplate[]>({
        serverLessResolve: () => Promise.resolve(
            getState().reportTemplates != null ? _.values(getState().reportTemplates) : mocks.mockReportTemplates()
        ),
        url: `${API_PREFIX_REPORT_TEMPLATES}?limit=9999`,
        onSuccess: (reportTemplates: IReportTemplate[]) => {
            dispatch(actionsCreators.set(reportTemplates));
        },
    })
), 10 * 1000);

export const getDynamicReportParameterChoices = (templateId: string) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        url: `${API_PREFIX_REPORT_TEMPLATES}/dynamic-parameters/${templateId}`,
        error: { description: t("core:errors.errorConnectionAPI", { name: PROJECT_NAME + " (getDynamicReportParameterChoices) for template " + templateId }) },
        onSuccess: (choices: IReportConfigurationParameterChoices) => {
            dispatch(actionsCreators.setDynamicChoices({ templateId, choices }));
        },
    })
);