import { IKpiZoneValueList } from "js/kpis/kpiObjectives";
import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { getKpiPatterns } from "../kpiPatterns/mocks";
import {
    IAlertCondition,
    IAlertHandlerType,
    IKpiAlert,
    IKpiAlertHandler,
    alertHandlerTypes,
    alertPriorities,
    alertSeverities,
    limitConditionTypeHasTwoParams,
    limitTypes,
    plcSeverities
} from "./model";

const random = createSeeder("kpiAlert");

export const mockKpiAlerts = (): IKpiAlert[] => (
    _.times(24, (i) => mockAlert(i + 1))
);

const mockAlert = (i: number): IKpiAlert => {
    random.seed(`mockAlert_${i}`);
    return {
        id: `${i}`,
        name: { values: { en: "Mock Alert (en) #" + i, fr: "Alerte Mock (fr) #" + 1, pt: "Mock Alert (pt) #" + i, fi: "Mock Alert (fi) #" + i } },
        contextId: `${random.intBetween(1000000, 1000010)}`,
        nodeId: `${random.intBetween(1, 7)}`,
        enabled: i % 5 !== 0,
        system: i % 8 == 0,
        combinationStrategy: i % 2 == 0 ? "ALL_CONDITIONS_TRIGGERED" : "ANY_CONDITIONS_TRIGGERED",
        conditions: mockConditions(),
        handlers: mockHandlers(random.intBetween(0, alertHandlerTypes.length - 1)),
        cooldown: random.intBetween(10, 3600),
        delay: random.intBetween(10, 60),
    };
};

const mockConditions = (): IAlertCondition[] => {
    const nbConditions = random.intBetween(1, 3);
    return _.times(nbConditions, mockCondition);
};

const mockCondition = (): IAlertCondition => {
    const kpiPatterns = getKpiPatterns();
    const conditionType = random.intBetween(1, 2);
    const randomKpiPattern = _.values(kpiPatterns)[random.intBetween(0, _.size(kpiPatterns) - 1)];
    const randomKpiDefinition = _.values(randomKpiPattern.kpiDefinitions)[random.intBetween(0, _.size(randomKpiPattern.kpiDefinitions) - 1)];
    if (conditionType % 2 == 0) {
        // loop through each condition type one by one
        const values = Object.values(limitTypes);
        const limitType = values[conditionType % values.length];
        return {
            type: "limit",
            limitType,
            patternKey: randomKpiPattern.uniqueName,
            definitionId: randomKpiDefinition.id,
            param1: random.intBetween(10, 20),
            // 2nd param only for "inside-range" and "outside-range"
            param2: limitConditionTypeHasTwoParams(limitType) ? random.intBetween(20, 30) : null,
        };
    } else {
        const randZone = random.intBetween(0, IKpiZoneValueList.length - 1);
        return {
            type: "zone",
            patternKey: randomKpiPattern.uniqueName,
            definitionId: randomKpiDefinition.id,
            kpiZones: [IKpiZoneValueList[randZone]],
        };
    }
};

// always has the alert, plus a random choice
const mockHandlers = (i: number): IKpiAlertHandler[] => {
    const typeToMock = alertHandlerTypes[i];
    // if the random choses alert, we do ALL the handlers at once. Jackpot!
    if (typeToMock == "alarm") {
        return _.map(alertHandlerTypes, (type) => mockHandler(type));
    } else {
        return [mockHandler("alarm"), mockHandler(typeToMock)];
    }
};

const mockHandler = (type: IAlertHandlerType): IKpiAlertHandler => {
    switch (type) {
        default:
        case "alarm":
            return {
                type: "alarm",
                alarmTitle: { values: { en: "Alert $alert-name", fr: "Alerte $alert-name" } },
                alarmMessage: { values: { en: "Its bad!", fr: "ça va mal!" } },
                priority: alertPriorities[random.intBetween(0, alertPriorities.length - 1)],
                severity: alertSeverities[random.intBetween(0, alertSeverities.length - 1)],
            };
        case "email":
            return {
                type: "email",
                subject: { values: { en: "This is the subject for $alert-name", fr: "Ceci est le sujet pour $alert-name" } },
                message: { values: { en: "Alert message...", fr: "Le message..." } },
                recipients: ["1", "2", "3"],
            };
        case "plc": {
            return {
                type: "plc",
                index: random.intBetween(0, 31),
                message: { values: { en: "Plc Message: $alert-name", fr: "Message PLC: $alert-name" } },
                severity: plcSeverities[random.intBetween(0, plcSeverities.length - 1)],
            };
        }
    }
};