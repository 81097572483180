import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_REPORT_CONFIGURATIONS } from "js/constants";
import _ from "lodash";
import * as mocks from "./mocks";
import { IReportConfiguration, IReportConfigurations, reportIsMultiNode } from "./model";
import { actionsCreators } from "./slices";

export const getAllReportConfigurations = getThrottleAjax(() => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest<{}, IReportConfiguration[]>({
        serverLessResolve: () => Promise.resolve(
            getState().reportConfigurations ? _.map(getState().reportConfigurations, (d) => d) : mocks.mockReportConfigurations(getState().nodes)
        ),
        url: `${API_PREFIX_REPORT_CONFIGURATIONS}?limit=9999&sort=-displayPriority`,
        onSuccess: (reportConfigurations: IReportConfiguration[]) => {
            dispatch(actionsCreators.set(reportConfigurations));
        },
    })
), 10 * 1000);

export const postReportConfigurations = getAction((reportConfigurations: IReportConfiguration[]) => (dispatch) => (
    ajaxRequest({
        method: "POST",
        showAjaxLoading: true,
        data: reportConfigurations,
        // replace null ids with real ids
        serverLessResolve: () => Promise.resolve(_.map(reportConfigurations, (report) => report.id == null ? { ...report, id: mocks.getNewMockId() } : report)),
        url: `${API_PREFIX_REPORT_CONFIGURATIONS}?batch`,
        onSuccess: (response: IReportConfiguration[]) => {
            dispatch(actionsCreators.patch(response));
        },
    }).promise
));

export const putReportConfigurations = getAction((reportConfigurations: IReportConfiguration[]) => (dispatch) => (
    ajaxRequest({
        method: "PUT",
        showAjaxLoading: true,
        data: reportConfigurations,
        // replace new_ ids with real ids
        serverLessResolve: () => _.map(reportConfigurations, (report) => report.id.startsWith("new") ? { ...report, id: Date.now().toString() } : report),
        url: `${API_PREFIX_REPORT_CONFIGURATIONS}?id=${_.map(reportConfigurations, ({ id }) => id).join(",")}`,
        onSuccess: (response: IReportConfiguration[]) => {
            dispatch(actionsCreators.patch(response));
        },
    }).promise
));

export const deleteReportConfigurations = getAction((reportConfigurations: IReportConfiguration[]) => (dispatch) => (
    ajaxRequest({
        method: "DELETE",
        showAjaxLoading: true,
        serverLessResolve: () => null,
        url: `${API_PREFIX_REPORT_CONFIGURATIONS}?id=${_.map(reportConfigurations, ({ id }) => id).join(",")}`,
        onSuccess: () => {
            dispatch(actionsCreators.delete(_.map(reportConfigurations, ({ id }) => id)));
        },
    }).promise
));

export const saveReportConfiguration = getAction((reportConfiguration: IReportConfiguration, initialReports: IReportConfigurations, isMultiMachine: boolean, nodeId: string) => (
    async (dispatch) => {
        if (reportConfiguration.id.startsWith("new")) {
            const ajaxPromises: Promise<void>[] = [];
            const updatedReports: IReportConfigurations = {};
            _.forEach(initialReports, (report) => {
                if ((!isMultiMachine && report.nodeIds[0] == nodeId) || (isMultiMachine && reportIsMultiNode(report))) {
                    updatedReports[report.id] = { ...report, displayPriority: report.displayPriority + 1 };
                }
            });
            ajaxPromises.push(dispatch(postReportConfigurations([{ ...reportConfiguration, id: null }])));
            ajaxPromises.push(dispatch(putReportConfigurations(_.map(updatedReports))));
            return await Promise.all(ajaxPromises);
        } else {
            return dispatch(putReportConfigurations([reportConfiguration]));
        }
    }));
