import { ajaxRequest } from "@comact/crc";
import { API_PREFIX_KPI_PATTERNS } from "js/constants";
import { FormulaType } from "./model";
import { actionsCreators } from "./slices";

/**
 * Validate formula. Cancel previous request, dans debounced ajax calls...
 */
const ajaxReq: ReturnType<typeof ajaxRequest> = null;
let timeout: number;
export const validateFormula = ((type: FormulaType, formula: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    if (ajaxReq) ajaxReq.request.abort(); // abort previous ajax call

    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
        const { kpiPatternEditor } = getState();
        if (!kpiPatternEditor) return;
        const { database, groups, collection } = kpiPatternEditor;
        ajaxRequest({
            method: "POST",
            data: { formula, database, groups, collection, type },
            serverLessResolve: () => formula.includes("unknown") ? ["invalid"] : [],
            url: `${API_PREFIX_KPI_PATTERNS}/validate`, // ?bust:${Date.now()},
            onSuccess: ((remoteErrors: string[]) => {
                remoteErrors.length
                    ? dispatch(actionsCreators.setRemote({ type, errors: remoteErrors }))
                    : dispatch(actionsCreators.deleteRemote(type));
            }),
        }).promise;
    }, 750);
});