import _ from "lodash";
import { IMachineCodecNode } from "../node";

export interface IOptimizerConfig {
    configId: string;
    deviceId: string;
    type: IConfigType;
    name: string;
    plcRef: number;
    activationTimestamp: string; // date ex.: 2021-06-16T13:19:04+00:00
    products?: IProduct[]; // only when loading complete config
    mechanic?: Object; // only when loading complete config
    bundleDefinitions?: Object; // only when loading complete config
}

export interface IOptimizerConfigGroup {
    id: string;
    name: string;
    plcRef: number;
    active: boolean;
    configCount: number;
    type: IOptimizerConfig["type"];
    startDate: string;
    endDate: string;
}

export interface IProduct {
    id: string;
    name: string;
    price: number;
    color: string;
    grade: {
        name: string;
        nominal: number;
    };
    thickness: {
        name: string;
        nominal: number;
    };
    width: {
        name: string;
        nominal: number;
    };
    length: {
        name: string;
        nominal: number;
    };
    species: {
        name: string;
    }[];
}

export type IConfigType = "live" | "simulation";

export type IOptimizerConfigs = { [configId: string]: IOptimizerConfig; };

export const isActiveConfiguration = (config: IOptimizerConfig, nodeMachine: IMachineCodecNode) => {
    if (!nodeMachine) return false;
    const { liveConfigs, simulationConfigs } = nodeMachine;
    if (config.type == "live" && _.split(liveConfigs, ",").includes(config.configId)) return true;
    else if (config.type == "simulation" && _.split(simulationConfigs, ",").includes(config.configId)) return true;
    return false;
};