import { ajaxRequest, app, DataConfigModule, getAction } from "@comact/crc";
import { API_PREFIX_SPECIES_DENSITY } from "js/constants";
import * as mocks from "./mocks";
import { convertFromServer, convertToServer, IDensityBySpecies, IDensityBySpeciesServer } from "./model";
import { actionsCreators } from "./slices";

const addPrefsToURL = (state: IStoreState) => {
    const locale = app.language;
    const system = DataConfigModule.selectors.getUnitSystem(state);
    return `?locale=${locale}&system=${system}`;
};

export const getSpeciesDensity = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: (): IDensityBySpeciesServer => convertToServer(getState().densitiesBySpecies || mocks.getSpeciesDensity()),
        url: `${API_PREFIX_SPECIES_DENSITY}${addPrefsToURL(getState())}`,
        onSuccess: ((densitiesBySpecies: IDensityBySpeciesServer) => {
            dispatch(actionsCreators.set(convertFromServer(densitiesBySpecies)));
        }),
    })
);

export const saveSpeciesDensity = getAction((densitiesBySpecies: IDensityBySpecies[]) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest<{}, IDensityBySpeciesServer>({
        serverLessResolve: (): IDensityBySpeciesServer => convertToServer(densitiesBySpecies),
        url: `${API_PREFIX_SPECIES_DENSITY}?${addPrefsToURL(getState())}`,
        method: "POST",
        data: convertToServer(densitiesBySpecies),
        onSuccess: ((densitiesBySpeciesServer: IDensityBySpeciesServer) => {
            dispatch(actionsCreators.set(convertFromServer(densitiesBySpeciesServer)));
        }),
    }).promise
));
