import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IAccessToken, IAccessTokens } from "./model";

declare global {
    interface IStoreState {
        accessTokens: IAccessTokens;
    }
}

const useStats = "accessTokens";

const accessTokens = createSlice({
    name: "accessTokens",
    initialState: null as IAccessTokens,
    reducers: {
        set: (state, { payload }: PayloadAction<IAccessTokens>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: payload,
                useStats,
            })
        ),
        patch: (state, { payload }: PayloadAction<IAccessToken[]>) => (
            Utils.slices.patchWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats,
            })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...accessTokens.actions,
};

export default {
    [accessTokens.name]: accessTokens.reducer,
};