import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { INodes } from "../../node/model";
import { mockReportTemplates } from "../templates/mocks";
import { IReportConfiguration } from "./model";

let nextId = 1000000;
export const getNewMockId = () => "" + nextId++;

const rand = createSeeder();

export const mockReportConfigurations = (nodes: INodes): IReportConfiguration[] => {
    const machineNodes = _.filter(nodes, (node) => node.templateName == "machineCodec");
    const scannerNodes = _.filter((nodes), (node) => node.templateName == "scannerCodec");

    const getAllScannerNodesIds = () => (
        _.map(scannerNodes, (n) => n.id)
    );

    const getAllScannerNodesIdsForMachine = (machineNodeId: string) => (
        _.reduce(scannerNodes, (nodesIds: string[], n) => {
            if (n.parentId == machineNodeId) {
                nodesIds.push(n.id);
            }
            return nodesIds;
        }, [])
    );

    const mockedReports = _.reduce(machineNodes, (reports: IReportConfiguration[], machineNode) => {
        const isEnabled = rand.intBetween(0, 1) == 0;
        const isSystem = rand.intBetween(0, 1) == 0;
        reports.push({
            id: getNewMockId(),
            enabled: isEnabled,
            system: isSystem,
            nodeIds: getAllScannerNodesIdsForMachine(machineNode.id),
            label: {
                values: {
                    en: `Rapport ${machineNode.name + (!isEnabled && (" (disabled)")) + (isSystem && (" (System)"))} 1 EN`,
                    fr: `Rapport ${machineNode.name + (!isEnabled && (" (disabled)")) + (isSystem && (" (System)"))} 1 FR`,
                    fi: `Rapport ${machineNode.name + (!isEnabled && (" (disabled)")) + (isSystem && (" (System)"))} 1 FI`,
                    pt: `Rapport ${machineNode.name + (!isEnabled && (" (disabled)")) + (isSystem && (" (System)"))} 1 PT`,
                },
            },
            templateId: "RTA_DowngradeProductGraph",
            displayPriority: 0,
            tags: [],
            parameters: mockReportTemplates()[0].parameters,
        },
        {
            id: getNewMockId(),
            enabled: true,
            system: false,
            nodeIds: ["1"],
            label: {
                values: {
                    en: "Rapport Grade 2 EN",
                    fr: "Rapport Grade 2 FR",
                    fi: "Rapport Grade 2 FI",
                    pt: "Rapport Grade 2 PT",
                },
            },
            templateId: "RTA_LinearLiveTallyBucking",
            displayPriority: 0,
            tags: [],
            parameters: mockReportTemplates()[1].parameters,
        },
        {
            id: getNewMockId(),
            enabled: true,
            system: false,
            nodeIds: ["2"],
            label: {
                values: {
                    en: "Rapport TBL 1 EN",
                    fr: "Rapport TBL 1 FR",
                    fi: "Rapport TBL 1 FI",
                    pt: "Rapport TBL 1 PT",
                },
            },
            templateId: "RTA_LinearLiveTallyBucking",
            displayPriority: 0,
            tags: [],
            parameters: mockReportTemplates()[1].parameters,
        },
        {
            id: getNewMockId(),
            enabled: true,
            system: false,
            nodeIds: ["3"],
            label: {
                values: {
                    en: "Rapport TBL 2 EN",
                    fr: "Rapport TBL 2 FR",
                    fi: "Rapport TBL 2 FI",
                    pt: "Rapport TBL 2 PT",
                },
            },
            templateId: "RTA_DowngradeProductGraph",
            displayPriority: 0,
            tags: [],
            parameters: mockReportTemplates()[0].parameters,
        },
        {
            id: getNewMockId(),
            enabled: true,
            system: false,
            nodeIds: ["4"],
            label: {
                values: {
                    en: "Rapport OLI 1 EN",
                    fr: "Rapport OLI 1 FR",
                    fi: "Rapport OLI 1 FI",
                    pt: "Rapport OLI 1 PT",
                },
            },
            templateId: "RTA_DowngradeProductGraph",
            displayPriority: 0,
            tags: [],
            parameters: mockReportTemplates()[0].parameters,
        },
        {
            id: getNewMockId(),
            enabled: false,
            system: false,
            nodeIds: ["4"],
            label: {
                values: {
                    en: "Rapport OLI (disabled) EN",
                    fr: "Rapport OLI (disabled) FR",
                    fi: "Rapport OLI (disabled) FI",
                    pt: "Rapport OLI (disabled) PT",
                },
            },
            templateId: "RTA_DowngradeProductGraph",
            displayPriority: 0,
            tags: [],
            parameters: mockReportTemplates()[0].parameters,
        });
        return reports;
    }, []);

    // multi-machine
    mockedReports.push({
        id: getNewMockId(),
        enabled: true,
        system: false,
        nodeIds: getAllScannerNodesIds(),
        label: {
            values: {
                en: "Multi-Machine EN",
                fr: "Multi-Machine FR",
                fi: "Multi-Machine FI",
                pt: "Multi-Machine PT",
            },
        },
        templateId: "RTA_DowngradeProductGraph",
        displayPriority: 0,
        tags: [],
        parameters: mockReportTemplates()[0].parameters,
    },
    {
        id: getNewMockId(),
        enabled: true,
        system: true,
        nodeIds: ["1"],
        label: {
            values: {
                en: "Rapport Grade (System) EN",
                fr: "Rapport Grade (System) FR",
                fi: "Rapport Grade (System) FI",
                pt: "Rapport Grade (System) PT",
            },
        },
        templateId: "RTA_DowngradeProductGraph",
        displayPriority: 0,
        tags: [],
        parameters: mockReportTemplates()[0].parameters,
    });

    return mockedReports;
};