import { ajaxRequest } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_SAMPLES } from "../constants";
import { actionsCreators } from "../samples/slices";
import * as mocks from "./mocks";
import { ICategory, ISample } from "./model";

export const getSamples = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: () => _.isEmpty(getState().samples) ? mocks.getSamples() : getState().samples,
        url: `${API_PREFIX_SAMPLES}`,
        onSuccess: (samples: ISample[]) => {
            dispatch(actionsCreators.set(samples));
        },
    })
);

export const getCategories = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: () => _.isEmpty(getState().categories) ? mocks.getCategories() : getState().categories,
        url: `${API_PREFIX_SAMPLES}/categories`,
        onSuccess: (categories: ICategory[]) => {
            dispatch(actionsCreators.setCategories(categories));
        },
    })
);