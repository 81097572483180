// Redux
import { createModule } from "@comact/crc";
import * as requests from "./request";
import * as selectors from "./selectors";
import reducers, { actionsCreators } from "./slices";
import * as validation from "./validation";

// Model (type, interface)
export * from "./model";
export { selectors, validation, requests };

export const KpiDataIOModuleEdit = createModule({
    reducers, actionsCreators, requests, selectors,
});
