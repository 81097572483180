import { UserModule, Utils, app } from "@comact/crc";
import { IContextDefinitions } from "js/kpis/contextDefinitions/model";
import { IMachineCodecNode, INodes } from "js/node";
import * as _ from "lodash";
import { createSelector } from "reselect";
import { getContextDefinitions } from "../kpis/contextDefinitions/selectors";
import { getKpiPatterns } from "../kpis/kpiPatterns/selectors";
import { IKpiQueryRecipesNode } from "../kpis/kpiQueries/model";
import { NodesModule } from "../node";
import { IDashboard, IDashboardEdit, IDashboards, createDashboardExtra, getDashboardStats, widgetHasKpiQueryRecipes, widgetHasQueryRecipe } from "./model";
import { IWidgetDashboard } from "./widgets/index";

export const getDashboardById = (state: IStoreState, id: string) => getDashboards(state)?.[id];

export const getDashboards = ({ dashboards }: IStoreState) => dashboards || {};

export const getValidDashboardById = (state: IStoreState, id: string) => _.find(getAllDashboardExtra(state), (d) => d.isValid && d.id == id);

export const getFirstValidDashboard = (state: IStoreState) => _.find(getAllDashboardExtra(state), (d) => d.isValid);

/**
 * Generates all systems dashboards by injectings ids that seems to correspond.
 */
export const getSystemDashboards = createSelector(
    (state: IStoreState) => state,
    (_state: IStoreState, defaultDashboards: IDashboards) => defaultDashboards,
    (state, defaultDashboards): IDashboards => {
        const nodes = NodesModule.selectors.getNodesChildrenOfTypeRecursive(state, state.currentMillNodeId, null);
        const contextDefinitions = state.contextDefinitions;

        // If no nodes or no context, can't do anything
        if (_.isEmpty(contextDefinitions) || _.isEmpty(nodes)) {
            console.warn("At least one list is empty [contexts, nodes]. System dashboards can't be generated.");
            return {};
        }

        // Inject node id, scanner id, context id or machine id if applicable
        return _.reduce(defaultDashboards, (dashboards, dashboard) => (
            { ...dashboards, ...createInjectedIdsDashboard(dashboard, nodes, contextDefinitions) }
        ), {} as IDashboards);
    },
    {
        memoizeOptions: { // System dashboards take some time to calculate so we want a fast way to check if we need to recalculate them.
            equalityCheck: (a: IStoreState, b: IStoreState) => {
                if (a.nodes == b.nodes && a.contextDefinitions == b.contextDefinitions) return true; // basic equality
                if (a.nodes != b.nodes || _.size(a.nodes) != _.size(b.nodes)) return false; // size not matching
                if (a.contextDefinitions != b.contextDefinitions || _.size(a.contextDefinitions) != _.size(b.contextDefinitions)) return false; // size not matching
                if (!Utils.object.shallowEqual(_.mapValues(a.nodes, ({ id }) => id), _.mapValues(b.nodes, ({ id }) => id))) return false; // ids not matching
                if (!Utils.object.shallowEqual(_.mapValues(a.contextDefinitions, ({ id }) => id), _.mapValues(b.contextDefinitions, ({ id }) => id))) return false; // ids not matching
                return true;
            },
        },
    }
);

/**
 * Generate one or more dashboards by injecting ids that seem to correspond to the requested types.
 * This function scan a default dashboard and replace node id, scanner id and context id or machine/node id with actual ids.
 */
export const createInjectedIdsDashboard = (dashboard: IDashboard, nodes: INodes, contextDefinitions: IContextDefinitions) => {
    const dashboards: IDashboards = {};

    // Inject node id, scanner id, context id or machine id if applicable
    const { machineModels } = getDashboardStats(dashboard, nodes, contextDefinitions);

    const machinesByModels: IMachineCodecNode[][] = _.chain(machineModels)
        .map((machineModel) => _.filter(nodes, (node) => node.templateName == "machineCodec" && node?.machine == machineModel) as IMachineCodecNode[])
        .filter((models) => !_.isEmpty(models)) // remove empty matches
        .value();

    const machineCombinations = machinesByModels.reduce((arr, machinesByModel, index) => {
        const newArr: { [model: string]: IMachineCodecNode; }[] = [];
        _.forEach(machinesByModel, (machineByModel) => {
            if (index == 0) {
                newArr.push({ [machineByModel.machine]: machineByModel });
            } else {
                arr.forEach((cloneArr) => {
                    newArr.push({ ...cloneArr, [machineByModel.machine]: machineByModel });
                });
            }
        });
        return newArr;
    }, [] as { [model: string]: IMachineCodecNode; }[]);

    // Do we need to create more than one dashboard or not
    const multiCombinations = _.size(machineCombinations) > 1;

    // No machine found for this dashboard, but we still add it to show it in some cases
    if (_.size(machineCombinations) == 0) {
        dashboards[dashboard.id] = dashboard; // return the dashboard as is, without injecting any id
    }

    // Loop through all machines combinations, and create a dashboard for each
    machineCombinations.forEach((machineCombination) => {
        const finalId = multiCombinations
            ? dashboard.id + "_" + _.map(machineCombination, ({ id, machine }) => machine + id).join("_").toLowerCase() // multiple combination, we need to create a unique id
            : dashboard.id; // only one combination, we can keep the original id
        dashboards[finalId] = {
            ...dashboard,
            id: finalId,
            widgetsByBreakpoints: _.mapValues(dashboard.widgetsByBreakpoints, (oldWidgets) => _.map(oldWidgets, (widget) => ({
                ...widget,
                props: injectIds(widget, nodes, machineCombination, contextDefinitions),
            } as typeof widget))),
            title: multiCombinations
                ? {
                    ...dashboard.title,
                    values: _.mapValues(dashboard.title.values, (v) => ( // add the name of the machines to the title to differentiate them
                        v + " | " + _.map(machineCombination, ({ name }) => name).join(", "))),
                }
                : dashboard.title,
        };
    });

    return dashboards;
};

const injectIds = (
    widget: IWidgetDashboard,
    nodes: INodes,
    machineCombination: { [model: string]: IMachineCodecNode; }, contextDefinitions?: IContextDefinitions
): IWidgetDashboard["props"] => {
    const matchToNodeId = (nodeRecipe: IKpiQueryRecipesNode): string => {
        if (nodeRecipe.templateName == "scannerCodec") {
            const machineId = machineCombination[nodeRecipe.machine]?.id;

            const foundNode = _.find(nodes, (node) => (
                node.templateName == nodeRecipe.templateName &&
                node.parentId == machineId &&
                _.every(nodeRecipe, (r, key) => key == "id" || r == node[key]) // we don't want to check the id, because we don't have it yet
            ));
            return foundNode?.id;
        } else if (nodeRecipe.templateName == "machineCodec") {
            const machineId = machineCombination[nodeRecipe.machine]?.id;
            return machineId;
        } else {
            // Future cases goes here
            return null;
        }
    };

    const matchToContextId = (key: string): string => (
        _.find(contextDefinitions, (context) => context.uniqueReferenceName == key)?.id ||
        _.find(contextDefinitions, ({ systemDashboardDefault }) => systemDashboardDefault)?.id
    );

    if (widgetHasKpiQueryRecipes(widget)) {
        const props = widget.props;
        return ({
            ...props,
            kpiQueryRecipes: {
                ...props.kpiQueryRecipes,
                nodes: _.map(props.kpiQueryRecipes.nodes, (node) => ({
                    ...node,
                    id: matchToNodeId(node),
                })),
                contexts: _.map(props.kpiQueryRecipes.contexts, (context) => ({
                    ...context,
                    id: matchToContextId(context.key),
                })),
            },
        });
    } else if (widgetHasQueryRecipe(widget)) {
        if (widget.widgetType == "Machine") {
            const { props, props: { queryRecipe } } = widget;
            return {
                ...props,
                queryRecipe: {
                    ...queryRecipe,
                    id: machineCombination[queryRecipe?.machine]?.id,
                },
            };
        } else {
            const { props, props: { queryRecipe } } = widget;
            return ({
                ...props,
                queryRecipe: {
                    ...queryRecipe,
                    id: matchToNodeId(queryRecipe),
                },
            });
        }
    } else {
        return widget.props;
    }
};

/**
 * Get dashboard "extra" model by id
 */
export const getDashboardExtraById = (state: IStoreState, id: string) => getAllDashboardExtra(state)?.[id];

const getAllDashboardExtra = createSelector(
    (state: IStoreState) => state,
    ((state) => (
        _.mapValues(getDashboards(state), (dashboard) => (
            createDashboardExtra(
                dashboard,
                NodesModule.selectors.getAllNodes(state),
                getContextDefinitions(state),
                getKpiPatterns({ state }),
                userCanEditDashboard(state, dashboard.id),
                userCanDuplicateDashboard(state)
            )
        ))
    )),
    {
        memoizeOptions: { // Dashboards extra take some time to calculate so we want a fast way to check if we need to recalculate them.
            equalityCheck: (a: IStoreState, b: IStoreState) => {
                if (a.dashboards == b.dashboards && a.nodes == b.nodes && a.contextDefinitions == b.contextDefinitions) return true;
                if (a.dashboards != b.dashboards || _.size(a.dashboards) != _.size(b.dashboards)) return false; // size not matching
                if (a.nodes != b.nodes || _.size(a.nodes) != _.size(b.nodes)) return false; // size not matching
                if (a.contextDefinitions != b.contextDefinitions || _.size(a.contextDefinitions) != _.size(b.contextDefinitions)) return false; // size not matching
                if (!Utils.object.shallowEqual(_.mapValues(a.dashboards, ({ id }) => id), _.mapValues(b.dashboards, ({ id }) => id))) return false; // ids not matching
                if (!Utils.object.shallowEqual(_.mapValues(a.dashboards, (d) => d.modificationDate), _.mapValues(b.dashboards, (d) => d.modificationDate))) return false; // date not matching
                if (!Utils.object.shallowEqual(_.mapValues(a.nodes, ({ id }) => id), _.mapValues(b.nodes, ({ id }) => id))) return false; // ids not matching
                if (!Utils.object.shallowEqual(_.mapValues(a.contextDefinitions, ({ id }) => id), _.mapValues(b.contextDefinitions, ({ id }) => id))) return false; // ids not matching
                return true;
            },
        },
    }
);

/**
 * Get dashboard "extra" edit model, for edit
 */
export const getDashboardEditExtra = ({ state, dashboardEdit }: { state: IStoreState; dashboardEdit: IDashboardEdit; }) => (
    createDashboardExtra(
        dashboardEdit,
        NodesModule.selectors.getAllNodes(state),
        getContextDefinitions(state),
        getKpiPatterns({ state }),
        userCanEditDashboard(state, dashboardEdit.id),
        userCanDuplicateDashboard(state)
    )
);

export const makeGetDashboardsExtraForMill = () => createSelector(
    (state: IStoreState) => getAllDashboardExtra(state),
    (state: IStoreState) => UserModule.selectors.hasPermission(state, ["dashboards-edit-all"], state.currentMillNodeId),
    (dashboards, canEdit) => (
        _.chain(dashboards)
            .filter((d) => (
                app.debugMode || // in debug mode we show all the dashboards no exception
                d.isValid ||
                (canEdit && !d.isSystem) // show custom dashboards even if they are not valid, since the user can fix them
            ))
            .keyBy(({ id }) => id)
            .value()
    )
);

export const userCanEditDashboard = (state: IStoreState, dashboardId: string) => {
    const { userId, isSystem } = getDashboardById(state, dashboardId) || {}; // Could be a new dashboard
    if (isSystem) return false;
    if (UserModule.selectors.hasPermission(state, ["dashboards-edit-all"], state.currentMillNodeId)) return true;
    if (UserModule.selectors.hasPermission(state, ["dashboards-edit-own"], state.currentMillNodeId)) {
        if (UserModule.selectors.getCurrentUser(state)?.id == userId) return true;
        if (_.startsWith(dashboardId, "new_")) return true;
    }
    return false;
};

const userCanDuplicateDashboard = (state: IStoreState) => {
    if (UserModule.selectors.hasPermission(state, ["dashboards-edit-all"], state.currentMillNodeId)) return true;
    return false;
};