import { ajaxRequest, downloadAjaxRequest, getAction, getThrottleAjax, t } from "@comact/crc";
import { API_PREFIX_CMOC_BACKUP_FILES, API_PREFIX_CMOC_BACKUP_URL } from "js/constants";
import { ICmocBackup, IotFileType } from "./model";
import { actionsCreators } from "./slices";

export const getCmocBackups = getThrottleAjax((nodeId: string, iotFileType: IotFileType) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => (
            import("./mocks").then((m) => m.getCmocBackups())
        ),
        url: `${API_PREFIX_CMOC_BACKUP_FILES}/${nodeId}?type=${iotFileType}`,
        onSuccess: (cmocBackups: ICmocBackup[]) => {
            dispatch(actionsCreators.set(cmocBackups));
        },
    })
), 2 * 1000);

export const downloadCmocBackup = (
    nodeId: string,
    name: string,
    iotFileType: IotFileType
) => () => {
    downloadAjaxRequest({
        url: `${API_PREFIX_CMOC_BACKUP_FILES}/${nodeId}/download?type=${iotFileType}&fileName=${name}`,
        showAjaxLoadingMessage: t("backups.request.ajaxLoadingMessage"),
    });
};

export const getCmocBackupUrl = getAction((nodeId: string, name: string) => () => (
    ajaxRequest({
        serverLessResolve: () => `${API_PREFIX_CMOC_BACKUP_URL}/${nodeId}/${name}`,
        url: `${API_PREFIX_CMOC_BACKUP_URL}/${nodeId}/${name}`,
        rawResponse: true,
        onSuccess: (url: string) => url,
    }).promise
));
