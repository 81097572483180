import { t } from "@comact/crc";
import _ from "lodash";

export type IKpiZoneValue = "empty" | "normal" | "warn" | "error"; // ou normal == ok ?
export const IKpiZoneValueList: IKpiZoneValue[] = ["empty", "warn", "normal", "error"];

export interface IKpiZone {
    value: IKpiZoneValue;
    min: number;
    max: number;
}

// ////////////////////////////////////////////

export const createNewObjective = (partialData: Partial<IKpiObjective> = {}): IKpiObjective => ({
    id: "default",
    title: partialData.id || "default",
    min: 0,
    max: 100,
    target: 50,
    zones: [],
    batchId: null,
    ...partialData,
});

export interface IKpiObjective {
    readonly id: string;
    readonly title: string;
    min: number;
    max: number;
    target?: number;
    zones: IKpiZone[];
    readonly batchId?: string;
}

export type IKpiObjectives = { [id: string]: IKpiObjective; };

export interface IKpiObjectiveErrors {
    min?: string;
    max?: string;
    target?: string;
    zones?: string[];
}

export const validateKpiObjective = (objective: IKpiObjective): IKpiObjectiveErrors => {
    let errors: IKpiObjectiveErrors = {};

    const MAX_VALUE = 4294967296; // chiffre choisi arbitrairement

    const checkNumberError = (value: number, min: number, max: number): string | null => {
        if (value == null) return t("core:errors.notEmpty");
        else if (!_.isFinite(value)) return t("core:errors.numberInvalid");
        else if (value > max) return t("core:errors.numberMax", { max });
        else if (value < min) return t("core:errors.numberMin", { min });
        return null;
    };

    errors.min = checkNumberError(objective.min, -MAX_VALUE, MAX_VALUE);
    errors.max = checkNumberError(objective.max, -MAX_VALUE, MAX_VALUE);

    if (_.isFinite(objective.target) && _.isFinite(objective.min) && _.isFinite(objective.max)) {
        errors.target = checkNumberError(objective.target, objective.min, objective.max);
    }

    let lastMin = objective.min;
    errors.zones = objective.zones.map((zone) => {
        if (zone.max == null) return t("core:errors.notEmpty");
        if (lastMin && zone.max < lastMin) return t(".zone must be greater than previous");
        if (objective.max && zone.max > objective.max) return t(".zone must be lower than total max");
        lastMin = zone.min;
        return null;
    });
    errors.zones = errors.zones.every((z) => z == null) ? null : errors.zones;

    errors = _.omitBy(errors, _.isEmpty); // Remove empty key
    return Object.keys(errors).length > 0 ? errors : null; // Return object of not empty
};