import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ILogFile, ILogFiles } from "./model";

declare global {
    interface IStoreState {
        logFiles: ILogFiles;
    }
}

const useStats = "logFiles";

const logFiles = createSlice({
    name: "logFiles",
    initialState: null as ILogFiles,
    reducers: {
        set: (state, { payload }: PayloadAction<ILogFile[]>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ name }) => name), useStats })
        ),
        clear: () => null,
    },
});

export const actionsCreators = {
    ...logFiles.actions,
};

export default {
    [logFiles.name]: logFiles.reducer,
};