import { ajaxRequest, getAction, getActionAjax } from "@comact/crc";
import { API_PREFIX_LOG_FILES } from "js/constants";
import { downloadFileFromAjaxRequest } from "../utils";
import { ILogFile } from "./model";
import { actionsCreators } from "./slices";

export const getLogFiles = getActionAjax((nodeId: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => (
            import("./mocks").then((m) => m.getLogFiles())
        ),
        url: `${API_PREFIX_LOG_FILES}/${nodeId}`,
        onSuccess: (logFiles: ILogFile[]) => {
            dispatch(actionsCreators.set(logFiles));
        },
    })
));

export const downloadLog = getAction((nodeId: string, fileName: string) => () => {
    const { promise, request } = ajaxRequest({
        url: `${API_PREFIX_LOG_FILES}/${nodeId}/download?fileName=${fileName}`,
        rawResponse: true,
        responseType: "arraybuffer",
    });

    return promise.then(() => {
        downloadFileFromAjaxRequest(request);
    });
});