import { getLocalizedText, t } from "@comact/crc";
import { IQueryResults } from "js/common";
import { selectors as selectorsKpiPatterns } from "js/kpis/kpiPatterns";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { IDashboard, IDashboardQuery, getRangeTypeInMilli } from "../../dashboards/model";
import { insertVariableValues } from "../../dashboards/widgets/TitleFormula";
import { getUnitDisplaySymbol } from "../../mUnits/selectors";
import { NodesModule } from "../../node";
import { IKpiQuery, IKpiQueryRecipes } from "./model";

/**
 * Make a selector that will take a query recipes and return an array of kpi queries.
 */
export const makeResolveKpiQueryRecipes = () => memoize(({ kpiQueryRecipes }: { state: IStoreState; kpiQueryRecipes: IKpiQueryRecipes; }) => {
    const nodesIds = _.map(kpiQueryRecipes.nodes, (n) => n.id);
    const kpiQueries: IKpiQuery[] = [];
    _.each(kpiQueryRecipes.contexts, (context) => {
        _.each(nodesIds, (nodeid) => {
            _.each(kpiQueryRecipes.kpis, (kpi) => {
                const kpiQuery: IKpiQuery = { nodeId: null, contextId: null, patternKey: null, definitionId: null };
                kpiQuery.contextId = context.id;
                kpiQuery.nodeId = nodeid || null;
                if (kpi.type == "kpiDefinition") {
                    kpiQuery.patternKey = kpi.patternKey;
                    kpiQuery.definitionId = kpi.definitionId;
                } else if (kpi.type == "kpiGroup") {
                    kpiQuery.patternKey = kpi.patternKey;
                    kpiQuery.definitionId = null;
                }
                kpiQueries.push(kpiQuery);
            });
        });
    });
    return kpiQueries;
});

/**
 * Add title to results
 */
export const makeAddTitleToQueryResults = () => memoize(({ state, results, titleFormula }: { state: IStoreState; results: IQueryResults[]; titleFormula?: string; }) => {
    const kpiPatterns = selectorsKpiPatterns.getKpiPatterns({ state });
    const contextDefinitions = state.contextDefinitions;
    const nodes = NodesModule.selectors.getAllNodes(state);
    const mUnits = state.mUnits;
    return _.map(results, (result) => {
        if (!result) return null;
        const kpiPattern = _.find(kpiPatterns, (k) => k.uniqueName == result.patternKey);
        if (!kpiPattern) return result;
        const definition = kpiPattern.kpiDefinitions[result.definitionId];
        if (_.isEmpty(titleFormula)) titleFormula = "$title"; // by default only use title
        const $context = getLocalizedText(contextDefinitions[result.contextId].name);
        const $title = getLocalizedText(result.title);
        const $node = nodes?.[result.nodeId].name;
        const $unit = getUnitDisplaySymbol(mUnits, kpiPattern.unitType, kpiPattern.unitLabel);
        const database = kpiPattern.database;
        const $dataType = _.isEmpty(database) ? "" : t([`databases.${database}`, database]);
        const $parentNode = nodes?.[nodes?.[result.nodeId].parentId].name;
        const $kpiPattern = getLocalizedText(kpiPattern.title);
        let $kpiDefinition = "";
        if (definition) {
            $kpiDefinition = _.isEmpty(definition.shortTitle) ? getLocalizedText(definition.title) : getLocalizedText(definition.shortTitle);
        }

        return {
            ...result,
            title: insertVariableValues(titleFormula, { $context, $title, $node, $unit, $dataType, $parentNode, $kpiPattern, $kpiDefinition }),
        };
    });
});

/**
 * Selector that create a Dashboard Query.
 */
export const getDashboardQuery = memoize(({ state, dashboard }: { state: IStoreState; dashboard: IDashboard; }) => {
    const defaultRangeType = dashboard?.defaultRangeType || "currentShift";
    const defaultAggregation = dashboard?.defaultAggregation || "time_15_minutes";
    const searchParams = state.routing.searchParams || {};

    const estimatedDuration = ((() => {
        const sParams = state.routing.searchParams;
        const range = (sParams?.range || dashboard?.defaultRangeType) as IDashboardQuery["rangeType"];
        switch (range) {
            case "customRange": {
                const from = parseInt(sParams?.from, 10) || null;
                const to = parseInt(sParams?.to, 10) || null;
                if (to && from) return to - from;
                return null;
            }
            default: return getRangeTypeInMilli(range);
        }
    })());

    return {
        rangeType: searchParams.range ? searchParams.range as IDashboardQuery["rangeType"] : defaultRangeType,
        aggregation: searchParams.aggregation ? searchParams.aggregation as IDashboardQuery["aggregation"] : defaultAggregation,
        shiftId: searchParams.shift,
        batchId: searchParams.batch,
        customRange: {
            from: parseInt(searchParams.from, 10) || null,
            to: parseInt(searchParams.to, 10) || null,
        },
        estimatedDuration,
    };
});

// eslint-disable-next-line max-len
export const getOrderedResultsIdsByRecipes = ({
    kpiResults,
    kpiQueryRecipes,
    sortAsc,
}: { kpiResults: IQueryResults[]; kpiQueryRecipes: IKpiQueryRecipes; sortAsc: boolean; }): { kpiIds: string[]; contextIds: string[]; nodeIds: string[]; } => {
    if (!kpiResults || !kpiQueryRecipes) return { kpiIds: [], contextIds: [], nodeIds: [] };

    // Find the kpi ids that are both in results and queries, but keep the queries order
    const kpiIds = kpiQueryRecipes.kpis.reduce((acc, kpi) => {
        if (kpi.type == "kpiGroup") {
            const founds = kpiResults.filter((r) => r.patternKey == kpi.patternKey && kpi.definitionId == null);
            if (founds.length) {
                const ordered = _.orderBy(founds, ({ data }) => _.last(data)?.order ?? -1, sortAsc ? "asc" : "desc"); // order the group definition by their order
                acc.push(...ordered.map((found) => found.patternKey + "," + found.definitionId));
            }
        } else {
            const found = kpiResults.find((r) => r.patternKey == kpi.patternKey && r.definitionId == kpi.definitionId);
            if (found) acc.push(found.patternKey + "," + found.definitionId);
        }
        return acc;
    }, [] as string[]);

    // Find the context ids that are both in results and queries, but keep the queries order
    const contextIds = kpiQueryRecipes.contexts.reduce((acc, { id }) => (
        kpiResults.find((r) => r.contextId == id) ? [...acc, id] : acc
    ), [] as string[]);

    // Find the node ids that are both in results and queries, but keep the queries order
    const nodeIds = kpiQueryRecipes.nodes.reduce((acc, { id }) => (
        kpiResults.find((r) => r.nodeId == id) ? [...acc, id] : acc
    ), [] as string[]);

    return { kpiIds, contextIds, nodeIds };
};