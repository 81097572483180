import { getClassNames, t } from "@comact/crc";
import { CSS, LoadingSpinner, styled } from "@comact/crc/modules/kit";
import DashboardExtraContext from "js/dashboards/dashboardsEdits/components/DashboardExtraContext";
import _ from "lodash";
import * as React from "react";
import { IWidgetDashboard, IWidgetDashboardTypes } from "../widgets";
import WidgetTitle from "../widgets/WidgetTitle";

export interface IWidgetContainerProps extends Pick<IWidgetDashboard, "id" | "design" | "padding" | "size" > {
    children: React.ReactNode;
    loading?: boolean;
    noData?: boolean;
    errorMessage?: string;
    ajaxError?: boolean;
    title?: string;
    unknownError?: boolean;
    widgetType: IWidgetDashboard["widgetType"];
}

export const useWidgetSplitProps = <T extends IWidgetDashboard>(props: T, defaultProps: T, widgetType: IWidgetDashboardTypes) => {
    const { dashboardExtra, breakpoint } = React.useContext(DashboardExtraContext);
    const widget = dashboardExtra?.widgetsByBreakpoints[breakpoint].find((w) => w.id == props.id);
    const design = widget?.design || props?.design;
    const padding = widget?.padding || props?.padding;
    const size = widget?.size || props?.size;
    return {
        container: {
            id: props.id,
            design,
            padding,
            size,
            widgetType,
        },
        props: {
            ...defaultProps?.props,
            ...props.props,
        } as Pick<T, "props">["props"],
    };
};

export const WidgetContainer = (props: IWidgetContainerProps) => {
    const { children, loading, noData, errorMessage, unknownError, ajaxError, title, design, padding, size } = props;

    const classNames = getClassNames([
        "react-grid-item-container",
        design || "default",
        padding ? "padding" + padding.charAt(0).toUpperCase() + padding.slice(1) : "paddingDefault",
        props.widgetType,
        errorMessage && "hasError",
        ajaxError && "ajaxError",
        unknownError && "hasUnknownErrorError",
    ]);

    const content = (() => {
        if (errorMessage) return <ErrorOnLoadingDataStyled children={errorMessage} />;
        else if (noData) return <NoDataOrLoadingStyled children={t("widgets.noKpiData")} />;
        else return children;
    })();
    return (
        <div className={classNames} style={{ fontSize: size + "em" }}>
            <LoadingSpinner.Inline data-test="" className={getClassNames(["loadingSpinner", loading && "isLoading"])} />
            {!_.isEmpty(title)
                ? (
                    <div className={getClassNames(["widgetGridContainer"])}>
                        <WidgetTitle>{title}</WidgetTitle>
                        {content}
                    </div>
                )
                : (
                    content
                )}
        </div>
    );
};

const NoDataOrLoadingStyled = styled("div")`
    color: rgba(255, 255, 255, .3);
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ErrorOnLoadingDataStyled = styled(NoDataOrLoadingStyled)`
    color: ${CSS.colors.red};
`;