import { getLocalizedText, ILocalizedString, t } from "@comact/crc/";
import { createNewObjective, IKpiObjective, IKpiObjectiveErrors, IKpiObjectives, validateKpiObjective } from "js/kpis/kpiObjectives";
import { validateLocalizeString } from "js/utils";
import _ from "lodash";
import { IMUnitsState } from "../../mUnits";
import { getUnitDisplayLabel } from "../../mUnits/selectors";
import { IKpiPattern } from "../kpiPatterns";

// IKpiGroupedObjectives
export interface IKpiDefinition {
    readonly id: string;
    title: ILocalizedString;
    shortTitle: ILocalizedString;
    objectives: IKpiObjectives | null; // pouvoir les ré-ordonné ?
}

/**
 * For when we want to display both kpi patterns and definitions
 */
export interface IKpiPatternWithDefinition extends IKpiPattern {
    definitionTitle?: ILocalizedString; // Optional cause we only need definitionTitle when it is a kpiDefinition
    isDefinition?: true;
}
export type IKpiPatternWithDefinitions = Record<string, IKpiPatternWithDefinition>;

export const createNewKpiDefinition = (partialData: Partial<IKpiDefinition> = {}): IKpiDefinition => ({
    id: "default",
    title: { values: { en: "default", fr: "défaut", pt: "padrão", fi: "oletusarvo" } },
    shortTitle: { values: { en: "default", fr: "défaut", pt: "padrão", fi: "oletusarvo" } },
    objectives: {
        default: createNewObjective(),
    },
    ...partialData,
});

export interface IKpiDefinitionErrors {
    title?: { [id: string]: string; };
    shortTitle?: { [id: string]: string; };
    objectives?: { [id: string]: IKpiObjectiveErrors; };
}

export const validateKpiDefinition = (kpiDef: IKpiDefinition): IKpiDefinitionErrors => {
    let errors: IKpiDefinitionErrors = {};

    if (kpiDef.id != "default") {
        errors.title = validateLocalizeString(kpiDef.title); // Title
        errors.shortTitle = validateLocalizeString(kpiDef.shortTitle); // Short title
    }

    // kpi objectives
    errors.objectives = _.reduce(kpiDef.objectives, (obj, kOjbective: IKpiObjective) => {
        const kOjbectiveErrors = validateKpiObjective(kOjbective);
        if (!_.isEmpty(kOjbectiveErrors)) obj[kOjbective.id] = kOjbectiveErrors;
        return obj;
    }, {});

    errors = _.omitBy(errors, _.isEmpty); // Remove empty key
    return Object.keys(errors).length > 0 ? errors : null; // Return object of not empty
};

export const createKpiLabel = (mUnits: IMUnitsState, kpiPattern: IKpiPattern, id: string) => {
    if (!kpiPattern?.kpiDefinitions[id]) return null;

    const kpiDefinition = kpiPattern.kpiDefinitions[id];
    const unitText = getUnitDisplayLabel(mUnits, kpiPattern.unitType, kpiPattern.unitLabel);

    // Kpi pattern title
    let label = getLocalizedText(kpiPattern.title);

    // Kpi definition title
    if (id != "default") label += ` - ${getLocalizedText(kpiDefinition.title)}`;
    else if (id == "default" && kpiPattern.collection && _.size(kpiPattern.kpiDefinitions) > 1) label += ` [x${_.size(kpiPattern.kpiDefinitions) - 1}]`; // number of children

    // Database name
    if (!_.isEmpty(kpiPattern.database)) label += " - " + t([`databases.${kpiPattern.database}`, kpiPattern.database]);

    // Unit Name
    if (!_.isEmpty(_.trim(unitText))) label += ` (${unitText})`;

    return label;
};
