import React from "react";
import { IMachineCodecNode, IMillNode, INode } from "./model";
import { IPerspective } from "./perspectives/model";

interface IPageNodeContext<T extends INode> {
    nodeId: string;
    node: T;
    millNode: IMillNode;
    millNodeId: string;
    machineNode: IMachineCodecNode;
    machineNodeId: string;
    perspective: IPerspective;
    setPageName: (overridePageName: string) => void;
}

export const PageNodeContext = React.createContext<IPageNodeContext<INode>>({
    nodeId: null,
    node: null,
    millNode: null,
    millNodeId: null,
    machineNode: null,
    machineNodeId: null,
    perspective: null,
    setPageName: null,
});

export const usePageNodeContext = <T extends INode>() => React.useContext<IPageNodeContext<T>>(PageNodeContext as React.Context<IPageNodeContext<T>>);
