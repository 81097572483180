import { ajaxRequest, getAction } from "@comact/crc";
import { API_PREFIX_PRODUCTION_MANAGER } from "js/constants";
import _ from "lodash";
import * as mocks from "./mocks";
import { ILabel, ILabelExportationServer, ILabelServer, ILabelTypes, ILabels, convertLabelsFromServer, convertLabelsToServer } from "./model";
import { actionsCreators } from "./slices";

export const getLabelsByType = (labelType: ILabelTypes) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: (): ILabelServer[] => _.isEmpty(_.filter(getState().labels, ({ type }) => type == labelType))
            ? mocks.getLabelsByType(labelType)
            : convertLabelsToServer(_.map(getState().labels)),
        url: process.env.EXEC_MODE == "icp"
            ? `${API_PREFIX_PRODUCTION_MANAGER}/downtimes/causes?nodeId=${getState().currentNodeId}&recursive=true` // FIXME: only work for LabelsType.DOWNTIME_CAUSE
            : `${API_PREFIX_PRODUCTION_MANAGER}/labels/${labelType}`,
        onSuccess: (labelsServer: ILabelServer[]) => {
            dispatch(actionsCreators.set(convertLabelsFromServer(labelsServer)));
        },
    })
);

export const saveLabels = getAction((labels: ILabel[], initialLabels: ILabels, type: ILabelTypes) => (
    async (dispatch: IStoreDispatch) => {
        const ajaxPromises: Promise<void>[] = [];

        const deleted = _.differenceBy(_.values(initialLabels), labels, ({ id }) => id);
        if (!_.isEmpty(deleted)) ajaxPromises.push(dispatch(deleteLabels(deleted, type)));

        const created = _.filter(labels, ({ id }) => id.startsWith("new_"));
        if (!_.isEmpty(created)) ajaxPromises.push(dispatch(createLabels(created, type)));

        const updated = _.filter(labels, (label) => !label.id.startsWith("new_") && !_.isEqual(label, initialLabels[label.id]));
        if (!_.isEmpty(updated)) ajaxPromises.push(dispatch(updateLabels(updated, type)));

        // wait until all the requests are completed
        await Promise.all(ajaxPromises);
        // refresh all data
        dispatch(getLabelsByType(type)).promise;
    }));

export const updateLabels = getAction((labels: ILabel[], labelType: ILabelTypes) => (dispatch) => ajaxRequest({
    method: "PATCH",
    data: convertLabelsToServer(labels),
    serverLessResolve: () => convertLabelsToServer(labels),
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels/${labelType}/?id=${_.map(labels, ({ id }) => id).join("&id=")}`,
    showAjaxLoading: true,
    onSuccess: ((labelsServer: ILabelServer[]) => {
        dispatch(actionsCreators.patch(convertLabelsFromServer(labelsServer)));
    }),
}).promise);

export const createLabels = getAction((labels: ILabel[], labelType: ILabelTypes) => (dispatch) => ajaxRequest({
    method: "POST",
    data: convertLabelsToServer(labels),
    serverLessResolve: () => convertLabelsToServer(_.map(labels, (label) => ({ ...label, id: Date.now().toString() }))), // replace new ids with real ids
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels/${labelType}/?batch=true`,
    showAjaxLoading: true,
    onSuccess: ((labelsServer: ILabelServer[]) => {
        dispatch(actionsCreators.patch(convertLabelsFromServer(labelsServer)));
    }),
}).promise);

export const deleteLabels = getAction((labels: ILabel[], labelType: ILabelTypes) => (dispatch) => ajaxRequest({
    method: "DELETE",
    serverLessResolve: () => null,
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels/${labelType}/?id=${_.map(labels, ({ id }) => id).join("&id=")}`,
    showAjaxLoading: true,
    onSuccess: (() => {
        dispatch(actionsCreators.delete(_.map(labels, ({ id }) => id)));
    }),
}).promise);

/* --- EXPORT/IMPORT LABELS REQUEST --- */

export const exportLabels = (labelType: string) => {
    const a = document.createElement("a");
    a.href = `${API_PREFIX_PRODUCTION_MANAGER}/labels/${labelType}/export`;
    a.download = labelType; // Do not remove (necessary to avoid leaving prompt and maxime being angry)
    document.body.appendChild(a);
    a.click();
};

export const unzipImportFile = getAction((data: FormData, labelType: ILabelTypes) => () => ajaxRequest({
    method: "POST",
    serverLessResolve: () => ({ machines: [], groups: [], labels: [] }),
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels/${labelType}/unzip`,
    contentType: "", // need to let the browser detect the file type itself so that it detect the multipart file boudaries
    showAjaxLoading: true,
    data,
    onSuccess: ((importData: ILabelExportationServer) => ({ ...importData, labels: convertLabelsFromServer(importData.labels) })),
}).promise);
