import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { actionsCreators as nodeActionsCreators } from "../node/slices";
import { IOptimizerConfig, IOptimizerConfigs } from "./model";

declare global {
    interface IStoreState {
        optimizerConfigs: IOptimizerConfigs;
    }
}

const useStats = "configId";
const isEqual = (a: IOptimizerConfig, b: IOptimizerConfig) => a.configId == b.configId;

const optimizerConfigs = createSlice({
    name: "optimizerConfigs",
    initialState: {} as IOptimizerConfigs,
    reducers: {
        set: (state, { payload }: PayloadAction<IOptimizerConfig[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ configId }) => configId), isEqual, useStats })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(nodeActionsCreators.setCurrentMillNodeId, () => null); // when changing mill, we remove the configs
    },
});

export const actionsCreators = {
    ...optimizerConfigs.actions,
};

export default {
    [optimizerConfigs.name]: optimizerConfigs.reducer,
};