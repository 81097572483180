import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_OPTIMIZER_CONFIGS_FILES } from "js/constants";
import _ from "lodash";
import { downloadFileFromAjaxRequest } from "../utils";
import { IOptimizerConfig } from "./model";
import { actionsCreators } from "./slices";

export const getOptimizerConfigs = getThrottleAjax((nodeId: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => (
            import("./mocks").then((m) => m.getOptimizerConfigs())
        ),
        url: `${API_PREFIX_OPTIMIZER_CONFIGS_FILES}/${nodeId}`,
        onSuccess: (optimizerConfigs: IOptimizerConfig[]) => {
            dispatch(actionsCreators.set(optimizerConfigs));
        },
    })
), 10 * 1000);

export const getOptimizerConfigsById = getAction((nodeId: string, configId: string) => async () => (
    ajaxRequest({
        serverLessResolve: () => (
            import("./mocks").then((m) => _.find(m.getOptimizerConfigs(), (c) => c.configId == configId))
        ),
        url: `${API_PREFIX_OPTIMIZER_CONFIGS_FILES}/${nodeId}/${configId}`,
        onSuccess: (optimizerConfig: IOptimizerConfig) => optimizerConfig, // No redux
    }).promise
));

export const downloadOptimizerConfig = getAction((nodeId: string, configId: string) => () => {
    const { promise, request } = ajaxRequest({
        url: `${API_PREFIX_OPTIMIZER_CONFIGS_FILES}/${nodeId}/${configId}/download`,
        rawResponse: true,
        responseType: "arraybuffer",
    });

    return promise.then(() => {
        downloadFileFromAjaxRequest(request);
    });
});