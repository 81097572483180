import { CSS } from "@comact/crc/modules/kit";
import tinycolor from "tinycolor2";

export const PROJECT_NAME = "main-wui";

// API endpoints
export const API_PREFIX_PRODUCTION_MANAGER = process.env.EXEC_MODE == "icp" ? "/api/v1" : "/production-manager/api/v1";
export const API_TWIN_V1 = process.env.EXEC_MODE == "icp" ? "/api/v1" : "/twin/api/v1";
export const API_TWIN_V2 = process.env.EXEC_MODE == "icp" ? "/api/v2" : "/twin/api/v2";
export const API_PREFIX_EVENT_MANAGER_V1 = "/event-manager/api/v1";
export const API_PREFIX_EVENT_MANAGER_V2 = "event-manager/api/v2";
export const API_PREFIX_CLOUD_AGENT = "/cloud-agent/api/v1";
export const API_PREFIX_MODELS = process.env.EXEC_MODE == "icp" ? "/api/v1/models" : "model-manager/api/v1/models";
export const API_PREFIX_DETECTIONS = process.env.EXEC_MODE == "icp" ? "/api/v1/detections" : "model-manager/api/v1/detections";
export const API_PREFIX_FILES_DASHBOARDS = process.env.EXEC_MODE == "icp" ? "/api/v1/dashboards" : `${API_PREFIX_PRODUCTION_MANAGER}/files/dashboards`;
export const API_PREFIX_USER_MANAGER = "/user-manager/api";
export const API_PREFIX_VAULT = "/vault/api/v1/vault";
export const API_PREFIX_ACCESS_TOKENS = "/api/v1/apikeys";

// BACKUPS
export const API_PREFIX_CMOC_BACKUP_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/cmoc-backups`;
export const API_PREFIX_CMOC_BACKUP_URL = `${API_PREFIX_PRODUCTION_MANAGER}/cmoc-backups-url`;
export const API_PREFIX_LOG_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/cmoc-log-backups`;

export const API_PREFIX_OPTIMIZER_CONFIGS_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/optimizer-configurations`;

export const API_PREFIX_INTERVALS = `${API_PREFIX_PRODUCTION_MANAGER}/intervals`;

export const API_PREFIX_CONTEXT_DEFINITIONS = `${API_PREFIX_PRODUCTION_MANAGER}/contexts`;

export const API_PREFIX_CLOUD_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/l5xfiles`;

export const API_PREFIX_DOWNTIMES = process.env.EXEC_MODE == "icp" ? `${API_PREFIX_PRODUCTION_MANAGER}/downtimes` : "/production-manager/api/v2/downtimes";

export const API_PREFIX_HLS = process.env.EXEC_MODE == "icp" ? "/api/v1/streams/camera/" : "/hls/";

// KPI
export const API_PREFIX_KPI_DATAIO = `${API_PREFIX_PRODUCTION_MANAGER}/data-io-kpi`;
export const API_PREFIX_KPI_PATTERNS = `${API_PREFIX_PRODUCTION_MANAGER}/kpi-patterns`;
export const API_PREFIX_KPI_ALERTS = `${API_PREFIX_PRODUCTION_MANAGER}/kpi-alerts`;

// BATCHES
export const API_PREFIX_BATCH = `${API_PREFIX_PRODUCTION_MANAGER}/schedule/batches`;
export const API_PREFIX_BATCH_CONFIG = `${API_PREFIX_PRODUCTION_MANAGER}/batch-config`;
export const API_PREFIX_BATCH_DEFINITIONS = `${API_PREFIX_PRODUCTION_MANAGER}/batch-definitions`;
export const API_PREFIX_SPECIES_DENSITY = `${API_PREFIX_PRODUCTION_MANAGER}/advanced-parameters`;

// MUNITS
export const API_PREFIX_MUNITS = `${API_PREFIX_PRODUCTION_MANAGER}/munits`;

// SHIFTS
const API_PREFIX_SCHEDULE = `${API_PREFIX_PRODUCTION_MANAGER}/schedule`;
export const API_PREFIX_SHIFTS = `${API_PREFIX_SCHEDULE}/shifts`;
export const API_PREFIX_SHIFTS_DEFINITIONS = `${API_PREFIX_SCHEDULE}/shift-definitions`;

// REPORTS
export const API_PREFIX_REPORT_CONFIGURATIONS = `${API_PREFIX_PRODUCTION_MANAGER}/report/configurations`;
export const API_PREFIX_REPORT_TEMPLATES = `${API_PREFIX_PRODUCTION_MANAGER}/report/templates`;
export const API_PREFIX_REPORT_EXECUTE = `${API_PREFIX_PRODUCTION_MANAGER}/report/execute`;
export const API_PREFIX_SAMPLES = `${API_PREFIX_PRODUCTION_MANAGER}/samples`;

// REPORTS JOBS
export const API_PREFIX_REPORT_JOBS = `${API_PREFIX_PRODUCTION_MANAGER}/report-jobs`;

// OPTIMIZER
export const API_PREFIX_OPTIMIZER_LINESPEED = "/Optimizer/api/v2/machine/line-speed";
export const API_PREFIX_OPTIMIZER_SOLUTIONS = "/Optimizer/api/vault/v1/solutions";

/** Possible colors for charts by zones. Designed in Affinity Designer. */
export const colorsChartCollection = {
    empty: ["#003564", "#0765B7", "#001B33", "#80A4C4", "#2C3238", "#141B21", "#B9B9B9"],
    normal: ["#3C9400", "#5DEA00", "#103E00", "#95D78A", "#467154", "#26451B", "#B9DFB4"],
    warn: ["#FFCC00", "#FFF000", "#CD8800", "#FFFB5F", "#CDBE32", "#8C730F", "#E7E46B"],
    error: ["#B31F1F", "#EC0000", "#850000", "#DA6969", "#A94343", "#582D2D", "#BF8484"],
};

/** Possible colors for charts (not by zones)*/
export const colorsChartCollectionMisc = [
    "#0765B7", // blue imperial
    "#a2dd9c", // lime pastel
    "#e6621d", // orange red
    "#f1ca4e", // orange yellow
    "#de425b", // pinky,
    "#003564", // blue
    "#0e8f8d", // turquoise blue
    "#f98800", // orange
    "#c98869", // skin color
    "#5c0463", // purple dark
    "#001B33", // blue dark
    "#5ab797", // turquoise green
    "#5e4527", // brown
    "#2C3238", // grey
    "#ad5599", // purple
    "#80A4C4", // blue pastel
];

export const defaultChartData = {
    datasets: [{
        steppedLine: false,
        // borderColor: tinycolor(colors.WHITE).toRgbString(),
        borderWidth: 1,
    }],
};

export const bgColors = {
    background: "rgba(100,100,100,1)",
    normal: tinycolor(CSS.colors.green).setAlpha(0.3).toRgbString(),
    warn: tinycolor(CSS.colors.yellow).setAlpha(0.3).toRgbString(),
    error: tinycolor(CSS.colors.red).setAlpha(0.3).toRgbString(),
};

export const BAR_COLOR = tinycolor(CSS.colors.blue).setAlpha(0.9).toRgbString();
export const GRID_COLOR = tinycolor(CSS.colors.white).setAlpha(0.2).toRgbString();

export const colorsChart = {
    normal: tinycolor(CSS.colors.green).toRgbString(),
    warn: tinycolor(CSS.colors.yellow).toRgbString(),
    error: tinycolor(CSS.colors.red).toRgbString(),
};