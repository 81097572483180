import { createModule } from "@comact/crc";
import * as requests from "./requests";
import slice from "./slices";

export * from "./model";

export const ProductsModule = createModule({
    reducers: { [slice.name]: slice.reducer },
    actionsCreators: slice.actions,
    requests,
});