import { selectors as rcSelectors } from "js/reports/configurations";
import _ from "lodash";
import { memoize } from "proxy-memoize";

export const getSelectedReportConfigurationIds = memoize((state: IStoreState) => state.reportJobEdit.job.reportIDs);

/* Get list of IReportConfiguration which are currently "selected" for the job being edited */
export const getSelectedReportConfigurations = memoize((state: IStoreState) => {
    const reportIds = getSelectedReportConfigurationIds(state);
    const reportConfigurations = rcSelectors.getEnabledConfigurations(state);
    return _.filter(reportConfigurations, (rc) => reportIds.includes(rc.id));
});
