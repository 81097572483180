import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_INTERVALS } from "../constants";
import * as mocks from "./mocks";
import { IInterval, IIntervalCategories, IIntervalCategory, sortIntervals } from "./model";
import * as selectors from "./selectors";
import { actionsCreators } from "./slices";

// FIXME: Définir un sélecteur pour trouver la clé
export const setIntervalCategoryEdit = (name: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    dispatch(getIntervalCategory(name)).promise.then(() => dispatch(
        actionsCreators.patchEdit([selectors.getIntervalCategory(getState(), name)])
    ))
);

export const patchIntervalCategoryEdit = (intervalCategory: Pick<IIntervalCategory, "name" | "intervals">) => (dispatch: IStoreDispatch) => (
    dispatch(actionsCreators.setOne(intervalCategory))
);

export const deleteIntervalCategoryEdit = (name: string) => (dispatch: IStoreDispatch) => (
    dispatch(actionsCreators.delete([name]))
);

export const deleteAllIntervalCategoryEdit = () => (dispatch: IStoreDispatch) => (
    dispatch(actionsCreators.deleteAll)
);

export const getAllCategories = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (_.size(getState().intervalCategories) > 0) return getState().intervalCategories;
            return mocks.getAllCategories();
        },
        url: `${API_PREFIX_INTERVALS}`,
        addPrefs: true,
        onSuccess: ((intervalCategories: IIntervalCategories) => {
            const allIntervalCategories = {};
            _.values(intervalCategories).forEach((intervalCategory) => (
                allIntervalCategories[intervalCategory.name] = { ...intervalCategory, intervals: sortIntervals(intervalCategory.intervals) }
            ));
            dispatch(actionsCreators.set(allIntervalCategories));
        }),
    })
), 5 * 1000);

export const getIntervalCategory = getThrottleAjax((name: string) => (dispatch, getState) => ajaxRequest({
    serverLessResolve: () => getState().intervalCategories[name].intervals,
    url: `${API_PREFIX_INTERVALS}/${name}`,
    addPrefs: true,
    onSuccess: ((unsortedIntervals: IInterval[]) => {
        const intervals = sortIntervals(unsortedIntervals);
        dispatch(actionsCreators.setByType({ name, intervals }));
    }),
}), 5 * 1000);

export const patchIntervalCategory = getAction((toPatchIntervalCategory: IIntervalCategory) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => toPatchIntervalCategory.intervals,
        url: `${API_PREFIX_INTERVALS}/${toPatchIntervalCategory.name}`,
        addPrefs: true,
        method: "PATCH",
        data: toPatchIntervalCategory.intervals,
        onSuccess: ((unsortedIntervals: IInterval[]) => {
            const intervals = sortIntervals(unsortedIntervals);
            dispatch(actionsCreators.setByType({ ...toPatchIntervalCategory, intervals }));
            dispatch(deleteIntervalCategoryEdit(toPatchIntervalCategory.name));
        }),
    }).promise
));