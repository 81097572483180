import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ITimberByScanners, ITimberScanner } from "./model";

declare global {
    interface IStoreState {
        timbers: ITimberByScanners;
    }
}

const useStats = "timbers";

export default createSlice({
    name: "timbers",
    initialState: {} as ITimberByScanners,
    reducers: {
        patch: (state, { payload }: PayloadAction<ITimberScanner[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ scannerId }) => scannerId), useStats })
        ),
    },
});