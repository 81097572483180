import _ from "lodash";

export interface IBatch {
    id: string;
    startDate: number;
    startTime: number;
    duration: number;
    definitionId: string;
    name: string;
}

export type IBatches = Record<string, IBatch>;

export const getBatchesByTimeRange = (batches: IBatches, begin: number, end: number): IBatches => (
    _.pickBy(batches, (batch) => (
        (batch.startDate + batch.startTime + batch.duration) >= begin && (batch.startDate + batch.startTime) <= end
    ))
);

export const getBatchesByNames = (batches: IBatches, names: string[]): IBatches => (
    _.pickBy(batches, ({ name }) => _.some(names, (n) => name == n))
);

export type BatchDefinitionStrategy = "properties" | "recipe";

export const discriminatorPropertiesTypes = ["plc1", "plc2", "plc3", "plc4", "plc5", "plc6", "plc7", "plc8", "plc9", "RawThickness", "RawWidth", "RawLength"] as const;
export type IDiscriminatorPropertiesType = typeof discriminatorPropertiesTypes[number];

export interface IBatchConfig {
    currentStrategy: BatchDefinitionStrategy;
    nameFormat: string; // null when currentStrategy == "recipe"
    machineNodeId: string;
    discriminatorProperties: string[]; // null when currentStrategy == "recipe"
    batchProcessingEnabled: boolean;
    batchThreshold: number; // null when currentStrategy == "recipe"
}

export type IBatchDefinitionRequirement = IPropertiesBatchDefinitionRequirement | IRecipeBatchDefinitionRequirement;

// only used to extend below
interface IAbstractBatchDefinitionRequirement {
    type: BatchDefinitionStrategy;
}

export interface IRequirementProperties {
    property: string;
    operation: "eq";
    value: string;
}

export interface IPropertiesBatchDefinitionRequirement extends IAbstractBatchDefinitionRequirement {
    type: "properties";
    properties: IRequirementProperties[];
}

export interface IRecipeBatchDefinitionRequirement extends IAbstractBatchDefinitionRequirement {
    type: "recipe";
    // FIXME: add more information once this model actually exists in the back-end
}

export interface IBatchDefinition {
    id: string;
    name: string;
    timestamp: number;
    requirement: IBatchDefinitionRequirement | null;
}

export type IBatchDefinitions = { [batchDefinitionId: string]: IBatchDefinition; };

export type IBatchConfigValidationError = { [key in keyof IBatchConfig]?: string[] };
export type IBatchDefinitionValidationError = { [key in keyof IBatchDefinition]?: string[] };

export const createNewBatchDefinition = (): IBatchDefinition => ({
    id: "new_" + Date.now(),
    name: "",
    timestamp: Date.now(),
    requirement: {
        type: "properties",
        properties: [{
            property: "",
            operation: "eq",
            value: "",
        }],
    },
});