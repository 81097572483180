import { constants } from "@comact/crc";
// eslint-disable-next-line no-restricted-imports
import _ from "lodash";
import moment from "moment";
import { create as createSeeder } from "random-seed";
import { IShift, IShiftDefinition, shiftDays } from "./model";
const rand = createSeeder();
const { ONE_HOUR } = constants;
const HALF_HOUR = ONE_HOUR / 2;

export const getShiftsByTimeRange = (startDateTime: Date, endDateTime: Date, shiftDefinitions?: IShiftDefinition[]): IShift[] => (
    _.filter(getShifts(shiftDefinitions), (shift) => shift.startDate > startDateTime.getTime() && shift.startDate < endDateTime.getTime())
);

const getShifts = (shiftDefinitions?: IShiftDefinition[]): IShift[] => {
    const shifts: IShift[] = [];
    if (!shiftDefinitions || _.isEmpty(shiftDefinitions)) shiftDefinitions = getShiftDefinitions();
    _.forEach(shiftDefinitions, (shiftDefinition) => {
        const start = shiftDefinition.startDateRange;
        const end = shiftDefinition.endDateRange;
        const daysCount = moment(end).diff(moment(start), "days");

        for (let i = 1; i <= daysCount; i++) {
            const dayDate = moment(start).date(i).toDate();
            rand.seed("shift_" + shiftDefinition.id);
            const daysRepetition = _.map(shiftDays, (day) => shiftDefinition[day]);
            const uniqueId = shiftDefinition.id + "-" + dayDate.getFullYear() + "-" + "month" + dayDate.getMonth() + "-" + i;
            if (daysRepetition[new Date(dayDate).getDay()]) {
                shifts.push({
                    ...shiftDefinition.shiftTemplate,
                    id: uniqueId,
                    startDate: dayDate.getTime(),
                    finished: dayDate.getTime() + shiftDefinition.shiftTemplate.startTime + shiftDefinition.shiftTemplate.duration < Date.now(),
                });
            }
        }
    });
    return shifts;
};

export const getShiftById = (shiftId: string): IShift => _.find(getShifts(), { id: shiftId });

export const getShiftDefinitions = (): IShiftDefinition[] => {
    const shiftDefinitions: IShiftDefinition[] = [];
    for (let i = 0; i < 2; i++) {
        shiftDefinitions.push({
            id: i.toString(),
            useOccurrences: true,
            canSwitchToggleOccurences: false,
            nbOccurrence: 0,
            shiftTemplate: getShift(i.toString()),
            endType: "DATE",
            startDateRange: moment().startOf("day").valueOf(),
            endDateRange: moment().startOf("day").add(1, "year").valueOf(), // same day of next year
            nbWeeks: 1,
            endOfShiftJobIds: [],
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false,
        });
    }
    return shiftDefinitions;
};

const getShift = (definitionId?: string, id?: string, startDate?: number): IShift => {
    const startTime = parseInt(definitionId, 10) % 2 == 0 ? 8 * ONE_HOUR : 23 * ONE_HOUR;
    const duration = 8 * ONE_HOUR;
    return {
        modificationStamp: Date.now(),
        id,
        name: `Shift #${definitionId}`,
        description: "Shift description " + definitionId,
        workTeam: "workTeam_" + definitionId,
        supervisor: "superviser_" + definitionId,
        events: ((() => {
            const eventDuration = rand.intBetween(HALF_HOUR / 2, HALF_HOUR);
            return [
                {
                    name: "Break #1",
                    startTime: rand.intBetween(startTime, startTime + duration - eventDuration),
                    duration: eventDuration,
                    endTime: startTime + eventDuration,
                },
            ];
        })()),
        endOfShiftJobIds: [],
        startDate,
        startTime,
        duration,
        definitionId,
    };
};