import { ajaxRequest, app, AutoRefresh, DataConfigModule, ILocalizedString, routing } from "@comact/crc";
import { API_PREFIX_REPORT_EXECUTE } from "js/constants";
import * as contextDefinitionRequests from "js/kpis/contextDefinitions/requests";
import * as configurationRequests from "js/reports/configurations/requests";
import * as shiftsRequests from "js/shifts/requests";
import { FileType } from "js/utils";
import _ from "lodash";
import * as mocks from "./mocks";
import { autoRefreshId, IReportExecutionStatusResponse, IReportExecutionTokenResponse, isReportContextSample } from "./model";
import { actionsCreators } from "./slices";
import * as validation from "./validation";

/** Start rendering a report, receive a token in response, token will then be used to check if report is ready */
export const executeReport = (fileType: FileType = FileType.pdf, asDownload: boolean = false) => (
    (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const { userPrefs: preferences, reportBrowserConfiguration: { status }, samples } = getState();
        const locale = app.language;
        const realUnitSystem = DataConfigModule.selectors.getRealUnitSystem(getState());
        const nominalUnitSystem = DataConfigModule.selectors.getNominalUnitSystem(getState());
        const unitSystem = DataConfigModule.selectors.getUnitSystem(getState());
        const { selectedReportConfigurationId, reportContextData, selectedReportContextType: type } = preferences;

        if (status != "READY") {
            return Promise.reject(new Error("Not ready"));
        }

        const selectedContext = reportContextData[type];

        // take the values from the context and the configuration and merge them
        const requestContext = isReportContextSample(selectedContext)
            ? { ...selectedContext, sampleId: samples[selectedContext.sampleId].optimizerSampleId, fileType, reportConfigurationId: selectedReportConfigurationId }
            : { ...selectedContext, fileType, reportConfigurationId: selectedReportConfigurationId };

        const isValidSampleId = isReportContextSample(selectedContext) ? samples[selectedContext.sampleId] : true;

        // passing the user's preferred unit system and locale to the report engine
        const params = [
            `locale=${locale}`,
            `system=${unitSystem}`,
            `nominal_system=${nominalUnitSystem}`,
            `real_system=${realUnitSystem}`,
        ];

        return isValidSampleId && ajaxRequest({
            method: "POST",
            data: requestContext,
            serverLessResolve: () => Promise.resolve(mocks.mockReportToken()),
            url: `${API_PREFIX_REPORT_EXECUTE}?${params.join("&")}`,
            onSuccess: ((response: IReportExecutionTokenResponse) => {
                const token = parseInt(`${response}`, 10);
                if (Number.isFinite(token)) {
                    const fileName = getReportFileName(getState().reportConfigurations[selectedReportConfigurationId].label, fileType);
                    dispatch(actionsCreators.patchOne({ token }));
                    dispatch(actionsCreators.patchOne({ url: getReportUrl(token, fileName) }));
                    dispatch(asDownload ? actionsCreators.patchOne({ downloadInfo: { fileType, fileName } }) : actionsCreators.patchOne({ downloadInfo: undefined }));
                    dispatch(actionsCreators.patchOne({ status: "WAITING" }));
                } else {
                    throw new Error("Invalid token: " + token);
                }
            }),
            onError: ((error) => {
                // reset token
                dispatch(actionsCreators.patchOne({ token: null }));
                dispatch(actionsCreators.patchOne({ status: "ERROR" }));
                // log message
                console.error(error);
            }),
        }).promise;
    }
);

/** Monitor token to check if the report is ready */
export const updateReportStatus = () => (
    (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const { reportBrowserConfiguration: { token } } = getState();
        return ajaxRequest({
            serverLessResolve: () => Promise.resolve(mocks.mockReportStatus(token)),
            url: `${API_PREFIX_REPORT_EXECUTE}/${token}/status`,
            onSuccess: ((response: IReportExecutionStatusResponse) => {
                if (response && _.has(response, "done")) {
                    if (response.done) {
                        dispatch(actionsCreators.patchOne({ status: "DONE" }));
                    } else {
                        dispatch(actionsCreators.patchOne({ status: "WAITING" }));
                    }
                } else {
                    dispatch(actionsCreators.patchOne({ status: "ERROR" }));
                }
            }),
        }).promise;
    }
);

/** Stop monitoring a token, tell server to not render report */
export const cancelReport = () => (
    (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const { reportBrowserConfiguration: { token } } = getState();
        return ajaxRequest({
            method: "POST",
            serverLessResolve: () => null,
            url: `${API_PREFIX_REPORT_EXECUTE}/${token}/cancel`,
            onSuccess: (() => {
                dispatch(actionsCreators.patchOne({ status: "CANCELED" }));
            }),
        }).promise;
    }
);

export const updateReportBrowserStatus = () => (
    (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const { userPrefs: { reportContextData, selectedReportConfigurationId, selectedReportContextType: type } } = getState();
        const status = validation.validateContext(reportContextData[type]).ok && validation.validateConfiguration(getState().reportConfigurations[selectedReportConfigurationId]).ok ? "READY" : "NOT_READY";

        // update status (will change the message in the middle)
        dispatch(actionsCreators.patchOne({ status }));
        // also render if we're ready
        if (status == "READY") {
            // update report
            dispatch(executeReport());
            // reset auto refresh timer
            AutoRefresh.getInstance(autoRefreshId).reset();
        }
    }
);

export const updateAllData = (millId: string) => (dispatch: IStoreDispatch) => (
    Promise.all([
        dispatch(shiftsRequests.getShiftsByCurrentMonth()).promise,
        dispatch(configurationRequests.getAllReportConfigurations()).promise,
        dispatch(contextDefinitionRequests.getAllContextDefinitions(millId)).promise,
    ])
);

export const initReportDownload = (fileType: FileType) => (
    (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const { userPrefs: { reportContextData, selectedReportConfigurationId, selectedReportContextType: type } } = getState();
        const status = validation.validateContext(reportContextData[type]).ok && validation.validateConfiguration(getState().reportConfigurations[selectedReportConfigurationId]).ok ? "READY" : "NOT_READY";
        // update status (will change the message in the middle)
        dispatch(actionsCreators.patchOne({ status }));
        // check if we're ready
        if (status == "READY") {
            // update report
            dispatch(executeReport(fileType, true));
        }
    }
);

export const downloadReport = () => (
    (_dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const { reportBrowserConfiguration: { url, downloadInfo: { fileName } } } = getState();

        // requires raw fetch
        return fetch(url)
            .then((response) => response.blob())
            .then((blob: Blob) => {
                // For other browsers create link pointing to ObjectURL containing the blob
                const object = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = object;
                link.download = fileName;

                // Firefox needs the link to be in the DOM to allow .click()
                link.style.display = "hidden";
                document.body.appendChild(link);

                // For Firefox, delay revoking the ObjectURL or it doesn't work
                window.setTimeout(() => {
                    link.click();
                    window.URL.revokeObjectURL(object);
                }, 100);
            });
    }
);

const getReportFileName = (label: ILocalizedString, fileType: FileType) => {
    if (app.serverLess) {
        return fileType == FileType.pdf ? "serverless.report.pdf" : "serverless.report.xls";
    }
    // get label for current locale (or use 1st valid label if current locale is not working or "Report")
    const fileName = label.values[app.language] || _.find(label.values, (v) => !!v) || "Report";

    // get extension
    const extension = (() => {
        switch (fileType) {
            case FileType.pdf:
                return ".pdf";
            case FileType.xls:
                return ".xls";
            default:
                return "";
        }
    })();
    return fileName + extension;
};

const getReportUrl = (token: number, fileName: string) => (
    app.serverLess ? `${routing.baseUrl}/static/serverLess/reports/${encodeURIComponent(fileName)}` : `${API_PREFIX_REPORT_EXECUTE}/${token}/${encodeURIComponent(fileName)}`
);
