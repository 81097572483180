import { ajaxRequest, app, DataConfigModule } from "@comact/crc";
import { API_PREFIX_OPTIMIZER_LINESPEED } from "js/constants";
import _ from "lodash";
import { IMachineCodecNode, IScannerCodecNode, NodesModule } from "../node";
import * as mocks from "./mocks";
import { ILineSpeed } from "./model";
import { actionsCreators } from "./slices";

const getLineSpeed = (scannerId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { lineSpeeds } = getState();
    const scanners = NodesModule.selectors.getNodesChildrenOfTypeRecursive<IScannerCodecNode>(getState(), getState().currentNodeId, ["scannerCodec"], true);
    const machines = NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(getState(), getState().currentNodeId, ["machineCodec"], true);
    if (!scanners[scannerId] || !machines[scanners[scannerId].parentId]) {
        return Promise.reject("Can't fetch line speed because we are missing some information");
    }
    // get the connection parameters for the machine matching the scannerId
    const machineId = scanners[scannerId].parentId;
    const index = scanners[scannerId].scannerIndex;
    const host = machines[machineId].host;
    const locale = app.language;
    const system = DataConfigModule.selectors.getUnitSystem(getState());
    return ajaxRequest<{}, ILineSpeed>({
        serverLessResolve: (): ILineSpeed => (
            lineSpeeds != null ? lineSpeeds[scannerId] : mocks.mockLineSpeed(scannerId)
        ),
        url: `//${host}${API_PREFIX_OPTIMIZER_LINESPEED}/${index}?locale=${locale}&system=${system}`,
        onSuccess: ((lineSpeed: ILineSpeed) => {
            if (lineSpeed && Object.keys(lineSpeed).includes("speed")) {
                // add the scannerId prop (because the API doesn't set it)
                dispatch(actionsCreators.patch([{ ...lineSpeed, scannerId }]));
            }
        }),
    });
};

export const getLineSpeedThrottled = (() => {
    // keep a cached copy for each scannerId to re-use them
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lineSpeedThrottledCache: { [key: string]: (dispatch: IStoreDispatch, getState: () => IStoreState) => any; } = {};
    return (scannerId: string) => {
        if (!scannerId) return null;
        if (!lineSpeedThrottledCache[scannerId]) {
            lineSpeedThrottledCache[scannerId] = _.throttle(getLineSpeed(scannerId), 5 * 1000);
        }
        return lineSpeedThrottledCache[scannerId];
    };
})();
