import { Cache } from "@comact/crc";
import { getDatabaseDefinitions } from "js/databaseDefinitions/mocks";
import { IKpiDefinition } from "js/kpis/kpiDefinitions";
import { IKpiObjective, IKpiZone, IKpiZoneValueList } from "js/kpis/kpiObjectives";
import { mockMUnits } from "js/mUnits/mocks";
import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { machineModels } from "../../node";
import { IKpiPattern } from "./model";

const rand = createSeeder();

const unitList = _.map(mockMUnits("en"), ({ id }) => id);

interface IGetKpiObjective extends Partial<IKpiObjective> { id: string; }

const getObjective = (partialData: IGetKpiObjective): IKpiObjective => {
    const min = partialData.min || rand.intBetween(-100, 0);
    const max = partialData.max || rand.intBetween(0, 100);
    const zonesCount = rand.intBetween(0, 5);
    return {
        min,
        max,
        title: partialData.id,
        zones: ((): IKpiZone[] => {
            const step = (max - min) / zonesCount;
            return _.times(zonesCount, (index) => ({
                min: _.round(min + index * step, 2),
                max: _.round(min + (index + 1) * step, 2),
                value: IKpiZoneValueList[rand.intBetween(0, IKpiZoneValueList.length - 1)],
            }));
        })(),
        ...partialData,
    };
};

const getObjectives = (count: number): { [id: string]: IKpiObjective; } => {
    const objectives = _.keyBy(
        _.times(count, (index) => getObjective({ id: "BATCH" + index }))
        , ({ id }) => id
    );
    objectives.default = getObjective({ id: "default" }); // FIXME: Probablement faire les "par défaut" dans le modèle
    return objectives;
};

interface IGetDefinition extends Partial<IKpiDefinition> { id: string; }

const getKpiDefinition = (partialData: IGetDefinition): IKpiDefinition => {
    const { id } = partialData;
    return {
        title: { values: { fr: id, en: id, fi: id, pt: id } },
        shortTitle: { values: { fr: id, en: id, fi: id, pt: id } },
        objectives: getObjectives(rand.intBetween(1, 4)),
        ...partialData,
    };
};

const getKpiDefinitions = (patternId: string, count: number) => (
    _.keyBy(
        _.times(count, (index) => getKpiDefinition({
            id: "Definition " + (index + 1) + "[" + patternId + "]",
        }))
        , ({ id }) => id
    )
);

interface IGetKpiPattern extends Partial<IKpiPattern> { id: string; }
export const getKpiPattern = (partialData: IGetKpiPattern): IKpiPattern => {
    rand.seed("kpiPattern_" + partialData.id);
    const databases = getDatabaseDefinitions();
    const database = _.keys(databases)[rand.intBetween(0, _.size(databases) - 1)];
    const databaseDefinition = databases[database];
    const collection = !_.isEmpty(databaseDefinition.tags);
    const unit = unitList[rand.intBetween(0, unitList.length - 1)];
    return {
        formula: "Formula to fake for " + partialData.id,
        filterFormula: "Filter formula for " + partialData.id,
        filterRatio: false,
        order: null,
        modificationDate: Date.now(),
        title: { values: { en: partialData.id + " En", fr: partialData.id + " Fr" } },
        uniqueName: formatIdURLFriendly(partialData.id),
        machinesModel: machineModels[rand.intBetween(0, _.size(machineModels) - 1)],
        machinesScanner: "0",
        groups: collection ? ["$" + database + "." + databaseDefinition.tags[0]] : [],
        groupRatio: false,
        precision: 3,
        unitType: unit,
        unitLabel: null,
        collection,
        tags: [],
        useNumberContext: false,
        database,
        objectiveTimeBased: false,
        objectiveTimeUnit: "HOURS",
        kpiDefinitions: {
            ["default"]: getKpiDefinition({ id: "default" }),
            ...getKpiDefinitions(partialData.id, rand.intBetween(0, 5)),
        },
        ...partialData,
        system: rand.intBetween(0, 3) == 3,
        nodeIds: [],
    };
};

const formatIdURLFriendly = (id: string) => _.kebabCase(id);

export const getKpiPatterns = () => (
    Cache.remember("allKpiPatterns_", () => (
        _.keyBy(
            _.times(20, (index) => getKpiPattern({
                id: "Pattern " + (index + 1),
            }))
            , ({ id }) => id
        )
    ))
);