import { t } from "@comact/crc";
import _ from "lodash";
import { IKpiDataIOError, IKpiDataIOErrorList } from "../edition/model";
import { IKpiDataIO, IKpiDataIOs } from "../model";

export const validateKpiDataIO = (kpiDataIO: IKpiDataIO, allKpiDataIOs: IKpiDataIOs): IKpiDataIOError => {
    const errors: IKpiDataIOError = {
        nodeId: [],
        contextId: [],
        definitionId: [],
        patternKey: [],
        index: [],
    };

    if (_.isEmpty(kpiDataIO.nodeId)) errors.nodeId.push(t("core:errors.notEmpty"));
    if (_.isEmpty(kpiDataIO.contextId)) errors.contextId.push(t("core:errors.notEmpty"));
    if (_.isEmpty(kpiDataIO.definitionId)) errors.definitionId.push(t("core:errors.notEmpty"));
    if (_.isEmpty(kpiDataIO.patternKey)) errors.patternKey.push(t("core:errors.notEmpty"));

    if (!_.isFinite(kpiDataIO.index)) errors.index.push(t("core:errors.notEmpty"));
    if (_.some(allKpiDataIOs, (d) => d.id != kpiDataIO.id && d.index == kpiDataIO.index)) errors.index.push(t("kpiDataIO.validation.indexCollision"));

    return _.omitBy(errors, _.isEmpty); // Remove empty key
};

export const validateAllKpiDataIOs = (kpiDataIOs: IKpiDataIOs): IKpiDataIOErrorList => {
    const errors: IKpiDataIOErrorList = {};
    _.forEach(kpiDataIOs, (kpiDataIO, id) => errors[id] = validateKpiDataIO(kpiDataIO, kpiDataIOs));
    return _.omitBy(errors, _.isEmpty);
};