import _ from "lodash";
import { memoize } from "proxy-memoize";
import { INodes } from "../../node";
import { getNodeNamePath } from "../../node/selectors";
import { IKpiPatternWithDefinitions } from "../kpiDefinitions/model";
import { IKpiPattern, IKpiPatterns, createKpiDefinitionUniqueId } from "./model";

interface IKpiFilters {
    showKpisOfKpis?: boolean;
    showKpiCollections?: boolean;
    nodeIdsToFilter?: string[];
}

export const getKpiPatterns = memoize(({ state, filters = { showKpiCollections: true, showKpisOfKpis: true, nodeIdsToFilter: [] } }: { state: IStoreState; filters?: IKpiFilters; }): IKpiPatterns => (
    state.kpiPatterns
        ? _.chain(state.kpiPatterns)
            .filter((kpiPattern) => (
                (_.isEmpty(filters.nodeIdsToFilter) || _.some(kpiPattern.nodeIds, (nodeId) => _.includes(filters.nodeIdsToFilter, nodeId))) &&
                (filters?.showKpisOfKpis || kpiPattern.database != "kpi") &&
                (filters?.showKpiCollections || !kpiPattern.collection)
            ))
            .keyBy(({ id }) => id)
            .value()
        : null
));

export const getKpiPatternsWithDefinitions = memoize(({ state, filters = { showKpisOfKpis: true, nodeIdsToFilter: [] } }:
{
    state: IStoreState;
    filters?: IKpiFilters;
}) => (
    _.reduce(getKpiPatterns({ state, filters }), (tempKPIs, kpiPattern) => {
        tempKPIs[kpiPattern.id] = kpiPattern;
        if (kpiPattern.collection) {
            _.forEach(kpiPattern.kpiDefinitions, ({ id: definitionId }) => {
                if (definitionId == "default") return; // For the kpi collections, the default definition is not actually a definition, it's a template for the other definitions generated
                const defUniqueId = createKpiDefinitionUniqueId(kpiPattern.uniqueName, definitionId, kpiPattern.collection);
                tempKPIs[defUniqueId] = ({
                    ...kpiPattern,
                    id: defUniqueId,
                    uniqueName: defUniqueId,
                    kpiDefinitions: null,
                    collection: false,
                    definitionTitle: kpiPattern.kpiDefinitions[definitionId].title,
                    isDefinition: true,
                });
            });
        }
        return tempKPIs;
    }, {} as IKpiPatternWithDefinitions)
));

export const getKpiPatternById = (state: IStoreState, id: string): IKpiPattern => getKpiPatterns({ state })?.[id];

export const getKpiPatternByKey = (state: IStoreState, key: string): IKpiPattern => _.find(getKpiPatterns({ state }), (k) => k.uniqueName == key);

export const makeKpiPatternByKeySelector = () => memoize(({ state: { kpiPatterns }, uniqueName }: { state: IStoreState; uniqueName: string; }) => (
    _.find(kpiPatterns, (pattern) => pattern.uniqueName == uniqueName)
));

export const getNodesAvailableInKpiPatterns = memoize(({ kpiPatterns, nodes }: IStoreState) => (
    _.reduce(kpiPatterns, (acc, k) => {
        _.forEach(k.nodeIds, (nId) => {
            acc[nId] = nodes?.[nId];
        });
        return acc;
    }, {} as INodes)
));

export const makeNodeNamesAvailable = () => memoize(({ state, nodeIds }: { state: IStoreState; nodeIds: string[]; }) => (
    _.map(nodeIds, (nodeId) => {
        const node = state.nodes?.[nodeId];
        if (!node) return nodeId;
        return getNodeNamePath(state, node.id);
    })
));

export const getAvailableDatabases = memoize(({ kpiPatterns }: IStoreState) => (
    _.chain(kpiPatterns)
        .map(({ database }) => database)
        .uniq()
        .orderBy(((value) => value.toLocaleLowerCase())) // To place the option "No DataType" at the beginning
        .value()
));
