import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ICmocBackup, ICmocBackups } from "./model";

declare global {
    interface IStoreState {
        cmocBackups: ICmocBackups;
    }
}

const useStats = "cmocBackups";
const isEqual = (a: ICmocBackup, b: ICmocBackup) => a.dateTime == b.dateTime;

const cmocBackups = createSlice({
    name: "cmocBackups",
    initialState: {} as ICmocBackups,
    reducers: {
        set: (state, { payload }: PayloadAction<ICmocBackup[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ name }) => name), isEqual, useStats })
        ),
    },
});

export const actionsCreators = {
    ...cmocBackups.actions,
};

export default {
    [cmocBackups.name]: cmocBackups.reducer,
};