import _ from "lodash";
import { memoize } from "proxy-memoize";
import { IEvent, IEventAlarm, IEventPunctual, ISortOrder, alarmEventTypes, punctualEventTypes } from "./model";

export const getSystemEventsByType = memoize(<T
extends IEvent>({ state, selectedTypes,
    selectedNodes = [], sortOrder = "asc" }:
{
    state: IStoreState;
    selectedTypes: T["type"][] | null;
    selectedNodes?: string[];
    sortOrder?: ISortOrder; }
): T[] => {
    if (!state.systemPunctualEvents || !state.systemDurationEvents) return null; // We want both event types before returning events
    const hasSelectedPunctual = !selectedTypes || !_.isEmpty(_.intersection(punctualEventTypes, selectedTypes as IEventPunctual["type"][]));
    const hasSelectedAlarm = !selectedTypes || !_.isEmpty(_.intersection(alarmEventTypes, selectedTypes as IEventAlarm["type"][]));

    const events: IEvent[] = [
        ...(hasSelectedPunctual ? _.values(state.systemPunctualEvents) : []),
        ...(hasSelectedAlarm ? _.values(state.systemDurationEvents) : []),
    ];
    return _.chain(events)
        .filter(({ type, nodeId }) => (
            (!selectedTypes || _.includes(selectedTypes, type)) && // if user has selected types, filter by type
            (_.isEmpty(selectedNodes) || _.includes(selectedNodes, nodeId)) // if user has selected nodes, filter by node
        ))
        .orderBy(({ date }) => date, [sortOrder])
        .value() as T[];
});

export const punctualAndDurationEventsReady = ({ systemPunctualEvents, systemDurationEvents }: IStoreState) => systemPunctualEvents && systemDurationEvents;
