import { store, useMapStateMemoize } from "@comact/crc";
import { CSS, Card, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import WidgetMachine from "../../dashboards/widgets/WidgetMachine";
import WidgetMultiKpis from "../../dashboards/widgets/WidgetMultiKpis";
import { IMachineCodecNode, IScannerCodecNode } from "../model";
import * as selectors from "../selectors";

const NodeMachineKpisCardStyled = styled(Card)`
    display: grid;
    grid-gap: ${CSS.gutter}px;
    grid-template-columns: 140px 1fr;
    min-height: 220px;
    position: relative;
    min-width: 200px;
`;

const NodeMachineKpisCard = React.memo(({ id }: { id: string; }) => (
    <NodeMachineKpisCardStyled key={id}>
        <HighlightMachine id={id} />
        <HighlightKpis id={id} />
    </NodeMachineKpisCardStyled>
));

export default NodeMachineKpisCard;

const HighlightMachine = React.memo(({ id }: { id: string; }) => {
    const props = useMapStateMemoize((state) => {
        const nodeMachine = selectors.getNodeById<IMachineCodecNode>(state, id);
        return { queryRecipe: { id, machine: nodeMachine.machine, templateName: "machineCodec" } } as React.ComponentPropsWithRef<typeof WidgetMachine>["props"];
    }, [id]);
    return props && <WidgetMachine props={props} design="subtle" padding="none" />;
});

const HighlightKpis = React.memo(({ id }: { id: string; }) => {
    const props = useMapStateMemoize((state) => {
        const def = _.find(state.contextDefinitions, (c) => c.uniqueReferenceName == "DASHBOARD_CTX_SHIFT_DEFAULT1");
        if (!def) return null;
        const scanners = selectors.getNodesChildrenOfType<IScannerCodecNode>(store.getState(), id, ["scannerCodec"]);
        if (_.isEmpty(scanners)) return null;
        const scanner = _.find(scanners, (s) => s.scannerIndex == "0");
        return {
            kpiQueryRecipes: {
                contexts: [{ id: def.id, key: def.uniqueReferenceName, type: "context" }],
                kpis: [
                    { definitionId: "default", patternKey: "product-fbm", type: "kpiDefinition" },
                    { definitionId: "default", patternKey: "product-fbm-projected", type: "kpiDefinition" },
                    { definitionId: "default", patternKey: "uptime-ratio", type: "kpiDefinition" },
                    { definitionId: "default", patternKey: "stem-count", type: "kpiDefinition" },
                    { definitionId: "default", patternKey: "product-count", type: "kpiDefinition" },
                ],
                nodes: [
                    {
                        id: scanner.id,
                        machine: scanner.machine,
                        scannerIndex: scanner.scannerIndex,
                        templateName: "scannerCodec",
                    },
                ],
            },
            viewType: "LinearGaugeSimple",
            colCount: 1,
            rowCount: 5,
        } as React.ComponentPropsWithoutRef<typeof WidgetMultiKpis>["props"];
    }, [id]);

    return props && <WidgetMultiKpis props={props} design="noBackground" padding="none" />;
});