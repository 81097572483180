import { ajaxRequest, app, getThrottleAjax } from "@comact/crc/index";
import { API_PREFIX_MUNITS } from "js/constants";
import _ from "lodash";
import { mockMUnits } from "./mocks";
import { IMUnit } from "./model";
import { actionsCreators } from "./slices";

export const fetchUnitDefinitionTranslationsThrottled = getThrottleAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => mockMUnits(app.language),
        url: `${API_PREFIX_MUNITS}?locale=${app.language}`,
        onSuccess: (response: IMUnit[]) => {
            dispatch(actionsCreators.set(_.keyBy(response, ({ id }) => id)));
        },
    })
), 60 * 1000);
