/* eslint-disable max-len */
import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { API_PREFIX_CLOUD_AGENT, API_TWIN_V1, API_TWIN_V2 } from "../constants";
import { IConnexionStatuses, INode, INodeServer, convertNodeToServer, isRejectedProperty } from "./model";
import { getNodeById } from "./selectors";
import { actionsCreators } from "./slices";

const FETCH_ALL_NODE_PACE = process.env.EXEC_MODE == "icp" ? 10 * 1000 : 5 * 1000; // ICP (azure) is so buggy/slow
export const fetchAllNodes = getThrottleAjax(() => (dispatch, getState) => (
    getState().system.isMaster && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.fetchAllNodes()),
        url: `${API_TWIN_V2}/nodes`,
        timeout: FETCH_ALL_NODE_PACE,
        onSuccess: (nodesFromServer: INodeServer[]) => {
            dispatch(actionsCreators.set(nodesFromServer));
            return getState().nodes;
        },
    })
), FETCH_ALL_NODE_PACE);

export const saveNodeLinks = getAction((node: Partial<INode>) => async (dispatch) => (
    process.env.EXEC_MODE == "cmoc"
        ? dispatch(saveNode(node)) // for the cmoc, we use the same request for the regular save process
        : ajaxRequest({
            serverLessResolve: () => ({ ...node, modificationDate: Date.now() }),
            url: `${API_TWIN_V1}/nodes/${node.id}`,
            method: "PATCH",
            data: node,
            onSuccess: (nodeFromServer: INodeServer) => dispatch(actionsCreators.patch([nodeFromServer])),
        }).promise
));

export const saveNode = getAction((node: Partial<INode>) => async (dispatch, getState) => (
    node.id
        ? ajaxRequest({ // Update existing node
            serverLessResolve: () => _.assign({}, getNodeById(getState(), node.id), { ...node, modificationDate: Date.now() }) as INode,
            url: `${API_TWIN_V2}/nodes/${node.id}/desired`,
            method: "PATCH",
            data: ((() => {
                const originalNode = getNodeById(getState(), node.id);
                return _.chain(node.desired)
                    .pickBy((_value, property) => !_.isEqual(node.desired?.[property], node?.[property]) || isRejectedProperty(originalNode, property)) // only send modify or rejected properties
                    .mapValues((value, property) => ({
                        property,
                        value,
                        timestamp: Date.now(),
                    }))
                    .values()
                    .value();
            })()),
            onSuccess: (nodeFromServer: INodeServer) => {
                dispatch(actionsCreators.patch([nodeFromServer]));
                return nodeFromServer; // need to be return for the form
            },
        }).promise
        : ajaxRequest({ // Creates a new node
            serverLessResolve: () => ({ ...node, modificationDate: Date.now(), id: uuid() }),
            url: `${API_TWIN_V2}/nodes`,
            method: "POST",
            data: ((() => {
                const { reported, ...serverNode } = convertNodeToServer(node as INode); // find a way for convertNodeToServer to use Partial<INode>
                return { ...serverNode, reported: {} }; // remove the reported properties
            })()),
            onSuccess: (nodeFromServer: INodeServer) => {
                dispatch(actionsCreators.patch([nodeFromServer]));
                return nodeFromServer; // need to be return for the form
            },
        }).promise
));

export const deleteNode = getAction((id: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => ({ ...m.fetchNode(id) })), // this won't do anything in serverless
        url: `${API_TWIN_V2}/nodes/${id}`,
        method: "DELETE",
        onSuccess: () => dispatch(actionsCreators.deleteRecursive(id)),
    }).promise
));

export const getNodeConnexionStatuses = getThrottleAjax(() => (dispatch, getState) => (
    getState().system.cloudEnabled && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getAllAllConnexionStatuses()),
        url: `${API_PREFIX_CLOUD_AGENT}/devices/statuses`,
        onSuccess: (connexionStatuses: IConnexionStatuses) => {
            dispatch(actionsCreators.setNodeConnexionStatuses(connexionStatuses));
        },
    })
), 5 * 1000);