/* eslint-disable react/jsx-no-target-blank */
import { Cache, Icon, RootModalsComponents, SimpleModal, routing, store, t, useForceUpdate, useIntervalAjaxRequest, useMapState, useMapStateMemoize } from "@comact/crc";
import { Box, Buttons, ButtonsContainer, Card, Line, ListRow, Span, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import { ELearningModule } from "../elearning";
import { IMachineCodecNode, NodesModule } from "../node";

const SimpleModalStyled = styled(SimpleModal)`
    .content {
        border: 1px solid red;
        width: 400px;
        min-width: max-content;
    }
`;

const IconStyled = styled.span`
    display: inline-block;
    > * {
        font-size: 18px;
    }
    > .svg-icon {
        display: inline-block;
        height: 20px;
        width: 20px;
        > svg {
            height: unset;
            width: unset;
            position: unset;
            bottom: unset;
        }
    }
`;

const RequestSentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
`;

/**
 * A modal to choose a control center to go to
 */
const ControlCentersModal = React.memo(({ onClose }: { onClose: () => unknown; }) => {
    const nodes = useMapState((state) => NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec", "iep"]));
    return (
        <SimpleModalStyled onClose={onClose} header={t("core:cmoc.services.controlCenter")}>
            <ListRow kPad="none">
                <Box>
                    <ListRow kGap="small" kPad="none">
                        <Line kFontWeight="light" kColor="light">{t("modals.controlCenter.description1")}</Line>
                        <Line kFontWeight="light" kColor="light">{t("modals.controlCenter.description2")}</Line>
                    </ListRow>
                </Box>
                {_.map(nodes, (node) => (
                    <Card key={node.id} href={routing.createUrl(`${document.location.protocol}//${node.host}/control-center`)}>
                        <IconStyled><Icon type="control-center" /></IconStyled>{" "}{node.name} <Span kSize="small" kFontWeight="light" kColor="light">({node.version})</Span>
                    </Card>
                ))}
            </ListRow>
        </SimpleModalStyled>
    );
});

/**
 * A modal to choose a sorter to go to
 */
const SortersModal = React.memo(({ onClose }: { onClose: () => unknown; }) => {
    const sorters = useMapStateMemoize((state) => (
        _.chain(NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec"]))
            .filter((n) => n.sorterEnabled)
            .map((n) => ({ id: n.id, name: n.name, url: `${document.location.protocol}//${n?.host}/sorter` }))
            .value()
    ));
    return (
        <SimpleModalStyled onClose={onClose} header={t("core:cmoc.services.sorter")}>
            <ListRow kPad="none">
                <Box>
                    <ListRow kGap="small" kPad="none">
                        <Line kFontWeight="light" kColor="light">{t("modals.sorter.description1")}</Line>
                        <Line kFontWeight="light" kColor="light">{t("modals.sorter.description2")}</Line>
                    </ListRow>
                </Box>
                {_.map(sorters, (sorter) => (
                    <Card key={sorter.id} href={routing.createExternalUrl(sorter.url)}>
                        <IconStyled><Icon type="sorter" /></IconStyled>{" "}{sorter.name}
                    </Card>
                ))}
            </ListRow>
        </SimpleModalStyled>
    );
});

/**
 * A modal to choose a diagnostic to go to
 */
const DiagnosticsModal = React.memo(({ onClose }: { onClose: () => unknown; }) => {
    const diagnostics = useMapStateMemoize((state) => (
        _.chain(NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec"]))
            .filter((n) => n.diagnosticEnabled)
            .map((n) => ({ id: n.id, name: n.name, url: `${document.location.protocol}//${n?.host}/diagnostic` }))
            .value()
    ));
    return (
        <SimpleModalStyled onClose={onClose} header={t("core:cmoc.services.diagnostic")}>
            <ListRow kPad="none">
                <Box>
                    <ListRow kGap="small" kPad="none">
                        <Line kFontWeight="light" kColor="light">{t("modals.diagnostic.description1")}</Line>
                        <Line kFontWeight="light" kColor="light">{t("modals.diagnostic.description2")}</Line>
                    </ListRow>
                </Box>
                {_.map(diagnostics, (diagnostic) => (
                    <Card key={diagnostic.id} href={routing.createExternalUrl(diagnostic.url)}>
                        <IconStyled><Icon type="diagnostic" /></IconStyled>{" "}{diagnostic.name}
                    </Card>
                ))}
            </ListRow>
        </SimpleModalStyled>
    );
});

/**
 * A modal to launch the java interface.
 */
const InterfacesLauncherModal = React.memo<{ onClose: () => unknown; }>(({ onClose }) => {
    const iframeRef = React.useRef<HTMLIFrameElement>();

    const nodes = useMapState((state) => NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec"]));

    const onInstallButton = React.useCallback(() => {
        iframeRef.current.src = "/comact-launcher/comact-launcher.exe";
    }, []);

    const onLaunch = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        const { currentTarget: { dataset: { src } } } = e;
        iframeRef.current.src = src;
        setTimeout(onClose, 1000); // avoid modal to close before the iframe.src has time to be changed, otherwise sometimes the launcher does not open
    }, [onClose]);

    return (
        <SimpleModal onClose={onClose} constentStyle={{ width: "400px" }} header={t("core:interfaces-launcher.modal.title")}>
            <ListRow kPad="none">
                <img src={require("src/img/interfaces-screenshot.png")} style={{ display: "block", margin: "0 auto 15px auto", width: "150px" }} />
                <Box>
                    <ListRow kGap="small" kPad="none">
                        <Line kFontWeight="light" kColor="light">{t("core:interfaces-launcher.modal.description.line1")}</Line>
                        <Line kFontWeight="light" kColor="light">{t("core:interfaces-launcher.modal.description.line2")}</Line>
                    </ListRow>
                    <br />
                    <div>
                        <Buttons.Primary onClick={onInstallButton} children={t("core:interfaces-launcher.modal.button.install")} />
                    </div>
                </Box>
                {_.map(nodes, (node) => (
                    <Card
                        key={node.id}
                        data-src={`comact-launcher:?host=${encodeURIComponent(node.host)}&port=8080&name=${encodeURIComponent(node.name)}&machineType=${encodeURIComponent(node.machine)}`}
                        onClick={onLaunch}
                    >
                        <IconStyled><Icon type="interfaces-launcher" /></IconStyled>{" "}{node.name}
                    </Card>
                ))}
            </ListRow>
            {/* iframe will remain hidden and is only used to trigger downloads without changing the url of the page */}
            <iframe ref={iframeRef} style={{ display: "none" }} />
        </SimpleModal>
    );
});

/**
 * A modal to show the ELearning links. When there are multiple we show a list of links. When there are none we show a Request Demo button.
 */
const ELearningModal = React.memo<{ onClose: () => unknown; }>(({ onClose }) => {
    const data = useMapState((state) => state.elearning?.sharepointLinks);
    const forceUpdate = useForceUpdate();

    const onElearningDemoRequest = React.useCallback(() => {
        if (Cache.fetch("elearningRequestDemo")) return;
        Cache.add("elearningRequestDemo", true);
        store.dispatch(ELearningModule.requests.requestADemo());
        forceUpdate();
    }, [forceUpdate]);
    useIntervalAjaxRequest((dispatch) => dispatch(ELearningModule.requests.getElearningLinks()), 10 * 60 * 1000);

    return (
        <SimpleModal onClose={onClose} constentStyle={{ width: _.isEmpty(data) ? "600px" : "400px" }} header={t("elearning.modal.title")}>
            <ListRow kGap="big" kPad="none">
                <Box>
                    <ListRow kGap="small" kPad="none">
                        <Span
                            kFontWeight="light"
                            kColor="light"
                            dangerouslySetInnerHTML={{
                                __html: t("elearning.modal.description", {
                                    markettingLink: `<a href="${t("elearning.marketting.main.link")}" target="_blank">${t("elearning.marketting.main.title")}</a>`,
                                }),
                            }}
                        />
                        {!_.isEmpty(data)
                            ? <Span kFontWeight="light" kColor="light">{t("elearning.modal.notices.multipleAccess")}</Span>
                            : (
                                <Span
                                    kFontWeight="light"
                                    kColor="light"
                                    dangerouslySetInnerHTML={{
                                        __html: t("elearning.modal.notices.noAccess", {
                                            glcOnlineMarkettingLink: `<a href="${t("elearning.marketting.online.link")}" target="_blank">${t("elearning.marketting.online.title")}</a>`,
                                        }),
                                    }}
                                />
                            )
                        }
                    </ListRow>
                </Box>
                {_.isEmpty(data) && (
                    <>
                        <iframe style={{ border: "none" }} width="100%" height="365" src={t("elearning.marketting.video")} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" />
                        <ButtonsContainer>
                            {Cache.fetch("elearningRequestDemo")
                                ? (
                                    <RequestSentContainer>
                                        <Span kFontWeight="bold">{t("elearning.modal.requestDemo.success")}</Span>
                                    </RequestSentContainer>
                                )
                                : (
                                    <Buttons.Primary onClick={onElearningDemoRequest} disabled={Cache.fetch("elearningRequestDemo")}>
                                        {t("elearning.marketting.callToAction.title")}
                                    </Buttons.Primary>
                                )
                            }
                        </ButtonsContainer>
                    </>
                )}
                {_.map(data, (spLink) => (
                    <Card key={spLink.title} href={spLink.url} target="_blank">
                        <IconStyled><Icon type="external-link" /></IconStyled>{" "}{spLink.title}
                    </Card>
                ))}
            </ListRow>
        </SimpleModal>
    );
});

const Modals = {
    openControlCenters: () => RootModalsComponents.createModal((close) => <ControlCentersModal onClose={close} />).open(),
    openSorters: () => RootModalsComponents.createModal((close) => <SortersModal onClose={close} />).open(),
    openDiagnostics: () => RootModalsComponents.createModal((close) => <DiagnosticsModal onClose={close} />).open(),
    openInterfaceLauncher: () => RootModalsComponents.createModal((close) => <InterfacesLauncherModal onClose={close} />).open(),
    openElearningLauncher: () => RootModalsComponents.createModal((close) => <ELearningModal onClose={close} />).open(),
};

export default Modals;