import _ from "lodash";
import { memoize } from "proxy-memoize";
import { NodesModule } from "../node";

export const getAll = memoize(({ downtimes }: IStoreState) => downtimes);

export const getAllForNode = ({ state, nodeId }: { state: IStoreState; nodeId: string; }) => {
    const node = NodesModule.selectors.getNodeById(state, nodeId);
    if (!node) return {};
    const allDowntimes = getAll(state);
    switch (node.templateName) {
        case "millCodec": return allDowntimes;
        case "machineCodec": return _.pickBy(allDowntimes, (d) => d.nodeId == nodeId);
        default: return {};
    }
};

export const getById = memoize(({ state, id }: { state: IStoreState; id: string; }) => getAll(state)?.[id]);