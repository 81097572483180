import { ajaxRequest, getAction } from "@comact/crc";
import { API_PREFIX_PRODUCTION_MANAGER } from "js/constants";
import _ from "lodash";
import { ILabelTypes } from "../labels/model";
import * as mocks from "./mocks";
import { ILabelsGroup, ILabelsGroupServer, convertLabelGroupsFromServer, convertLabelGroupsToServer } from "./model";
import { actionsCreators } from "./slices";

export const getLabelGroupsByType = (labelType: ILabelTypes) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: (): ILabelsGroupServer[] => _.isEmpty(_.filter(getState().labelGroups, ({ type }) => type == labelType))
            ? mocks.getLabelGroupsByType(labelType)
            : convertLabelGroupsToServer(_.map(getState().labelGroups)),
        url: process.env.EXEC_MODE == "icp"
            ? `${API_PREFIX_PRODUCTION_MANAGER}/downtimes/groups?nodeId=${getState().currentNodeId}&recursive=true` // FIXME: only work for LabelsType.DOWNTIME_CAUSE
            : `${API_PREFIX_PRODUCTION_MANAGER}/labels-groups/${labelType}`,
        onSuccess: (labelGroups: ILabelsGroupServer[]) => {
            dispatch(actionsCreators.set(convertLabelGroupsFromServer(labelGroups)));
        },
    })
);

export const saveLabelGroups = getAction((labelGroups: ILabelsGroup[], initialLabels: ILabelsGroup[], labelsType: ILabelTypes) => (
    async (dispatch: IStoreDispatch) => {
        const ajaxPromises: Promise<void>[] = [];

        const deleted = _.differenceBy(initialLabels, _.values(labelGroups), ({ id }) => id);
        if (!_.isEmpty(deleted)) ajaxPromises.push(dispatch(deleteLabelGroups(deleted, labelsType)));

        const created = _.filter(labelGroups, ({ id }) => id.startsWith("new_"));
        if (!_.isEmpty(created)) ajaxPromises.push(dispatch(createLabelGroups(created, labelsType)));

        const updated = _.filter(labelGroups, (group) => !group.id.startsWith("new_") && !_.isEqual(group, initialLabels[group.id]));
        if (!_.isEmpty(updated)) ajaxPromises.push(dispatch(updateLabelGroups(updated, labelsType)));

        // wait until all the requests are completed
        await Promise.all(ajaxPromises);
        // refresh all data
        dispatch(getLabelGroupsByType(labelsType)).promise;
    }));

export const updateLabelGroups = getAction((groups: ILabelsGroup[], labelsType: ILabelTypes) => (dispatch) => ajaxRequest({
    method: "PATCH",
    data: convertLabelGroupsToServer(groups),
    serverLessResolve: () => convertLabelGroupsToServer(groups),
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels-groups/${labelsType}/?id=${_.map(groups, ({ id }) => id).join("&id=")}`,
    showAjaxLoading: true,
    onSuccess: ((allGroups: ILabelsGroupServer[]) => {
        dispatch(actionsCreators.patch(convertLabelGroupsFromServer(allGroups)));
    }),
}).promise);

export const createLabelGroups = getAction((groups: ILabelsGroup[], labelsType: ILabelTypes) => (dispatch) => ajaxRequest({
    method: "POST",
    data: convertLabelGroupsToServer(groups),
    serverLessResolve: () => convertLabelGroupsToServer(_.map(groups, (group) => ({ ...group, id: Date.now().toString() }))), // replace new ids with real ids
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels-groups/${labelsType}/?batch=true`,
    showAjaxLoading: true,
    onSuccess: ((allGroups: ILabelsGroupServer[]) => {
        dispatch(actionsCreators.patch(convertLabelGroupsFromServer(allGroups)));
    }),
}).promise);

export const deleteLabelGroups = getAction((groups: ILabelsGroup[], labelsType: ILabelTypes) => (dispatch) => ajaxRequest({
    method: "DELETE",
    serverLessResolve: () => null,
    url: `${API_PREFIX_PRODUCTION_MANAGER}/labels-groups/${labelsType}/?id=${_.map(groups, ({ id }) => id).join("&id=")}`,
    showAjaxLoading: true,
    onSuccess: (() => {
        dispatch(actionsCreators.delete(_.map(groups, ({ id }) => id)));
    }),
}).promise);
