import { getLocalizedText, IChartGraphProps, IGaugeProps, IPluginZoneBackgroundOptions, ITextBoxProps, PluginZoneBackground } from "@comact/crc";
import { CSS } from "@comact/crc/modules/kit";
import Chart from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { IQueryResults } from "js/common";
import { ILinearGaugeProps } from "js/components/LinearGauges";
import { BAR_COLOR, bgColors, colorsChart, colorsChartCollection, colorsChartCollectionMisc, defaultChartData, GRID_COLOR } from "js/constants";
import WidgetTitle from "js/dashboards/widgets/WidgetTitle";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import tinycolor from "tinycolor2";
import { IEventICP } from "../../events/eventsICP/model";

Chart.pluginService.register(annotationPlugin as unknown);

export const ANNOTATIONS_PLUGIN_ID = "annotations";

/**
 * Convert QueryResults for the Gauge Component view props (radial gauge)
 */
export const convertForRadialGauge = (results: IQueryResults): IGaugeProps => {
    const { title, data, precision, unitSymbol } = results;
    const { value } = _.last(data);
    const objective = getObjective(results);
    const targetValue = objective?.target;
    const min = objective?.min || 0; // ou lastData.min
    const max = objective?.max || 100; // ou lastData.max
    const clampedValue = _.clamp(value, min, max);
    const zones = (objective?.zones || []).map((o) => ({ type: o.value, minimum: o.min, maximum: o.max })).filter((z) => z.type != "empty") as IGaugeProps["zones"];
    const targetZone = zones.find((z) => clampedValue >= z.minimum && clampedValue < z.maximum);
    const header = <WidgetTitle color={targetZone?.type || "empty"} style={{ marginBottom: 0 }} children={getLocalizedText(title)} />;

    const barColors = {
        empty: tinycolor(CSS.colors.blue).toRgbString(),
        normal: tinycolor(CSS.colors.green).toRgbString(),
        warn: tinycolor(CSS.colors.yellow).toRgbString(),
        error: tinycolor(CSS.colors.red).toRgbString(),
    };
    return { value, precision, min, max, targetValue, header, zones, unit: unitSymbol, bgColors, barColors };
};

/**
 * Convert QueryResults for the LinearGauge Component view props
 */
export const convertForLinearGauge = (results: IQueryResults): ILinearGaugeProps => {
    const { title, data, precision, unitSymbol } = results;
    const objective = getObjective(results);
    const { value: rawValue } = _.last(data);
    const value = _.round(rawValue, precision);
    const targetValue = objective?.target;
    const min = _.round(objective?.min || 0, precision); // ou lastData.min
    const max = _.round(objective?.max || 100, precision); // ou lastData.max
    const zones = objective?.zones || [];
    return { value, min, max, targetValue, title: getLocalizedText(title), zones, unit: unitSymbol };
};

/**
 * Convert QueryResults for the TextBox Component view props
 */
export const convertForTextBox = (results: IQueryResults): ITextBoxProps => {
    const { title, data, precision, unitSymbol } = results;
    const objective = getObjective(results);
    const { value: rawValue } = _.last(data);
    const value = _.round(rawValue, precision);
    const zones = objective?.zones || [];
    const clampedValue = _.clamp(value, objective?.min, objective?.max);
    const targetZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    const color = targetZone ? colorsChart[targetZone.value] : null;
    return { value, label: getLocalizedText(title), unit: unitSymbol, color };
};

/* Get default options for all chart graphs (chart.js) */
const getOptionsChartGraphs = (precision: number, unit: string, extraOptions: IChartGraphProps["options"] = null): IChartGraphProps["options"] => {
    const defaultOptions: IChartGraphProps["options"] = {
        animation: null,
        responsive: true,
        maintainAspectRatio: false,
        // devicePixelRatio: 4, // window.devicePixelRatio,
        title: {
            display: false,
            fontSize: 18,
            fontStyle: "500",
            fontFamily: "roboto",
            fontColor: CSS.colors.white,
        },
        legend: {
            display: true,
            labels: {
                boxWidth: 8,
                fontSize: 12,
                fontStyle: "300",
                fontFamily: "roboto",
                fontColor: CSS.colors.white,
                usePointStyle: true,
            },
        },
        scales: {
            xAxes: [{
                ticks: {
                    maxTicksLimit: 10,
                    minRotation: 0,
                    maxRotation: 60,
                    fontColor: CSS.colors.white,
                },
                gridLines: {
                    color: GRID_COLOR,
                    zeroLineColor: GRID_COLOR,
                },
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    fontSize: 14,
                    fontColor: tinycolor(CSS.colors.white).setAlpha(0.5).toRgbString(),
                },
                ticks: {
                    fontColor: CSS.colors.white,
                },
                gridLines: {
                    color: GRID_COLOR,
                    zeroLineColor: GRID_COLOR,
                },
            }],
        },
        tooltips: {
            displayColors: true,
            callbacks: {
                title(tooltipItem, chartData) {
                    return chartData.datasets[tooltipItem[0].datasetIndex]?.label // has dataset label ?
                        ? chartData.datasets[tooltipItem[0].datasetIndex].label
                        : chartData.labels[tooltipItem[0].index] || "";
                },
                label(tooltipItem, chartData) {
                    const value = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as unknown as (number | string | Date | undefined | { value: number; });
                    if (_.isNumber(value)) {
                        return _.round(value, precision).toLocaleString() + " " + unit;
                    } else if (_.isDate(value)) {
                        return value.toLocaleString();
                    } else if (_.isObject(value) && _.isNumber(value.value)) {
                        return _.round(value.value, precision).toLocaleString() + " " + unit;
                    } else if (value) {
                        return `${value} ${unit}`;
                    } else {
                        return "";
                    }
                },
            },
        },
        plugins: [],
    };
    return extraOptions ? _.merge({}, defaultOptions, extraOptions) : defaultOptions;
};

const getFirstData = (results: IQueryResults[]) => {
    const first = _.first(results);
    if (!first) return { precision: 0, unit: "", objectiveTimeBased: true };
    return { precision: first.precision, unit: first.unitSymbol, objectiveTimeBased: first.objectiveTimeBased };
};

/** Get last objective or global objective */
const getObjective = (results: IQueryResults) => _.last(results?.data)?.objective || results.objective;

/* Get lowest min of all results and highest max of all result */
const getScaling = (results: IQueryResults[], defaultMin: number = null, defaultMax: number = null) => {
    let lowestMin = results.reduce((min, kpiQueryResult) => {
        const subMinReduced = kpiQueryResult.data.reduce((subMin, d) => (
            d?.objective?.min < subMin ? d.objective.min : subMin
        ), Infinity);
        if (subMinReduced < min) min = subMinReduced;
        return min;
    }, Infinity);
    lowestMin = lowestMin == Infinity ? defaultMin : lowestMin;
    let highestMax = results.reduce((max, kpiQueryResult) => {
        const subMaxReduced = kpiQueryResult.data.reduce((subMax, d) => (
            d?.objective?.max > subMax ? d.objective.max : subMax
        ), -Infinity);
        if (subMaxReduced > max) max = subMaxReduced;
        return max;
    }, -Infinity);
    highestMax = highestMax == -Infinity ? defaultMax : highestMax;
    return { min: lowestMin, max: highestMax };
};

/**
 * Convert QueryResults for the Time Chart Component view props
 */
export const convertForChartTimeGraphs = (results: IQueryResults[], type: IChartGraphProps["type"], numberOfSteps: number, enabledPlugins: string[] = [], events?: IEventICP[]): IChartGraphProps => {
    const { precision, unit, objectiveTimeBased } = getFirstData(results);

    let options: IChartGraphProps["options"];
    const { min, max } = getScaling(results);

    const multiResults = _.size(results) > 1; // we don't show the graphs the same way when there is only one kpi or multiple kpis

    // Activate force scaling (only work when "objectiveTimeBased" is set to false and "numberOfSteps" as been set)
    const forceScaling = !objectiveTimeBased && numberOfSteps && min !== null && max !== null;

    if (forceScaling) {
        options = getOptionsChartGraphs(precision, unit, {
            scales: {
                yAxes: [{
                    ticks: {
                        min: _.round(min, precision).toFixed(precision),
                        max: _.round(max, precision).toFixed(precision),
                        stepSize: (max - min) / numberOfSteps,
                        callback: (value: number) => _.round(value, precision).toFixed(precision),
                    },
                }],
            },
        });
    } else {
        options = getOptionsChartGraphs(precision, unit, {
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: min,
                        suggestedMax: max,
                    },
                }],
            },
        });
    }

    // Annotations - Breaks and Downtimes
    if (enabledPlugins.includes("annotations") && events) {
        const minX = results[0]?.data[0]?.time;
        const nextX = results[0]?.data[1]?.time;

        if (minX !== undefined && nextX !== undefined) {
            const stepDelta = nextX - minX; // number of milliseconds between each index of a dataset

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (options as any).annotation = {
                drawTime: "afterDatasetsDraw",
                annotations: [
                    ..._.map(events, (event) => {
                        const color = event.telemetryType == "SHIFT_BREAK"
                            ? tinycolor(CSS.colors.black).setAlpha(0.2).toRgbString()
                            : tinycolor(CSS.colors.orange).setAlpha(0.2).toRgbString();
                        return {
                            type: "box",
                            drawTime: "beforeDatasetsDraw",
                            id: event.id + "_" + event.telemetryType + "_" + event.startDateTime + ">" + event.endDateTime,
                            xScaleID: "x-axis-0", // ID of the X scale to bind onto
                            yScaleID: "y-axis-0", // ID of the Y scale to bind onto
                            xMin: ((new Date(event.startDateTime)).getTime() - minX) / stepDelta,
                            xMax: ((new Date(event.endDateTime)).getTime() - minX) / stepDelta,
                            yMax: _.ceil(max + 1),
                            yMin: _.floor(min - 1),
                            borderColor: tinycolor(color).setAlpha(0).toRgbString(),
                            borderWidth: 0,
                            backgroundColor: color,
                        };
                    }),
                ],
            };
        }
    }

    if (!multiResults) {
        options.legend = {
            ...options.legend,
            labels: {
                ...options.legend.labels,
                boxWidth: 0,
            },
        };
    }

    // zone color plugin (take the first one for the zones)
    if (enabledPlugins.includes(PluginZoneBackground.PLUGIN_ID) && !_.isEmpty(results[0].data)) {
        // time graph objectives are independent for each individual data point
        const zones = _.map(results[0].data, (data) => data?.objective?.zones || []);

        options.plugins = {
            ...options.plugins,
            // configuration of zones for the plugin
            [PluginZoneBackground.PLUGIN_ID]: {
                pattern: "none",
                zones,
                opacity: 0.6,
            } as IPluginZoneBackgroundOptions,
        };
    }

    const allData = _.merge({}, defaultChartData, {
        labels: results[0].data.map(({ time }) => moment(time).format("ddd h:mm a")),
        datasets: results.map(({ title: label, data }, index) => ({
            label,
            data: data.map((d) => ({ ...d, x: d.time, y: d.value })),
            borderWidth: type == "line" ? 1.5 : 0,
            borderColor: data.map(() => {
                const color = colorsChartCollectionMisc[index % colorsChartCollectionMisc.length];
                return tinycolor(color).setAlpha(0.99).toRgbString();
            }),
            backgroundColor: data.map((d) => {
                if (!multiResults && type == "bar") {
                    const { objective, value } = d;
                    const zones = objective?.zones || [];
                    const clampedValue = _.clamp(value, min, max);
                    const currentZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
                    const colorKey: keyof (typeof colorsChartCollection) = currentZone?.value || "empty";
                    return colorsChart[colorKey] ? tinycolor(colorsChart[colorKey]).setAlpha(0.9).toRgbString() : BAR_COLOR;
                } else {
                    const color = colorsChartCollectionMisc[index % colorsChartCollectionMisc.length];
                    const alpha = type == "line" ? 0.1 : 0.99;
                    return tinycolor(color).setAlpha(alpha).toRgbString();
                }
            }),
            pointRadius: 1,
            pointBackgroundColor: data.map(() => (
                tinycolor(colorsChartCollectionMisc[index % colorsChartCollectionMisc.length]).setAlpha(0.99).toRgbString()
            )),
        })),
    });
    return { options, type, data: allData };
};

/**
 * Convert IDataQueryWidgetProps grouped data bars
 */
export const convertForHistogram = (results: IQueryResults, type: IChartGraphProps["type"] = "bar"): IChartGraphProps => {
    const groups: { min: number; max: number; label: string; count: number; }[] = [];
    const min = results?.objective.min || 0;
    const max = results?.objective.max || 100;
    const resolution = results.resolution ? results.resolution : Math.ceil((max - min) / 5);
    const precision = results.precision || 0;
    const zones = results?.objective.zones || [];

    let start = min;
    while (start < max) {
        const end = start + resolution;

        const newGroups: (typeof groups)[0] = {
            min: start,
            max: end,
            label: `${start.toFixed(precision)} ${results.unitSymbol}`, // `${start.toFixed(precision)} - ${end.toFixed(precision)} ${getLocalizedText(results.unit)}`,
            count: results.data.filter(({ value }) => value >= start && value < end).length,
        };
        type == "horizontalBar"
            ? groups.unshift(newGroups)
            : groups.push(newGroups);

        start += resolution;
    }

    const options = (() => {
        if (type == "bar") {
            return getOptionsChartGraphs(results.precision, "", {
                title: { display: false },
                legend: { display: false },
                scales: {
                    xAxes: [{
                        ticks: {
                            suggestedMin: min,
                            suggestedMax: max,
                        },
                    }],
                },
            });
        } else { // horizontalBar
            return getOptionsChartGraphs(results.precision, "", {
                title: { display: false },
                legend: { display: false },
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: min,
                            suggestedMax: max,
                        },
                    }],
                },
            });
        }
    })();

    const data = _.merge({}, defaultChartData, {
        labels: groups.map(({ label }) => label),
        datasets: [{
            data: groups.map(({ count }) => count),
            backgroundColor: groups.map((g) => {
                const avg = (g.min + g.max) / 2;
                const clampedValue = _.clamp(avg, min, max);
                const currentZone = zones.find((z) => (clampedValue >= z.min && clampedValue < z.max));
                const colorKey: keyof (typeof colorsChartCollection) = currentZone?.value || "empty";
                return colorsChart[colorKey] ? tinycolor(colorsChart[colorKey]).setAlpha(0.9).toRgbString() : BAR_COLOR;
            }),
        }],
    });

    return { options, data, type };
};

/**
 * Convert QueryResults for the Chart Component view props
 */
export const convertForChartGraphs = (results: IQueryResults[], type: IChartGraphProps["type"]): IChartGraphProps => {
    const { precision, unit } = getFirstData(results);

    const { min: suggestedMin, max: suggestedMax } = getScaling(results, 0, 100);

    results = _.map(results, (kpiQueryResult) => ({ ...kpiQueryResult, data: [_.last(kpiQueryResult.data)] }));
    const colorsCounters: { [P in keyof (typeof colorsChartCollection)]: number } = { empty: -1, normal: -1, warn: -1, error: -1 };

    const allData = _.merge({}, defaultChartData, {
        labels: results.map(({ title: label }) => label),
        datasets: [{
            data: results.map(({ data }) => !_.isEmpty(data) && _.last(data).value),
            borderWidth: 0,
            borderColor: tinycolor(CSS.colors.white).setAlpha(type == "line" ? 0.5 : 0.9).toRgbString(),
            backgroundColor: results.map((k) => {
                const { objective, value } = _.last(k.data);
                const zones = objective?.zones || [];
                const clampedValue = _.clamp(value, suggestedMin, suggestedMax);
                const currentZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
                const colorKey: keyof (typeof colorsChartCollection) = currentZone?.value || "empty";
                colorsCounters[colorKey] = colorsCounters[colorKey] + 1;
                if (colorsCounters[colorKey] >= colorsChartCollection[colorKey].length) {
                    colorsCounters[colorKey] = 0; // loop
                }
                const colorsList = colorsChartCollection[colorKey];
                const i = colorsCounters[colorKey];
                return tinycolor(colorsList[i]).setAlpha(0.9).toRgbString();
            }),
        }],
    });

    const options = (() => {
        if (type == "pie" || type == "doughnut") {
            return getOptionsChartGraphs(precision, unit, {
                legend: { display: true },
                scales: null,
            });
        } else if (type == "bar") {
            return getOptionsChartGraphs(precision, unit, {
                legend: { display: false },
                scales: {
                    yAxes: [{
                        ticks: {
                            autoSkip: false,
                            suggestedMin,
                            suggestedMax,
                        },
                        scaleLabel: {
                            display: true,
                            fontColor: "white",
                            labelString: unit,
                        },
                    }],
                },
            });
        } else {
            return getOptionsChartGraphs(precision, unit, {
                legend: { display: false },
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: false,
                            suggestedMin,
                            suggestedMax,
                        },
                        scaleLabel: {
                            display: true,
                            fontColor: "white",
                            labelString: unit,
                        },
                    }],
                },
            });
        }
    })();
    return { options, type, data: allData } as IChartGraphProps;
};

/**
 * Convert QueryResults for the TextBoxWithGraph Component view props
 */
export const convertForTextBoxWithGraph = (res: IQueryResults): { textBoxProps: ITextBoxProps; chartGraphProps: IChartGraphProps; } => {
    const { title: label, data, precision, unitSymbol } = res;
    const textBoxProps = convertForTextBox(res);

    const options = getOptionsChartGraphs(precision, unitSymbol, {
        title: { display: false },
        legend: { display: false },
        layout: {
            padding: { // we need space to see the last dot when there is no x and y axes.
                bottom: 6,
                top: 6,
                right: 12,
            },
        },
        scales: {
            xAxes: [{
                display: false,
                ticks: {
                    display: false,
                },
            }],
            yAxes: [{
                display: false,
                ticks: {
                    display: false,
                },
            }],
        },
    });

    const currentZone = (() => {
        const lastData = _.last(data);
        const { objective, value } = lastData;
        const clampedValue = _.clamp(value, objective?.min, objective?.max);
        const zones = objective?.zones || [];
        return zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    })();

    const allData = _.merge({}, defaultChartData, {
        labels: data.map(({ time }) => moment(time).format("h:mm a")),
        datasets: [
            {
                label,
                data: data.map(({ value }) => value),
                borderColor: tinycolor(currentZone ? colorsChart[currentZone.value] : BAR_COLOR).lighten(10).setAlpha(0.5).toRgbString(),
                borderWidth: 3,
                backgroundColor: "rgba(255,255,255,0)",
                pointRadius: [...Array(data.length - 1).fill(0), 3],
                pointBorderColor: currentZone ? colorsChart[currentZone.value] : BAR_COLOR,
                pointBackgroundColor: currentZone ? colorsChart[currentZone.value] : BAR_COLOR,
                lineTension: 0,
            },
            { // Target line
                data: data.map(({ objective }) => objective?.target),
                borderColor: tinycolor(CSS.colors.white).setAlpha(0.2).toRgbString(),
                fill: false,
                radius: 0,
                steppedLine: "after",
                borderDash: [3, 2],
                borderWidth: 1,
                type: "line",
            },
        ],
    });

    return { textBoxProps, chartGraphProps: { options, type: "line", data: allData } };
};