import _ from "lodash";
import { IKpiDataIO, IKpiDataIOs } from "../model";

export type IKpiDataIOError = { [key in keyof IKpiDataIO]?: string[]; };
export type IKpiDataIOErrorList = { [machineNodeId: string]: IKpiDataIOError; };

export type IKpiDataIOEditionData = { [machineNodeId: string]: IKpiDataIOs; };

export const validateIndex = (kpiDataIOS: IKpiDataIOs) => {
    const duplicates: { [key: number]: number; } = _.reduce(kpiDataIOS, (acc, { index }) => {
        acc[index] = acc[index] != null ? acc[index] + 1 : 1;
        return acc;
    }, {});
    return _.chain(duplicates).pickBy((duplicate) => duplicate > 1).keys().map(Number).value();
};

export const createNewKpiDataIO = (partialData: Partial<IKpiDataIO> = {}): IKpiDataIO => ({
    id: "new_" + Date.now(),
    patternKey: "",
    definitionId: "",
    contextId: "",
    nodeId: "",
    factor: 1,
    index: 1,
    machineNodeId: "",
    ...partialData,
});