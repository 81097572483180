import { createModule, createUserPrefsSlice } from "@comact/crc";
import { IReportContext, buildDefaultReportContext } from "js/reports/browser";
import { IPageShiftViewType } from "js/shifts";
import { IRangesQuery } from "../components/RangesSelector";
import { IZoomType } from "../events/components/EventsViewer/ZoomSelect";
import { IValidTypeFilter } from "../events/components/PageSystemEvents";

declare global {
    interface IStoreState {
        userPrefs: IUserPrefsData;
    }
}

// UserPrefs interface
export interface IUserPrefsData {
    shiftsViewType: IPageShiftViewType;
    selectedReportConfigurationId: string;
    selectedReportContextType: IReportContext["type"];
    reportContextData: { [type in IReportContext["type"]]: IReportContext };
    dashboardSelectedId: string; // FIXME: à enlever éventuellement quand ce sera juste IEP
    dashboardSelectedIdByNode: Record<string, string>;
    eventsViewer: {
        selectedRange: IRangesQuery;
        selectedType: IValidTypeFilter;
        selectedZoom: IZoomType;
    };
}

// UserPrefs default values
const defaultState: IUserPrefsData = {
    shiftsViewType: "DaysList7",
    selectedReportConfigurationId: null,
    selectedReportContextType: "PERIOD",
    reportContextData: {
        PERIOD: buildDefaultReportContext("PERIOD"),
        SHIFT: buildDefaultReportContext("SHIFT"),
        BATCH: buildDefaultReportContext("BATCH"),
        SIMULATION: buildDefaultReportContext("SIMULATION"),
        SAMPLING: buildDefaultReportContext("SAMPLING"),
    },
    dashboardSelectedId: null,
    dashboardSelectedIdByNode: {},
    eventsViewer: {
        selectedRange: {
            customRange: { from: null, to: null },
            shiftId: null,
            rangeType: "last_24_hours",
        },
        selectedType: null,
        selectedZoom: "100%",
    },
};

const slice = createUserPrefsSlice(defaultState);

export const UserPrefsModule = createModule({
    actionsCreators: slice.actions,
    reducers: { [slice.name]: slice.reducer },
});