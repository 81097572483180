import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_REPORT_JOBS } from "js/constants";
import _ from "lodash";
import * as mocks from "./mocks";
import { IReportJob } from "./model";
import { actionsCreators } from "./slices";

export const getReportJobsThrottled = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest<{}, IReportJob[]>({
        serverLessResolve: () => Promise.resolve(getState().reportJobs ? _.values(getState().reportJobs) : mocks.buildMockReportJobs()),
        url: `${API_PREFIX_REPORT_JOBS}?limit=9999`,
        onSuccess: (reportJobs: IReportJob[]) => {
            dispatch(actionsCreators.set(reportJobs));
        },
    })
), 5 * 1000);

export const createReportJob = (reportJob: IReportJob) => (dispatch: IStoreDispatch): Promise<void> => (
    ajaxRequest<IReportJob, IReportJob>({
        serverLessResolve: () => Promise.resolve({ ...reportJob, id: Date.now().toString() }),
        url: `${API_PREFIX_REPORT_JOBS}`,
        method: "POST",
        data: reportJob,
        onSuccess: ((newJob: IReportJob) => {
        // update the job in the store immediately
            dispatch(actionsCreators.patch([newJob]));
        }),
    }).promise
);

export const updateReportJob = (reportJob: IReportJob) => (dispatch: IStoreDispatch): Promise<void> => (
    ajaxRequest<{}, IReportJob>({
        serverLessResolve: () => Promise.resolve({ ...reportJob }),
        url: `${API_PREFIX_REPORT_JOBS}/${reportJob.id}`,
        method: "PUT",
        data: reportJob,
        onSuccess: ((newJob: IReportJob) => {
        // update the job in the store immediately
            dispatch(actionsCreators.patch([newJob]));
        }),
    }).promise
);

export const deleteReportJob = (reportJobId: string) => (dispatch: IStoreDispatch): Promise<void> => (
    ajaxRequest({
        serverLessResolve: () => Promise.resolve(),
        url: `${API_PREFIX_REPORT_JOBS}/${reportJobId}`,
        method: "DELETE",
        onSuccess: (() => {
        // update the job in the store immediately
            dispatch(actionsCreators.delete([reportJobId]));
        }),
    }).promise
);