import { constants, ILocalizedString, store, t, validateLocalizedText } from "@comact/crc";
import produce from "immer";
import { IMachineCodecNode, IMachinesModel, INode, INodes, IScannerCodecNode } from "js/node/model";
import * as _ from "lodash";
import { Layout } from "react-grid-layout";
import { IContextDefinitions } from "../kpis/contextDefinitions/model";
import { IKpiPatterns } from "../kpis/kpiPatterns/model";
import { IKpiQueryRecipesNode } from "../kpis/kpiQueries/model";
import { NodesModule } from "../node";
import { needToMigrate } from "./migrations/migrations";
import { IWidgetDashboard, IWidgetDashboardWithKpiQueryRecipes, IWidgetDashboardWithQueryRecipe } from "./widgets/index";
const { ONE_MINUTE, ONE_HOUR, ONE_DAY } = constants;

/**
 * Definition of Dashboard
 * isSystem identify a dashboard that is provided by default. Not customizable by user. Imported from static files.
 */
export interface IDashboard {
    readonly id: string;
    readonly userId: string;
    readonly lastModificationUserId: string;
    readonly version: "v4";
    title: ILocalizedString;
    layouts: { [id in IBreakpointNames]?: Layout[] };
    layoutActives: { [id in IBreakpointNames]: boolean };
    readonly modificationDate: number;
    defaultRangeType: IDashboardQuery["rangeType"];
    defaultAggregation: IDashboardQuery["aggregation"];
    widgetsByBreakpoints: { [id in IBreakpointNames]: IWidgetDashboard[] };
    readonly isSystem?: boolean; // true for default dashboards
    readonly duplicatedId?: string; // id of the duplicated parent dashboard
    readonly permissionsNeeded?: string[];
    millNodeId: string;
}

interface IDashboardStats {
    orderedNodes: { count: number; id: string; templateName: INode["templateName"]; scannerIndex: IScannerCodecNode["scannerIndex"]; machine: IMachineCodecNode["machine"]; }[];
    orderedContexts: { count: number; id: string; key: string; }[];
    orderedKpis: { id: string; count: number; }[];
    machineModels: IMachinesModel[];
    widgetsInError: string[];
}
export interface IExtra extends IDashboardStats {
    missingPermissions: string[];
    missingNodes: IDashboardStats["orderedNodes"];
    missingContexts: IDashboardStats["orderedContexts"];
    missingKpis: IDashboardStats["orderedKpis"];
    isValid: boolean;
    userCanEdit: boolean;
    userCanDuplicate: boolean;
    needsMigration: boolean;
}

export interface IDashboardExtra extends IDashboard, IExtra { }

export interface IDashboardEditExtra extends IDashboardEdit, IExtra { }

export type IDashboards = { [id: string]: IDashboard; };

export interface IDashboardEdit extends IDashboard {
    widgetEdit: IWidgetDashboard;
    forceBreakpoint: IBreakpointNames;
    editMode: boolean;
}

export const convertFromDashboardEdit = (anyDashboard: IDashboard | IDashboardEdit): IDashboard => {
    const { editMode, widgetEdit, forceBreakpoint, ...dashboard } = anyDashboard as IDashboardEdit;
    // auto-disable layout breakpoints containing 0 widgets
    dashboard.layoutActives = _.mapValues(dashboard.layoutActives, (active, breakpoint) => active && !_.isEmpty(dashboard.widgetsByBreakpoints[breakpoint]));
    return dashboard;
};

export const validate = (dashboard: IDashboardEdit) => {
    const { title } = dashboard;
    let errors: { -readonly [key in keyof IDashboardEdit]?: string[] | { [lang: string]: string; } } = {};

    errors.title = validateLocalizedText(title);
    errors.defaultAggregation = validateAggregation(dashboard.defaultRangeType, dashboard.defaultAggregation);

    // must have at least one widget
    if (_.every(dashboard.widgetsByBreakpoints, _.isEmpty)) errors.widgetsByBreakpoints = [t("widgets.editors.requiresSomeWidgets")];

    errors = _.omitBy(errors, _.isEmpty); // Remove empty key
    return errors;
};

/* eslint-disable camelcase */
export const validateAggregation = (
    rangeType: IDashboardQuery["rangeType"],
    aggregation: IDashboardQuery["aggregation"],
    customRange?: IDashboardQuery["customRange"]
): string[] | null => {
    const rangeDurations: { [key in IDashboardQuery["rangeType"]]?: number } = {
        last_5_minutes: 5,
        last_15_minutes: 15,
        last_1_hours: 60,
        last_4_hours: 60 * 4,
        last_24_hours: 60 * 24,
        last_4_days: 60 * 24 * 4,
        last_7_days: 60 * 24 * 7,
        last_30_days: 60 * 24 * 30,
        last_90_days: 60 * 24 * 90,
        last_365_days: 60 * 24 * 365,
        currentShift: 60 * 24, // estimated at one day
        lastShift: 60 * 24, // estimated at one day
        customShift: 60 * 24, // estimated at one day
        customRange: null, // precise calculation below
    };

    const aggregationDurations: { [key in IDashboardQuery["aggregation"]]?: number } = {
        time_1_minutes: 1,
        time_5_minutes: 5,
        time_10_minutes: 10,
        time_15_minutes: 15,
        time_30_minutes: 30,
        time_1_hours: 60,
        time_4_hours: 60 * 4,
        time_1_days: 60 * 24,
        time_4_days: 60 * 24 * 4,
        time_7_days: 60 * 24 * 7,
        time_30_days: 60 * 24 * 30,
    };
    /* eslint-enable camelcase */

    const rangeDuration = rangeType == "customRange" && customRange?.from && customRange?.to
        ? (customRange.to - customRange.from) / constants.ONE_MINUTE // custom range (or shifts)
        : rangeDurations[rangeType]; // predefined range
    const aggregationDuration = aggregationDurations[aggregation];
    const approximatedDataPoints = rangeDuration / aggregationDuration;

    if (approximatedDataPoints > 400) return [t("widgets.widgetAggregationTooSmall")];
    else if (approximatedDataPoints < 1) return [t("widgets.widgetAggregationLargerThanRange")];

    return null;
};

type ICreateNewDashboard = Partial<IDashboard> & Pick<IDashboard, "millNodeId">;
export const createNewDashboard = (partialData: ICreateNewDashboard): IDashboard => ({
    id: "new_" + Date.now(),
    userId: null,
    lastModificationUserId: null,
    title: { values: {} },
    modificationDate: Date.now(),
    layouts: {},
    layoutActives: { xxs: true, xs: true, sm: true, md: true, lg: true, tv: true },
    widgetsByBreakpoints: { xxs: [], xs: [], sm: [], md: [], lg: [], tv: [] },
    defaultRangeType: "currentShift",
    defaultAggregation: "time_15_minutes",
    version: "v4",
    ...partialData,
});

export interface IDashboardQuery {
    rangeType: "customRange" | "customShift" | "customBatch" | "currentShift" | "lastShift" | "currentBatch" | "lastBatch" |
    "last_5_minutes" | "last_15_minutes" | "last_1_hours" | "last_4_hours" |
    "last_24_hours" | "last_4_days" | "last_7_days" |
    "last_30_days" | "last_90_days" | "last_365_days";
    customRange: {
        from: number;
        to: number;
    };
    shiftId: string;
    batchId?: string;
    aggregation: "time_1_minutes" | "time_5_minutes" | "time_10_minutes" | "time_15_minutes" | "time_30_minutes" | "persistent" |
    "time_1_hours" | "time_4_hours" | "time_1_days" | "time_4_days" | "time_7_days" | "time_30_days";
    estimatedDuration: number;
}

export const getRangeTypeInMilli = (rangeType: IDashboardQuery["rangeType"]) => {
    switch (rangeType) {
        case "customRange": return null; // unknown
        case "customShift": return ONE_HOUR * 8; // approx
        case "currentShift": return ONE_HOUR * 8; // approx
        case "lastShift": return ONE_HOUR * 8; // approx
        case "customBatch": return ONE_HOUR * 8; // approx
        case "currentBatch": return ONE_HOUR * 8; // approx
        case "lastBatch": return ONE_HOUR * 8; // approx

        case "last_5_minutes": return ONE_MINUTE * 5;
        case "last_15_minutes": return ONE_MINUTE * 15;
        case "last_1_hours": return ONE_HOUR * 1;
        case "last_4_hours": return ONE_HOUR * 4;
        case "last_24_hours": return ONE_HOUR * 24;
        case "last_4_days": return ONE_DAY * 4;
        case "last_7_days": return ONE_DAY * 7;
        case "last_30_days": return ONE_DAY * 30;
        case "last_90_days": return ONE_DAY * 90;
        case "last_365_days": return ONE_DAY * 365;
    }
};

export const getAggregationInMilli = (rangeType: IDashboardQuery["aggregation"]) => {
    switch (rangeType) {
        case "persistent": return Infinity; // FIXME: not used anymore
        case "time_1_minutes": return ONE_MINUTE * 1;
        case "time_5_minutes": return ONE_MINUTE * 5;
        case "time_10_minutes": return ONE_MINUTE * 10;
        case "time_15_minutes": return ONE_MINUTE * 15;
        case "time_30_minutes": return ONE_MINUTE * 30;
        case "time_1_hours": return ONE_HOUR * 1;
        case "time_4_hours": return ONE_HOUR * 4;
        case "time_1_days": return ONE_DAY * 1;
        case "time_4_days": return ONE_DAY * 4;
        case "time_7_days": return ONE_DAY * 7;
        case "time_30_days": return ONE_DAY * 30;
    }
};

export interface IDashboardQueryUrl {
    range?: string;
    from?: string;
    to?: string;
    shift?: string;
    batch?: string;
    aggregation?: string;
}

export const convertDashboardQueryToUrl = (query: Partial<IDashboardQuery>): Record<string, string> => _.omitBy({
    range: query.rangeType,
    aggregation: query.aggregation,
    from: query.customRange?.from?.toString(),
    to: query.customRange?.to?.toString(),
    shift: query.shiftId,
    batch: query.batchId,
}, _.isUndefined);

export const widgetHasKpiQueryRecipes = (widget: IWidgetDashboard): widget is IWidgetDashboardWithKpiQueryRecipes => {
    switch (widget.widgetType) {
        case "KpisTable":
        case "TimeGraphs":
        case "TimeTable":
        case "MultiKpis":
        case "Graphs":
            return true;
    }
    return false;
};

export const widgetHasQueryRecipe = (widget: IWidgetDashboard): widget is IWidgetDashboardWithQueryRecipe => {
    switch (widget.widgetType) {
        case "TimberViewer2D":
        case "TimberViewer3D":
        case "DataQuery":
        case "LineSpeed":
        case "Machine":
        case "SolutionViewer":
            return true;
    }
    return false;
};

export const getDashboardStats = (dashboard: IDashboard, nodes: INodes, contextDefinitions: IContextDefinitions): IDashboardStats => {
    const nodesFoundByNumber: { [id: string]: IDashboardExtra["orderedNodes"][0]; } = {};
    const contextsFoundByNumber: { [id: string]: IDashboardExtra["orderedContexts"][0]; } = {};
    const kpisFoundByNumber: { [id: string]: IDashboardExtra["orderedKpis"][0]; } = {};
    const widgetErrors: { [widgetId: string]: boolean; } = {};

    const setNodeStats = (nodeRecipe: IKpiQueryRecipesNode, widgetId: string) => {
        if (!nodeRecipe) return;

        const id = ((() => {
            if (!nodes[nodeRecipe.id]) widgetErrors[widgetId] = true; // missing node
            if (nodeRecipe.id) return nodeRecipe.id;

            // If the real nodeRecipe id is missing, we need fake ids to be able to create the stats correctly
            if (nodeRecipe.templateName == "scannerCodec" || nodeRecipe.templateName == "machineCodec") return `?${nodeRecipe.machine}`;
            return `?${nodeRecipe.templateName}`;
        })());
        if (nodeRecipe.templateName == "scannerCodec") {
            if (nodesFoundByNumber[id]) nodesFoundByNumber[id].count++;
            else nodesFoundByNumber[id] = { id, count: 1, templateName: nodeRecipe.templateName, scannerIndex: nodeRecipe.scannerIndex, machine: nodeRecipe.machine };
        } else if (nodeRecipe.templateName == "machineCodec") {
            if (nodesFoundByNumber[id]) nodesFoundByNumber[id].count++;
            else nodesFoundByNumber[id] = { id, count: 1, scannerIndex: null, templateName: nodeRecipe.templateName, machine: nodeRecipe.machine };
        } else {
            // Future cases goes here
        }
    };

    _.forEach(dashboard.widgetsByBreakpoints, (widgets, breakpointId) => {
        const activeBreakpoint = dashboard.layoutActives[breakpointId];
        if (!activeBreakpoint) return; // don't check anything for inactive breakpoints
        // eslint-disable-next-line complexity
        _.forEach(widgets, (widget) => {
            if (widgetHasKpiQueryRecipes(widget)) {
                const props = widget.props;
                // Node ids
                _.forEach(props.kpiQueryRecipes.nodes, (n) => setNodeStats(n, widget.id));

                // Contexts ids
                _.forEach(props.kpiQueryRecipes.contexts, (contextRecipe) => {
                    if (!_.some(contextDefinitions, ({ uniqueReferenceName }) => uniqueReferenceName == contextRecipe.key)) widgetErrors[widget.id] = true; // missing context
                    if (contextsFoundByNumber[contextRecipe.key]) contextsFoundByNumber[contextRecipe.key].count++;
                    else contextsFoundByNumber[contextRecipe.key] = { ...contextRecipe, count: 1 };
                });

                // Kpis
                _.forEach(props.kpiQueryRecipes.kpis, (kpiRecipe) => {
                    if (kpisFoundByNumber[kpiRecipe.patternKey]) kpisFoundByNumber[kpiRecipe.patternKey].count++;
                    else kpisFoundByNumber[kpiRecipe.patternKey] = { id: kpiRecipe.patternKey, count: 1 };
                });
            } else if (widgetHasQueryRecipe(widget)) {
                setNodeStats(widget.props.queryRecipe, widget.id);
            }
        });
    });

    return {
        orderedNodes: _.orderBy(nodesFoundByNumber, ({ count }) => count, ["desc"]),
        orderedContexts: _.orderBy(contextsFoundByNumber, ({ count }) => count, ["desc"]),
        orderedKpis: _.orderBy(kpisFoundByNumber, ({ count }) => count, ["desc"]),
        machineModels: _(nodesFoundByNumber).filter((n) => !!n.machine).map((n) => n.machine).uniq().value(),
        widgetsInError: _.reduce(widgetErrors, (arr, widgetError, id) => {
            if (widgetError) arr.push(id);
            return arr;
        }, [] as string[]),
    };
};

export const createDashboardExtra = <T extends IDashboard>(
    dashboard: T, nodes: INodes, contextDefinitions: IContextDefinitions, kpiPatterns: IKpiPatterns, userCanEdit: boolean, userCanDuplicate: boolean
): T & IExtra => {
    const nodeStats = getDashboardStats(dashboard, nodes, contextDefinitions);

    // Check if all the machine has the given permissions to show a dashboard. Also check if a scanner parent machine has the permissions.
    const machineGivenPermissions = _.reduce(_.map(nodeStats.orderedNodes, ({ id }) => nodes[id]), (accumulator, node) => {
        if (node?.templateName == "machineCodec" || node?.templateName == "scannerCodec") {
            const machineNode = (node?.templateName == "scannerCodec" ? nodes[node.parentId] : node) as IMachineCodecNode;
            _.forEach(machineNode.machineInfo?.interfaceInfo?.metadataOverwrites, (givenPermission) => {
                if (!accumulator.includes(givenPermission)) accumulator.push(givenPermission);
            });
        }
        return accumulator;
    }, [] as string[]);

    const missingPermissions = _.difference(dashboard.permissionsNeeded, machineGivenPermissions);
    const missingNodes = _.filter(nodeStats.orderedNodes, ({ id }) => !nodes[id]);
    const missingContexts = _.filter(nodeStats.orderedContexts, ({ key }) => !_.some(contextDefinitions, ({ uniqueReferenceName }) => uniqueReferenceName == key));
    const missingKpis = _.filter(nodeStats.orderedKpis, ({ id }) => !_.some(kpiPatterns, ({ uniqueName }) => uniqueName == id));

    const isValid = _.isEmpty(missingPermissions) && _.isEmpty(missingNodes) && _.isEmpty(missingContexts) && _.isEmpty(missingKpis) &&
                    nodeStats.orderedNodes.every((m) => NodesModule.selectors.getNodeTreeIds(store.getState()).includes(m.id));

    const needsMigration = needToMigrate(dashboard);

    return { ...dashboard, ...nodeStats, missingPermissions, missingNodes, missingContexts, missingKpis, isValid, needsMigration, userCanDuplicate, userCanEdit };
};

/**
 * Create a new widget for a Dashboard
 */
export const createNewWidget = (): IWidgetDashboard => ({
    id: Date.now().toString(),
    widgetType: null,
    props: {},
    design: "default",
    size: 1,
    padding: "default",
});

/** Widget */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IWidget<P = any | null, W = string> {
    id?: string;
    widgetType?: W;
    props?: P;
    design?: "default" | "noBackground" | "subtle" | "dark" | "darker";
    size?: 1 | 1.25 | 1.5 | 1.75 | 2 | 2.5 | 3 | 3.5;
    padding?: "default" | "double" | "half" | "none";
}

interface IBreakpoint {
    id: IBreakpointNames;
    minWidth: number;
    editWidth: number;
    maxWidth: number;
    cols: number;
    fullScreenContent: boolean;
}

export type IBreakpointNames = "xxs" | "xs" | "sm" | "md" | "lg" | "tv";

export type IBreakpoints = { [id in IBreakpointNames]: IBreakpoint };

export const defaultBreakpoints: IBreakpoints = {
    xxs: { id: "xxs", minWidth: 280, editWidth: 300, maxWidth: 360, cols: 8, fullScreenContent: false }, // 35-45px per columns
    xs: { id: "xs", minWidth: 560, editWidth: 650, maxWidth: 720, cols: 16, fullScreenContent: false }, // 35-45px per columns
    sm: { id: "sm", minWidth: 840, editWidth: 920, maxWidth: 1080, cols: 24, fullScreenContent: false }, // 35-45px per columns
    md: { id: "md", minWidth: 1120, editWidth: 1200, maxWidth: 1440, cols: 32, fullScreenContent: false }, // 35-45px per columns
    lg: { id: "lg", minWidth: 1680, editWidth: 1800, maxWidth: 2160, cols: 48, fullScreenContent: false }, // 35-45px per columns
    tv: { id: "tv", minWidth: 1680, editWidth: 1840, maxWidth: 2160, cols: 48, fullScreenContent: true }, // 35-45px per columns // same as lg, but for kiosk mode
};

/** The definition of a widget */
export interface IWidgetDefinition<T extends {} = {}, E extends {} = {}> {
    componentClass: React.ComponentClass<IWidget<T>> | React.FunctionComponent<IWidget<T>>;
    editOptionsClass: React.ComponentClass<IWidgetEditOptions<T, E>> | React.FunctionComponent<IWidgetEditOptions<T, E>> | null;
    editOptionsModalSize?: "big";
    editOptionsValidate?: (props: T, state: IStoreState) => E;
    defaultProps: Partial<T>;
    defaultLayout: Omit<Layout, "i">;
}

/** Edition option of a widget */
export interface IWidgetEditOptions<WP, WE = null> {
    widgetProps: WP;
    errors: WE;
    onChangeWidgetProps: (widgetProps: Partial<WP>) => void;
}

/**
 * Sanitize system dashboards.
 * (set default values, remove hard code ids)
 */
export const sanitizeSystemGeneratedDashboards = (systemGeneratedDashboards: IDashboards) => (
    _.mapValues(systemGeneratedDashboards, (dashboard) => ({
        ...dashboard,
        isSystem: true,
        userId: null,
        widgetsByBreakpoints: _.mapValues(dashboard.widgetsByBreakpoints, (widgetsByBreakpoint, breakpoint) => {
            if (!dashboard.layoutActives[breakpoint]) return []; // remove inactive layout
            return _.map(widgetsByBreakpoint, (widget) => (
                produce(widget, (draft) => {
                    if (widgetHasKpiQueryRecipes(draft)) {
                        _.forEach(draft.props.kpiQueryRecipes.nodes, (node) => node.id = null);
                        _.forEach(draft.props.kpiQueryRecipes.contexts, (context) => context.id = null);
                    } else if (widgetHasQueryRecipe(draft)) {
                        draft.props.queryRecipe.id = null;
                    }
                })
            ));
        }),
    }))
);