import { UserModule, ajaxRequest, getActionAjax } from "@comact/crc";
import { API_PREFIX_ACCESS_TOKENS } from "../constants";
import { IAccessToken, IAccessTokens } from "./model";
import { actionsCreators } from "./slices";

export const getAccessTokens = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().accessTokens || import("./mocks").then((m) => m.getAccessTokens()),
        url: API_PREFIX_ACCESS_TOKENS,
        onSuccess: (accessTokens: IAccessTokens) => {
            dispatch(actionsCreators.set(accessTokens));
        },
    })
));

export const generateAccessToken = getActionAjax((data: { label: string; }) => (dispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.generateAccessToken(data.label, UserModule.selectors.getCurrentUserId(getState()))),
        url: API_PREFIX_ACCESS_TOKENS,
        method: "POST",
        data,
        onSuccess: (accessToken: IAccessToken) => {
            dispatch(actionsCreators.patch([accessToken]));
            return accessToken;
        },
    })
));

export const deleteAccessToken = getActionAjax((id: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => null,
        url: `${API_PREFIX_ACCESS_TOKENS}/${id}`,
        method: "DELETE",
        onSuccess: () => {
            dispatch(actionsCreators.delete([id]));
        },
    })
));
