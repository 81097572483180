import _ from "lodash";
import { IMillNode, NodesModule } from "../node";
import { IShift, IShiftDefinition, getEndTime, getShiftsByTimeRange as getShiftsByTimeRangeModel } from "./model";

export const getShiftsByTimeRange = (state: IStoreState, startDate: Date, endDate: Date) => (
    getShiftsByTimeRangeModel(_.values(state.shifts), startDate, endDate)
);

export const getShiftById = (state: IStoreState, id: string): IShift | null => (
    state.shifts ? state.shifts[id] : null
);

export const getShiftDefinitionById = (state: IStoreState, id: string): IShiftDefinition | null => (
    state.shiftDefinitions ? state.shiftDefinitions[id] : null
);

export const getCurrentShift = (state: IStoreState, currentDate: Date) => {
    if (process.env.EXEC_MODE == "icp") {
        const nodeMill = NodesModule.selectors.getNodeById<IMillNode>(state, state.currentMillNodeId);
        return nodeMill?.shift;
    } else {
        return _.find(state.shifts,
            (shift) => (shift.startDate + shift.startTime) <= currentDate.valueOf() && (getEndTime(shift)) >= currentDate.valueOf());
    }
};