import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IShift, IShiftDefinition, IShiftDefinitions, IShifts, IShiftsIds } from "./model";

declare global {
    interface IStoreState {
        shiftsStartDate: number;
        shifts: IShifts;
        shiftDefinitions: IShiftDefinitions;
        shiftIds: IShiftsIds;
    }
}

const convertShift = (shift: IShift): IShift => ({ ...shift, type: !shift.finished && _.isFinite(Number(shift.id)) ? "alternate" : "definition" });

const useStatsShifts = "shifts";
const isEqualShift = (a: IShift, b: IShift) => a.modificationStamp == b.modificationStamp;

const shifts = createSlice({
    name: "shifts",
    initialState: {} as IShifts,
    reducers: {
        set: (state, { payload }: PayloadAction<{ startTime?: Date; endTime?: Date; shift?: IShift; shifts?: IShift[]; }>) => {
            const startTime = payload.startTime.getTime();
            const endTime = payload.endTime.getTime();

            const newMonthShifts: IShifts = _.keyBy(payload.shifts.map(convertShift), (s) => s.id); // new shifts to add if changed

            const newShiftsToAdd = _.pickBy(newMonthShifts, (shift) => !state[shift.id]);

            const newState = _.reduce(state, (newStateShifts: IShifts, shift: IShift) => {
                if ((shift.startDate + shift.startTime + shift.duration) >= startTime && (shift.startDate + shift.startTime) <= endTime) {
                    if (newMonthShifts[shift.id]) { // found the old shift in the new shifts of the updated period (month) (shift not deleted)
                        if (shift.modificationStamp == newMonthShifts[shift.id].modificationStamp) {
                            newStateShifts[shift.id] = shift;
                        } else {
                            newStateShifts[shift.id] = newMonthShifts[shift.id];
                        }
                    }
                } else {
                    newStateShifts[shift.id] = shift; // OTHERS shifts, not in updated period
                }
                return newStateShifts;
            }, newShiftsToAdd);
            return Utils.slices.set({ state, nextState: newState, isEqual: isEqualShift, useStats: useStatsShifts });
        },
        patch: (state, { payload }: PayloadAction<IShift[]>) => {
            const nextState = _.chain(payload).map((shift) => convertShift(shift)).keyBy(({ id }) => id).value();
            return Utils.slices.patch({ state, nextState, isEqual: isEqualShift, useStats: useStatsShifts });
        },
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: useStatsShifts })
        ),
    },
});

const useStatsShiftDefinitions = "shiftDefinitions";
const isEqualShiftDefinitions = (a: IShiftDefinition, b: IShiftDefinition) => a.modificationStamp == b.modificationStamp;

const shiftDefinitions = createSlice({
    name: "shiftDefinitions",
    initialState: {} as IShiftDefinitions,
    reducers: {
        setDefinitions: (state, { payload }: PayloadAction<IShiftDefinitions>) => (
            Utils.slices.set({ state, nextState: payload, isEqual: isEqualShiftDefinitions, useStats: useStatsShiftDefinitions })
        ),
        patchDefinitions: (state, { payload }: PayloadAction<IShiftDefinition[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual: isEqualShiftDefinitions, useStats: useStatsShiftDefinitions })
        ),
        deleteDefinitions: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: useStatsShiftDefinitions })
        ),
    },
});

const shiftIds = createSlice({
    name: "shiftIds",
    initialState: { last: null, current: null } as IShiftsIds,
    reducers: {
        setCurrent: (state, { payload }: PayloadAction<string>) => { state.current = payload; },
        setLast: (state, { payload }: PayloadAction<string>) => { state.last = payload; },
    },
});

const shiftsStartDate = createSlice({
    name: "shiftsStartDate",
    initialState: new Date().getTime(),
    reducers: {
        setStartDate: (_state, { payload }: PayloadAction<number>) => payload,
    },
});

export const actionsCreators = {
    ...shifts.actions,
    ...shiftDefinitions.actions,
    ...shiftIds.actions,
    ...shiftsStartDate.actions,
};

export default {
    [shifts.name]: shifts.reducer,
    [shiftDefinitions.name]: shiftDefinitions.reducer,
    [shiftIds.name]: shiftIds.reducer,
    [shiftsStartDate.name]: shiftsStartDate.reducer,
};