import { getClassNames, t, useMapState } from "@comact/crc";
import { Badges, CSS, LoadingSpinner, styled } from "@comact/crc/modules/kit";
import React from "react";
import { IConnexionStatusState, INodeStateStyle, getNodeStateInTransition, getNodeStateStyle } from "../";
import * as selectors from "../selectors";
import { getCurrentNodeBranchIds, makeGetWorstBranchConnexionStatus, makeGetWorstBranchStatus } from "../selectors";

const StatusBadge = styled.div`
    display: grid;
    white-space: nowrap;
    align-items: start;
    grid-template-columns: min-content min-content;
    font-size: .9em;
    text-shadow: .09em .04em .03em ${CSS.colors.shadow};
`;

const MiniStatusBaseIcon = styled.i`
    --statusColor: ${CSS.colors.greyLight};
    &.error {
        --statusColor: ${CSS.colors.red};
        animation: flashAnimStatus 0.7s infinite;
    }
    &.success {
        --statusColor: ${CSS.colors.green};
    }
    &.warn {
        --statusColor: ${CSS.colors.yellow};
    }
`;

const MiniStatusIcon = styled(MiniStatusBaseIcon)`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--statusColor);
    border: 1px solid var(--statusColor);
    box-shadow: inset -2px -2px 5px 1px ${CSS.colors.shadow};
    border-radius: ${CSS.borderRadius}px;
`;

const MiniStatusParentIcon = styled(MiniStatusBaseIcon)`
    color: var(--statusColor);
    font-size: 12px;
`;

const Status = React.memo<{
    id: string;
    design?: "normal" | "mini";
    showChildren?: boolean;
    onlyShowChildren?: boolean;
    statusToShow?: INodeStateStyle[];
}>(({ id, design = "normal", showChildren = false, onlyShowChildren = false, statusToShow = ["error", "warn"] }) => {
    const nodeState = useMapState((state) => selectors.getNodeById(state, id)?.nodeState, [id]);
    const getBranchWorstStatus = React.useMemo(() => makeGetWorstBranchStatus(), []);
    const getBranchWorstStatusSelf = React.useMemo(() => makeGetWorstBranchStatus(), []);

    const { status, showOwnStatus } = useMapState((state) => {
        const isCurrentBranch = getCurrentNodeBranchIds(state).includes(id);
        const isOwnStatus = !showChildren || isCurrentBranch || !statusToShow.includes(getBranchWorstStatus({ state, nodeId: id, includeSelf: false })); // last condition: no error in children
        return {
            status: isOwnStatus ? getNodeStateStyle(nodeState) : getBranchWorstStatusSelf({ state, nodeId: id, includeSelf: true }), // else: worst error in self + children
            showOwnStatus: isOwnStatus,
        };
    }, [id, nodeState]);

    const text = t(`node.status.${nodeState?.toUpperCase()}`);

    return (
        nodeState && design == "normal"
            ? (
                <StatusBadge className={getClassNames(["status", design])}>
                    {((() => {
                        const inTransition = getNodeStateInTransition(nodeState) ? <><LoadingSpinner.Inline data-test="" />{" "}</> : null;
                        switch (getNodeStateStyle(nodeState)) {
                            case "error": return <Badges.Error>{inTransition}{text}</Badges.Error>;
                            case "warn": return <Badges.Warn>{inTransition}{text}</Badges.Warn>;
                            case "success": return <Badges.Success>{inTransition}{text}</Badges.Success>;
                            case "ok": return <Badges.Light>{inTransition}{text}</Badges.Light>;
                            case "unknown": return <Badges.DarkAlt>{inTransition}{text}</Badges.DarkAlt>;
                        }
                    })())}
                </StatusBadge>
            )
            : (
                (() => {
                    if (showOwnStatus && !onlyShowChildren && statusToShow.includes(status)) {
                        return <MiniStatusIcon className={status} title={text} />;
                    } else if (!showOwnStatus && statusToShow.includes(status)) {
                        return <MiniStatusParentIcon className={`fa fa-arrow-circle-down ${status}`} />;
                    }
                    return null;
                })()
            )
    );
});

export default Status;

const ConnexionIcon = styled.i`
    opacity: 70%;
    font-size: 12px;
    &.normal {
        font-size: 18px;
    }
    &.warn { color: ${CSS.colors.yellow}; }
    &.error { color: ${CSS.colors.red}; }
    &.success { color: ${CSS.colors.green}; }
`;

const connexionStatusErrors = ["DISCONNECTED", "DISCONNECTED_RETRYING"] as IConnexionStatusState[];
export const StatusConnexion = React.memo<{
    id: string; showChildren?: boolean;
    design?: "normal" | "mini";
    statusToShow?: IConnexionStatusState[];
}>(({ id, design = "normal", showChildren = false, statusToShow = ["DISCONNECTED", "DISCONNECTED_RETRYING"] }) => {
    const getBranchWorstConnexionStatus = React.useMemo(() => makeGetWorstBranchConnexionStatus(), []);

    const { connexionStatus, showOwnConnexionStatus } = useMapState((state) => {
        const isCurrentBranch = getCurrentNodeBranchIds(state).includes(id);
        const isOwnConnexionStatus = !showChildren || isCurrentBranch || !statusToShow.includes(getBranchWorstConnexionStatus({ state, nodeId: id, includeSelf: false }));
        return {
            connexionStatus: isOwnConnexionStatus ? selectors.getNodeConnexionStatusById(state, id)?.status : getBranchWorstConnexionStatus({ state, nodeId: id, includeSelf: true }),
            showOwnConnexionStatus: isOwnConnexionStatus,
        };
    }, [id]);

    const [iconName, iconState] = React.useMemo((): [string, INodeStateStyle] => {
        if (!statusToShow.includes(connexionStatus)) {
            return [null, null];
        } else if (!showOwnConnexionStatus && connexionStatusErrors.includes(connexionStatus)) {
            return ["cloud_download", "ok"];
        } else {
            switch (connexionStatus) {
                case "DISCONNECTED": return ["cloud_off", "error"];
                case "DISCONNECTED_RETRYING": return ["sync", "warn"];
                case "CONNECTED": return ["cloud_done", "success"];
            }
        }
    }, [connexionStatus, showOwnConnexionStatus, statusToShow]);

    return iconName && <ConnexionIcon className={`material-icons ${design} ${iconState}`}>{iconName}</ConnexionIcon>;
});