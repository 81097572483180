// FIXME: rename this file requests.ts (with an s)
import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_KPI_DATAIO } from "js/constants";
import _ from "lodash";
import * as mocks from "./mocks";
import { IKpiDataIO } from "./model";
import { actionsCreators } from "./slices";

export const getDataIOsThrottled = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (getState().kpiDataIOs) return getState().kpiDataIOs;
            return _.values(mocks.getDataIOs());
        },
        url: API_PREFIX_KPI_DATAIO,
        onSuccess: (kpiDataIOs: IKpiDataIO[]) => {
            dispatch(actionsCreators.set(kpiDataIOs));
        },
    })
), 10 * 1000);

export const saveKpiDataIOByMachineNodeId = (machineNodeId: string, kpiDataIOs: IKpiDataIO[]) => (dispatch: IStoreDispatch) => ajaxRequest({
    serverLessResolve: () => kpiDataIOs,
    method: "PATCH",
    data: kpiDataIOs,
    url: `${API_PREFIX_KPI_DATAIO}?machineNodeId=${machineNodeId}`,
    showAjaxLoading: true,
    onSuccess: ((returnedKpiDataIOs: IKpiDataIO[]) => {
        dispatch(actionsCreators.patch({ kpiDataIOs: returnedKpiDataIOs, machineNodeId }));
    }),
}).promise;