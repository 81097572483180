import { Cache } from "@comact/crc";
import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { IKpiDataIO } from "./model";
const rand = createSeeder();

interface IGetKpiDataIO extends Partial<IKpiDataIO> { id: string; }
export const getKpiDataIO = (partialData: IGetKpiDataIO): IKpiDataIO => {
    rand.seed("kpiDataIO_" + partialData.id);
    return {
        contextId: "1",
        definitionId: "1",
        factor: 0,
        index: 0,
        machineNodeId: "1",
        patternKey: "1",
        nodeId: "1",
        ...partialData,
    };
};

export const getDataIOs = () => (
    Cache.remember("allKpiDataIOs_", () => (
        _.times(50, (index) => getKpiDataIO({
            id: (index + 1).toString(),
            factor: index + 1,
            machineNodeId: "" + ((index % 4) + 1),
            patternKey: "pattern-" + ((index % 20) + 1),
            contextId: "" + (1000000 + ((index % 19) + 1)),
            definitionId: "Definition 1[Pattern " + ((index % 20) + 1) + "]",
            index,
        }))
    ))
);