import { app, t } from "@comact/crc";
import _ from "lodash";
import * as requests from "../request";
import * as selectors from "../selectors";
import * as editSelectors from "./selectors";
import { actionsCreators } from "./slices";
import * as validation from "./validation";

export const startEditByMachineNodeId = (machineNodeId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const definitions = _.cloneDeep(selectors.getKpiDataIOsByMachineNodeId(getState(), machineNodeId));
    dispatch(actionsCreators.startEdit({ machineNodeId, kpiDataIOs: definitions }));
};

const applyMachine = (machineNodeId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const newData = _.values(editSelectors.getKpiDataIOsEditorByMachineNodeId(getState(), machineNodeId));
    const validations = validation.validateAllKpiDataIOs(_.keyBy(newData, ({ id }) => id));
    if (_.isEmpty(validations)) {
        return dispatch(requests.saveKpiDataIOByMachineNodeId(machineNodeId, newData))
            .then(() => dispatch(actionsCreators.stopEdit({ machineNodeId })));
    } else {
        return app.alert({
            title: t("kpiDataIO.modals.notApplyable.title"),
            description: t("kpiDataIO.modals.notApplyable.description"),
        });
    }
};

export const creatorsByMachine = (machineNodeId: string) => ({
    create: () => actionsCreators.createOne({ machineNodeId }),
    apply: () => applyMachine(machineNodeId),
    cancelEdit: () => actionsCreators.stopEdit({ machineNodeId }),
});

export const creatorsByKpiDataIO = (machineNodeId: string, id: string) => ({
    deleteKpiDataIO: () => actionsCreators.deleteOne({ machineNodeId, id }),
    changeKpiDefinition: (patternKey: string, definitionId: string) => actionsCreators.setOne({ machineNodeId, id, kpiDataIO: { patternKey, definitionId } }),
    changeContext: (contextId: string) => actionsCreators.setOne({ machineNodeId, id, kpiDataIO: { contextId } }),
    changeScanner: (nodeId: string) => actionsCreators.setOne({ machineNodeId, id, kpiDataIO: { nodeId } }),
    changeFactor: (factor: number) => actionsCreators.setOne({ machineNodeId, id, kpiDataIO: { factor } }),
    changeIndex: (index: number) => actionsCreators.setOne({ machineNodeId, id, kpiDataIO: { index } }),
});

export type IKpiDataIOCreators = typeof actionsCreators.setOne;
export type IByMachineCreators = ReturnType<typeof creatorsByMachine>;
export type IByKpiDataIOCreators = ReturnType<typeof creatorsByKpiDataIO>;
