import { ajaxRequest, getLocalizedText, getThrottleAjax } from "@comact/crc";
import produce from "immer";
import { requests as batchRequests } from "js/batches";
import { API_PREFIX_KPI_PATTERNS } from "js/constants";
import { requests as databaseDefinitionsRequests } from "js/databaseDefinitions";
import { requests as contextDefinitionsRequests } from "js/kpis/contextDefinitions";
import _ from "lodash";
import slugify from "slugify";
import { NodesModule } from "../../node";
import * as mocks from "./mocks";
import { IKpiPattern } from "./model";
import { actionsCreators } from "./slices";

export const saveKpiPattern = (kpiPattern: IKpiPattern, isNew: boolean) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: isNew ? "POST" : "PUT",
        data: isNew ? { ...kpiPattern, id: null, uniqueName: `${slugify(getLocalizedText(kpiPattern.title, true))}-${Date.now()}` } : kpiPattern,
        serverLessResolve: () => kpiPattern,
        url: API_PREFIX_KPI_PATTERNS + (isNew ? "" : `/${kpiPattern.id}`),
        showAjaxLoading: true,
        onSuccess: ((returnedKpiPattern: IKpiPattern) => {
            dispatch(actionsCreators.patch([returnedKpiPattern]));
        }),
    }).promise
);

export const deleteKpiPattern = (id: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const kpiPattern = getState().kpiPatterns[id];
    if (!kpiPattern) return Promise.reject();
    return ajaxRequest({
        method: "DELETE",
        serverLessResolve: () => kpiPattern,
        url: `${API_PREFIX_KPI_PATTERNS}/${kpiPattern.id}`,
        showAjaxLoading: true,
        onSuccess: ((returnedKpiPattern: IKpiPattern) => {
            dispatch(actionsCreators.delete([returnedKpiPattern.id]));
        }),
    }).promise;
};

export const duplicateKpiPattern = (id: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const kpiPattern = getState().kpiPatterns[id];
    if (!kpiPattern) return Promise.reject();

    const duplicated = produce(kpiPattern, (draft) => {
        draft.id = null;
        draft.modificationDate = Date.now();
        draft.uniqueName = draft.uniqueName + "_duplicated";
        draft.system = false;
        draft.title.values = _.mapValues(draft.title.values, (v) => v ? v + " *" : null);
    });
    return dispatch(saveKpiPattern(duplicated, true));
};

export const getKpiPatternsThrottled = getThrottleAjax((millId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (_.size(getState().kpiPatterns) > 0) return getState().kpiPatterns;
            return _.values(mocks.getKpiPatterns());
        },
        url: process.env.EXEC_MODE == "icp"
            ? API_PREFIX_KPI_PATTERNS + `?nodeId=${millId}`
            : API_PREFIX_KPI_PATTERNS,
        onSuccess: (kpiPatterns: IKpiPattern[]) => (
            Promise.all([
                NodesModule.getRequests(dispatch).fetchAllNodes().promise,
                dispatch(databaseDefinitionsRequests.getInfluxDatabaseDefinitionsThrottled()).promise,
                dispatch(batchRequests.getBatchDefinitions())?.promise,
                dispatch(contextDefinitionsRequests.getAllContextDefinitions(millId)).promise,
            ]).then(() => {
                dispatch(actionsCreators.set(
                    _(kpiPatterns)
                        .map((k) => ({ ...k, millNodeId: millId })) // ajout du millNodeId pour la différenciation
                        .keyBy((k) => k.id)
                        .value()
                ));
            })
        ),
    })
), 10 * 1000);
