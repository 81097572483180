import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IIntervalCategories, IIntervalCategory } from "./model";

declare global{
    interface IStoreState {
        intervalCategories: IIntervalCategories;
        intervalCategoriesEdit: IIntervalCategories;
    }
}

const useStats = "intervalCategories";

const intervalCategories = createSlice({
    name: "intervalCategories",
    initialState: {} as IIntervalCategories,
    reducers: {
        set: (state, { payload }: PayloadAction<IIntervalCategories>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ name }) => name), useStats })
        ),
        setByType: (state, { payload }: PayloadAction<Pick<IIntervalCategory, "name" | "intervals">>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: { [payload.name]: { ...state[payload.name], intervals: payload.intervals } }, useStats })
        ),
    },
});

const useStatsEdit = "intervalCategoriesEdit";

const intervalCategoriesEdit = createSlice({
    name: "intervalCategoriesEdit",
    initialState: {} as IIntervalCategories,
    reducers: {
        patchEdit: (state, { payload }: PayloadAction<IIntervalCategory[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ name }) => name), useStats: useStatsEdit })
        ),
        setOne: (state, { payload }: PayloadAction<Pick<IIntervalCategory, "name" | "intervals">>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: { [payload.name]: { ...state[payload.name], intervals: payload.intervals } }, useStats: useStatsEdit })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: useStatsEdit })
        ),
        deleteAll: (state) => (
            Utils.slices.deleteAll({ state, useStats: useStatsEdit })
        ),
    },
});

export const actionsCreators = {
    ...intervalCategories.actions,
    ...intervalCategoriesEdit.actions,
};

export default {
    [intervalCategories.name]: intervalCategories.reducer,
    [intervalCategoriesEdit.name]: intervalCategoriesEdit.reducer,
};