import { getTimeMilli } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { IMachineCodecNode } from "../node/model";
import { IOptimizerConfig, IOptimizerConfigGroup, isActiveConfiguration } from "./model";

export const getAllByConfigIdDesc = memoize(
    ({ optimizerConfigs }: IStoreState) => _.orderBy(optimizerConfigs, ({ configId }) => configId, ["desc"])
);

export const hasMultiplePlcRef = (state: IStoreState) => {
    const refs = _.map(state.optimizerConfigs, (c) => c.plcRef)
        .filter((ref) => ref != 0);
    return refs.length > 0;
};

export const getByConfigIdDesc = memoize(({ state: { optimizerConfigs }, name, type, plcRef }: {
    state: IStoreState;
    name: IOptimizerConfig["name"];
    type: IOptimizerConfig["type"];
    plcRef: number;
}) => (
    _.chain(optimizerConfigs)
        .filter((c) => c.name == name && c.plcRef == plcRef && c.type == type)
        .orderBy(({ configId }) => configId, ["desc"])
        .value()
));

export const isOptimizerConfigsLoaded = ({ optimizerConfigs }: IStoreState) => !!optimizerConfigs;

export const getConfigById = ({ optimizerConfigs }: IStoreState, configId: string) => optimizerConfigs[configId];

export const makeGetPreviousConfig = () => memoize(({ state: { optimizerConfigs }, configId }: { state: IStoreState; configId: string; }) => {
    const config = optimizerConfigs[configId];

    const ordered = _(optimizerConfigs)
        .filter((c) => c.type == config.type && c.name == config.name && c.plcRef == config.plcRef)
        .orderBy((c) => c.configId, ["desc"])
        .value();
    const index = _.findIndex(ordered, (c) => c.configId == configId);
    return ordered[index + 1];
});

export const makeGetAllByGroup = () => memoize(({ state: { nodes, optimizerConfigs }, nodeId }: { state: IStoreState; nodeId: string; }) => {
    const currentNodeMachine = nodes[nodeId] as IMachineCodecNode;

    return _.reduce(optimizerConfigs, (group, config) => {
        const id = `${_.startCase(config.type)} - ${config.name} - ${config.plcRef}`;
        if (!group[id]) group[id] = { id, name: config.name, plcRef: config.plcRef, active: false, type: config.type, configCount: 0, startDate: null, endDate: null };
        if (!group[id].active && isActiveConfiguration(config, currentNodeMachine)) {
            group[id].active = true;
        }
        group[id].configCount++;
        const activationTimestamp = getTimeMilli(config.activationTimestamp);
        if (group[id].startDate == null || activationTimestamp < getTimeMilli(group[id].startDate)) {
            group[id].startDate = config.activationTimestamp;
        }
        if (activationTimestamp > getTimeMilli(group[id].endDate)) {
            group[id].endDate = config.activationTimestamp;
        }

        return group;
    }, {} as Record<string, IOptimizerConfigGroup>);
});