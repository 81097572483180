import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_CLOUD_FILES } from "js/constants";
import { ICloudFile } from "./model";
import { actionsCreators } from "./slices";

export const getCloudFiles = getThrottleAjax((nodeId: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => (
            import("./mocks").then((m) => m.getCloudFiles())
        ),
        url: `${API_PREFIX_CLOUD_FILES}/${nodeId}`,
        onSuccess: (cloudFiles: ICloudFile[]) => {
            dispatch(actionsCreators.set(cloudFiles));
        },
    })
), 10 * 1000);