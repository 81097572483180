import { app, ProgressBar } from "@comact/crc";
import { CSS, mixins, styled } from "@comact/crc/modules/kit";
import { IKpiZone } from "js/kpis/kpiObjectives";
import _ from "lodash";
import * as React from "react";
import tinycolor from "tinycolor2";

export interface ILinearGaugeProps {
    title: string;
    value: number;
    min: number;
    max: number;
    targetValue: number;
    unit: string;
    zones: IKpiZone[];
    showTitle?: boolean;
    showUnit?: boolean;
    showMinMax?: boolean;
}

/**
 * Linear Gauge
 */
const LinearGaugeStyled = styled("div")`
    display: grid;
    grid-template-areas:
        "progressbar progressbar"
        "targetPos targetPos"
        "min max";
    grid-template-rows: 1fr 0 min-content;
    height: 100%;
    position: relative;
    text-align: left;
    > .progressBar{
        grid-area: progressbar;
        > .barContainer{
            border: 1px solid darken(${CSS.colors.grey}, 3%);
            border-radius: 4px;
            text-shadow: 1px 1px 3px ${CSS.colors.black};
            display: grid;
            height: 100%;
            > .indicator{
                height: 100%;
                border-right: 1px solid ${tinycolor(CSS.colors.white).setAlpha(0.3).toRgbString()};
                border-left: 0px solid white;
            }
            > .bar{
                z-index: 2;
                position: relative;
                height: 100%;
                > .barFragment{
                    height: 100%;
                    transition: width .7s ease-out, color 1s ease-out;
                }
            }
            > .rightLabel{
                color: ${CSS.colors.white};
                font-weight: 700;
                > div{
                    > .unit{
                        font-size: .9em;
                        font-weight: 300;
                        opacity: .7;
                    }
                }
            }
        }
    }
    > .targetPos{
        grid-area: targetPos;
        position: relative;
        > .pointer{
            position: absolute;
            z-index: 3;
            top: 0;
            font-size: .75em;
            &::before{
                ${mixins.FontAwesome}
                content: "\f0d8";
                color: ${CSS.colors.white};
                transform: translate(-50%, -75%);
            }
        }
    }
    > .min, > .max{
        font-size: .9em;
        font-weight: 300;
        opacity: .6;
        &.min{
            grid-area: min;
            text-align: left;
        }
        &.max{
            grid-area: max;
            text-align: right;
        }
    }
`;
export const LinearGauge = React.memo<ILinearGaugeProps>(({ title, value, min, max, unit, targetValue, zones, showTitle = true, showUnit = true, showMinMax = false }) => {
    const ZONE_COLORS = React.useMemo(() => ({
        empty: tinycolor(CSS.colors.grey).setAlpha(0).desaturate(30).toRgbString(),
        normal: tinycolor(CSS.colors.green).setAlpha(0.1).desaturate(30).toRgbString(),
        warn: tinycolor(CSS.colors.yellow).setAlpha(0.1).desaturate(30).toRgbString(),
        error: tinycolor(CSS.colors.red).setAlpha(0.15).desaturate(30).toRgbString(),
    }), []);

    const format = React.useMemo(() => (new Intl.NumberFormat(app.language).format), []);

    const CURRENT_COLORS = React.useMemo(() => ({
        empty: tinycolor(CSS.colors.blue).toRgbString(),
        normal: tinycolor(CSS.colors.green).toRgbString(),
        warn: tinycolor(CSS.colors.yellow).toRgbString(),
        error: tinycolor(CSS.colors.red).toRgbString(),
    }), []);

    const rightLabel = (
        <div>
            {format(value)}
            {showUnit && <span className="unit">{" "}{unit}</span>}
        </div>
    );

    const progress = _.clamp((value - min) / (max - min), 0, 1);
    const targetPos = targetValue ? (targetValue - min) / (max - min) : null;

    const indicators: { className?: string; start: number; end: number; color?: string; }[] = zones.map((o) => ({
        color: ZONE_COLORS[o.value],
        start: _.clamp((o.min - min) / (max - min), 0, 1),
        end: _.clamp((o.max - min) / (max - min), 0, 1),
    }));
    const clampedValue = _.clamp(value, min, max);
    const currentZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    const color = currentZone ? CURRENT_COLORS[currentZone.value] : CURRENT_COLORS.empty;
    return (
        <LinearGaugeStyled className="linearGauge">
            <ProgressBar
                data={[{ color, progress }]}
                indicators={indicators}
                leftLabel={showTitle && title ? title : ""}
                rightLabel={rightLabel}
            />
            {targetPos &&
                <div className="targetPos"><div style={{ left: targetPos * 100 + "%" }} className="pointer" /></div>
            }
            {showMinMax && (
                <>
                    <div className="max">{max}</div>
                    <div className="min">{min}</div>
                </>
            )}
        </LinearGaugeStyled>
    );
});

/**
 * Linear Gauge Simple
 */
const LinearGaugeSimpleStyled = styled("div")`
    display: grid;
    height: 100%;
    position: relative;
    text-align: left;
    > .progressBar{
        > .barContainer{
            border-bottom: 1px solid ${tinycolor(CSS.colors.grey).brighten(5).toRgbString()};
            .dark &{
                border-bottom: 1px solid ${tinycolor(CSS.colors.greyDark).brighten(10).toRgbString()};
            }
            .darker &{
                border-bottom: 1px solid ${tinycolor(CSS.colors.greyDarker).brighten(5).toRgbString()};
            }
            border-radius: none;
            background-color: transparent;
            box-shadow: none;
            text-shadow: 1px 1px 3px ${CSS.colors.black};
            display: grid;
            height: 100%;
            > .indicator{
                height: 100%;
            }
            > .bar{
                z-index: 2;
                position: relative;
                height: 100%;
                > .barFragment{
                    height: 100%;
                    transition: width .7s ease-out, color 1s ease-out;
                }
            }
            > .rightLabel{
                color: ${CSS.colors.white};
                font-weight: 700;
                > div{
                    padding: .05em .3em;
                    border-radius: ${CSS.borderRadius};
                    > .unit{
                        font-size: .9em;
                        font-weight: 300;
                        opacity: .7;
                    }
                }
            }
        }
    }
    > .targetPos{
        position: relative;
        height: 0;
        > .pointer{
            position: absolute;
            z-index: 3;
            top: 0;
            font-size: .75em;
            &::before{
                ${mixins.FontAwesome}
                content: "\f0d8";
                color: ${CSS.colors.white};
                transform: translate(-50%, -75%);
            }
        }
    }
`;
export const LinearGaugeSimple = React.memo<ILinearGaugeProps>(({ title, value, min, max, unit, targetValue, zones, showTitle = true, showUnit = true }) => {
    const CURRENT_COLORS = React.useMemo(() => ({
        empty: tinycolor(CSS.colors.blue).setAlpha(0).toRgbString(),
        normal: tinycolor(CSS.colors.green).toRgbString(),
        warn: tinycolor(CSS.colors.yellow).toRgbString(),
        error: tinycolor(CSS.colors.red).toRgbString(),
    }), []);

    const format = React.useMemo(() => (new Intl.NumberFormat(app.language).format), []);

    const clampedValue = _.clamp(value, min, max);
    const currentZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    const color = currentZone ? CURRENT_COLORS[currentZone.value] : CURRENT_COLORS.empty;

    const targetPos = targetValue ? (targetValue - min) / (max - min) : null;
    const progress = _.clamp((value - min) / (max - min), 0, 1);

    const rightLabel = (
        <div style={{ backgroundColor: color }}>
            {format(value)}
            {showUnit && <span className="unit">{" "}{unit}</span>}
        </div>
    );

    return (
        <LinearGaugeSimpleStyled className="linearGaugeSimple">
            <ProgressBar
                data={[{ color: tinycolor(CSS.colors.black).setAlpha(0.1).toRgbString(), progress }]}
                leftLabel={showTitle && title ? title : ""}
                rightLabel={rightLabel}
            />
            {targetPos &&
                <div className="targetPos"><div style={{ left: targetPos * 100 + "%" }} className="pointer" /></div>
            }
        </LinearGaugeSimpleStyled>
    );
});
