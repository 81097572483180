import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_DOWNTIMES } from "../constants";
import { IDowntime } from "./model";
import { actionsCreators } from "./slices";

export const getDowntimesInRange = getThrottleAjax((nodeId: string, startDate: number, endDate: number) => (dispatch, getState) => {
    const start = process.env.EXEC_MODE == "icp" ? new Date(startDate).toISOString() : startDate;
    const end = process.env.EXEC_MODE == "icp" ? new Date(endDate).toISOString() : endDate;
    return (
        ajaxRequest({
            serverLessResolve: () => getState().downtimes || import("./mocks").then((m) => m.getDowntimes()),
            // icp takes dates in string and iep in number
            url: `${API_PREFIX_DOWNTIMES}/node/${nodeId}?from=${start}&to=${end}`,
            onSuccess: (downtimes: IDowntime[]) => {
                dispatch(actionsCreators.set(downtimes));
                return downtimes;
            },
        })
    );
}, 10 * 1000);

export const getDowntimesRecursive = getThrottleAjax((nodeId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().downtimes || import("./mocks").then((m) => m.getDowntimes()),
        url: `${API_PREFIX_DOWNTIMES}/node/${nodeId}?recursive=true`,
        onSuccess: (downtimes: IDowntime[]) => {
            dispatch(actionsCreators.set(downtimes));
        },
    })
), 10 * 1000);

export const editDowntime = getAction((downtime: IDowntime) => async (dispatch) => {
    const { id, ...data } = downtime;
    const isNew = id.startsWith("new");
    return ajaxRequest({
        serverLessResolve: () => ({ ...data, id: isNew ? (new Date()).toISOString() : id }),
        method: isNew ? "POST" : "PUT",
        data: isNew ? data : { id, ...data }, // remove id when new
        showAjaxLoading: true,
        serverLessTimeout: 1000,
        url: isNew ? `${API_PREFIX_DOWNTIMES}` : `${API_PREFIX_DOWNTIMES}/${id}`,
        onSuccess: ((downtimeServer: IDowntime) => {
            dispatch(actionsCreators.patch([downtimeServer]));
            return downtimeServer;
        }),
    });
});