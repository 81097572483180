import { ILocalizedString, getLocalizedText } from "@comact/crc/";
import { IKpiDefinition, createNewKpiDefinition } from "js/kpis/kpiDefinitions";
import _ from "lodash";
import { IMachineCodecNode, IMachinesModel } from "../../node";

export type IIKpiPatternObjectiveTimeUnit = "HOURS" | "MINUTES" | "SECONDS";

export type IExecutionConfiguration = {
    machineType: IMachineCodecNode["machine"]; // model
    scanners: string[]; // indexes
};

export interface IKpiPattern {
    readonly id: string | null;
    readonly modificationDate: number; // date de modification
    title: ILocalizedString;
    uniqueName: string; // also called patternKey sometimes
    machinesModel: IMachinesModel;
    machinesScanner: string;
    tags: string[];
    formula: string; // la formule écrite
    filterFormula: string; // formule pour "where"
    order: string; // formule pour le "sorting"
    filterRatio: boolean;
    groups: string[]; // comment on regroupe les résultats (collection seulement)
    groupRatio: boolean;
    precision: number;
    unitType: string;
    unitLabel: ILocalizedString;
    database: string;
    collection: boolean;
    useNumberContext: boolean;
    kpiDefinitions: {
        default: IKpiDefinition; // If it's a collection, default is a template and not usable
        [kpiID: string]: IKpiDefinition; // only for collections
    } | null;
    objectiveTimeBased: boolean;
    objectiveTimeUnit: IIKpiPatternObjectiveTimeUnit;
    system: boolean;
    millNodeId?: string;
    nodeIds: string[]; // allowed node ids for this kpi
}

export type IKpiPatterns = { [id: string]: IKpiPattern; };

export const createNewKpiPattern = (partialData: Partial<IKpiPattern> = {}): IKpiPattern => ({
    id: "new_" + Date.now(),
    uniqueName: null, // Will be set before sending the request to the server
    machinesModel: null,
    machinesScanner: null,
    tags: [],
    formula: "",
    filterFormula: "",
    filterRatio: false,
    order: null,
    modificationDate: Date.now(),
    title: { values: {} },
    groups: [],
    groupRatio: false,
    precision: 1,
    unitType: null,
    unitLabel: { values: {} },
    collection: false,
    useNumberContext: false,
    objectiveTimeBased: false,
    objectiveTimeUnit: "HOURS",
    kpiDefinitions: {
        default: createNewKpiDefinition(),
    },
    database: "",
    ...partialData,
    system: false,
    nodeIds: [],
});

// Sorts the kpiDefinitions by default first then by alphabetical order
export const getSortedKpiPatternDefinitions = (kpiPattern: IKpiPattern) => _.sortBy(kpiPattern.kpiDefinitions, (k) => k.id == "default" ? "" : getLocalizedText(k.title));

/**
 * For kpi collections, uniqueId format is {kpiPatternId}
 * For other kpis (normal or kpi definitions), id format is {kpiPatternId},{definitionId}
 */
export const createKpiDefinitionUniqueId = (kpiPatternId: IKpiPattern["uniqueName"], definitionId: IKpiDefinition["id"], isCollection: boolean) => (
    isCollection && (!definitionId || definitionId == "default") // Initially, kpi collections have definitionId="default", but later their definitionId=null
        ? kpiPatternId
        : `${kpiPatternId},${definitionId || "default"}`
);

export const getKpiPatternValue = (kpiPatternValue: string) => {
    if (!kpiPatternValue) return null;
    const [patternKey, definitionId] = kpiPatternValue.split(",");
    const isCollection = !definitionId;
    return { patternKey, definitionId: isCollection ? null : definitionId, isCollection };
};
