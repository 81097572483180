import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IDowntime, IDowntimes } from "./model";

declare global {
    interface IStoreState {
        downtimes: IDowntimes;
    }
}

const useStats = "downtimes";
const isEqual = (prev: IDowntime, next: IDowntime) => prev.modificationDate == next.modificationDate;

const downtimes = createSlice({
    name: "downtimes",
    initialState: null as IDowntimes,
    reducers: {
        set: (state, { payload }: PayloadAction<IDowntime[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patch: (state, { payload }: PayloadAction<IDowntime[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
    },
});

export const actionsCreators = {
    ...downtimes.actions,
};

export default {
    [downtimes.name]: downtimes.reducer,
};