import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import { API_PREFIX_CLOUD_AGENT } from "../constants";
import { ICloudAgentFilePending, ICloudAgentStatsServer } from "./model";
import { actionsCreators } from "./slices";

export const getCloudAgentStatItems = getThrottleAjax(() => (dispatch, getState) => (
    getState().system.cloudEnabled && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getCloudAgentStatItems()),
        url: `${API_PREFIX_CLOUD_AGENT}/stats/nodes?stats`,
        onSuccess: (cloudAgentStatItems: ICloudAgentStatsServer) => {
            dispatch(actionsCreators.setStats(cloudAgentStatItems));
        },
    })
), 10 * 1000);

export const getCloudAgentFilesPending = getThrottleAjax(() => (dispatch, getState) => (
    getState().system.cloudEnabled && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getCloudAgentFilesPending()),
        url: `${API_PREFIX_CLOUD_AGENT}/stats/files/pending`,
        onSuccess: (cloudAgentFilesPending: ICloudAgentFilePending[]) => {
            dispatch(actionsCreators.setFilesPending(cloudAgentFilesPending));
        },
    })
), 5 * 1000);