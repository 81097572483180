import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

export interface IInterval {
    readonly id: string;
    minimum: number;
    maximum: number;
    type: number;
    name: ILocalizedString;
}

export interface IGap {
    min?: number;
    max?: number;
}

export interface IIntervalCategory {
    readonly name: string; // Used as uid
    readonly type: number;
    readonly unit: string;
    readonly min: number;
    readonly max: number;
    intervals: IInterval[];
}

export interface IIntervalCategories { [name: string]: IIntervalCategory; }

export interface IIntervalError {
    overlappingIntervals?: IInterval[];
    minGreaterThanOrEqualToMax?: boolean;
    invalidNameKeys?: string[];
}

export interface IActionIntervalCategories {
    type: string;
    intervalCategory?: Partial<IIntervalCategory>;
    allIntervalCategories?: { [name: string]: IIntervalCategory; };
}

export interface IActionIntervalCategoryEdit {
    type: string;
    intervalCategory?: Partial<IIntervalCategory>;
}

export const createInterval = (id: string, type: number): IInterval => (
    {
        id,
        minimum: 0.0,
        maximum: 0.0,
        type,
        name: {
            values: {
                en: "New interval",
                fr: "Nouvel intervalle",
                fi: "New interval",
                pt: "New interval",
            },
        },
    }
);

export const validateIntervals = (intervals: IInterval[]): { [uid: string]: IIntervalError; } => {
    const errors = {};
    const EQUALITY_THRESHOLD = 0.0005;

    intervals.forEach((interval) => {
        const intervalError: IIntervalError = {};

        if (interval.minimum >= interval.maximum - EQUALITY_THRESHOLD) intervalError.minGreaterThanOrEqualToMax = true;

        const overlappingIntervals = intervals.filter((otherInterval) => intervalsCollide(interval, otherInterval));
        if (overlappingIntervals.length > 0) intervalError.overlappingIntervals = overlappingIntervals;

        const invalidNameKeys = getInvalidNameKeys(interval);
        if (invalidNameKeys.length > 0) intervalError.invalidNameKeys = invalidNameKeys;

        if (Object.keys(intervalError).length > 0) errors[interval.id] = intervalError;
    });

    return errors;
};

export const sortIntervals = (intervals: IInterval[]): IInterval[] => _.sortBy(intervals, [({ minimum }: IInterval) => minimum != null ? minimum : 0]);

export const refreshOrder = (intervals: IInterval[]) => {
    const listToOrder = sortIntervals(intervals);
    const sortedOrder: number[] = [];

    listToOrder.forEach((interval, index) => {
        sortedOrder[interval.id] = index;
    });

    return sortedOrder;
};

export const getGaps = (sortedIntervals: IInterval[]): IGap[] => {
    const gaps: IGap[] = [];

    for (let i = 0; i < sortedIntervals.length - 1; i++) {
        const before = sortedIntervals[i];
        const after = sortedIntervals[i + 1];
        if (before.maximum < after.minimum) {
            gaps[i] = { min: before.maximum, max: after.minimum };
        }
    }

    return gaps;
};

const intervalsCollide = (a: IInterval, b: IInterval): boolean => !(a == b) && a.minimum < b.maximum && b.minimum < a.maximum;

export const INTERVAL_NAME_MAX_LENGTH = 36;

const getInvalidNameKeys = (interval: IInterval): string[] => {
    const { name: { values } } = interval;
    return Object.keys(values).filter((key) => ((lclzd) => lclzd == null || lclzd.length == 0 || lclzd.length > INTERVAL_NAME_MAX_LENGTH)(values[key].trim()));
};