import { getB2cPolicies, getMsalInstance, usePingUrlUntilReady, useSetInterval } from "@comact/crc";
import { CSS, LoadingSpinner, styled } from "@comact/crc/modules/kit";
import * as React from "react";
import videojs from "video.js";

const VideoJsStyled = styled.div`
  // absolutely needed for css to calculate the correct height
  flex-grow: 1;
  display: flex;
  align-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  overflow: hidden;
  > video-js {
    flex-grow: 1;
    display: flex;
    background-color: ${CSS.colors.grey};
    align-content: center;
    align-items: center;
    height: 100%;
    > video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const VideoJS = ({ source, type = "application/x-mpegURL" }: { source: string; type: string; }) => {
    const videoRef = React.useRef<HTMLDivElement>(null);

    const options = React.useMemo(() => ({
        autoplay: "muted",
        controls: true,
        responsive: true,
        children: {
            // Visit https://videojs.com/guides/components/#default-component-tree to check all options.
            controlBar: {
                volumePanel: false,
                playToggle: false,
                pictureInPictureToggle: false,
                liveDisplay: false,
            },
        },
        fluid: false,
        preload: "auto",
        sources: [{
            src: source,
            type,
        }],
    }), [source, type]);

    // for ICP only. get an access token to call authenticated API enpoints
    const [accessToken, setAccessToken] = React.useState<string>();
    if (process.env.EXEC_MODE == "icp") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSetInterval(() => {
            // every 60 seconds check if the token has changed
            fetchAccessToken().then(setAccessToken);
        }, 60 * 1000);
    }

    const urlReady = usePingUrlUntilReady(source, 500);

    React.useEffect(() => {
        if (!urlReady) return () => null;

        // for ICP only, wait until access token is available before creating the player
        if (process.env.EXEC_MODE == "icp" && !accessToken) return () => null;

        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement("video-js");

        videoElement.classList.add("vjs-big-play-centered");
        videoRef.current.appendChild(videoElement);

        const player = videojs(videoElement, options);
        player.autoplay(options.autoplay);
        player.loadMedia({ src: options.sources }, null);

        // for ICP. add access token to request headers
        if (process.env.EXEC_MODE == "icp") {
            // wait until the player is ready before registering custom hooks
            player.on("xhr-hooks-ready", () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                player.tech(true).vhs.xhr.onRequest((req: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    req.beforeSend = (xhr: any) => {
                        // add access token in the request header only if it's an api call
                        if (xhr.url.includes("/api/")) {
                            xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
                        }
                    };
                    return req;
                });
            });
        }

        return () => {
            if (!player.isDisposed()) {
                player.dispose();
            }
        };
    }, [options, videoRef, accessToken, source, urlReady]);

    return urlReady ? <VideoJsStyled ref={videoRef} /> : <LoadingSpinner.Absolute />;
};

const fetchAccessToken = async () => {
    const msalInstance = getMsalInstance();

    const result = await msalInstance.acquireTokenSilent({
        scopes: [getB2cPolicies().readScope],
        account: msalInstance.getActiveAccount(),
    });

    return result.accessToken;
};