// eslint-disable-next-line no-restricted-imports
import { IPermission, t, UserModule } from "@comact/crc";
import { INodeHierarchical as INodeHierarchicalTree } from "@comact/crc/modules/kit/Tree";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { createSelector } from "reselect";
import { ITemplate } from "../nodeTemplate";
import { getNodeStateStyle, IConnexionStatusState, IMillNode, INode, INodeHierarchical, INodes, INodeStateStyle } from "./model";

export const getAllNodes = memoize(({ nodes }: IStoreState) => nodes);

export const getNodeById = <T extends INode>(state: IStoreState, id: string): T => getAllNodes(state)?.[id] as T;

export const getNodeNamePath = (({ nodes }: IStoreState, nodeId: string, includeMill?: boolean) => {
    const node = nodes[nodeId];
    if (!node) return null;
    let nodeNamePath = node.name;
    let newParent = node;
    while (newParent) { // We want to build the node name path up to the mill
        newParent = nodes[newParent.parentId];
        if (!newParent || (!includeMill && newParent.templateName == "millCodec")) break;
        nodeNamePath = `${newParent.name} - ${nodeNamePath}`;
        if (newParent.templateName == "millCodec") break; // We stop after adding the mill name to the path
    }
    return nodeNamePath;
});

export const getNodeTemplateNameLocalized = ({ nodes }: IStoreState, nodeId: string) => t(`node.template.${nodes[nodeId]?.templateName || "unknown"}`);

export const getAllConnexionStatuses = memoize((state: IStoreState) => state.nodeConnexionStatuses);

export const getNodeConnexionStatusById = (state: IStoreState, id: string) => getAllConnexionStatuses(state)?.[id];

export const getNodeParentByChildId = <T extends INode>(state: IStoreState, childId: string): T => getAllNodes(state)[getAllNodes(state)[childId]?.parentId] as T;

export const getNodeFirstParentOfType = <T extends INode>(state: IStoreState, currentNodeId: string, templateName: INode["templateName"], includeSelf: boolean = true): T => {
    const allNodes = getAllNodes(state);
    let nextNode = allNodes?.[currentNodeId];
    if (!includeSelf) nextNode = allNodes?.[nextNode.parentId]; // don't check the current node in this case
    while (nextNode) {
        if (nextNode.templateName == templateName) return nextNode as T;
        nextNode = allNodes[nextNode.parentId]; // next parent
    }
    return null; // nothing found
};

export const getMillNode = (state: IStoreState, currentNodeId: string): IMillNode => (
    process.env.EXEC_MODE == "icp"
        ? getNodeFirstParentOfType<IMillNode>(state, currentNodeId, "millCodec", true)
        : _.values(getNodesByTemplateName<IMillNode>(state, "millCodec"))?.[0] // only 1 mill on cmoc
);

export const getNodesChildrenOfType = <T extends INode>(state: IStoreState, currentNodeId: string, templateNames: INode["templateName"][]): Record<string, T> => (
    _.pickBy(getAllNodes(state), (node) => node.parentId == currentNodeId && _.includes(templateNames, node.templateName)) as Record<string, T>
);

export const getNodesChildrenOfTypeRecursive = <T extends INode>(
    state: IStoreState,
    currentNodeId: string,
    templateNames: INode["templateName"][],
    includeSelf: boolean = false
): Record<string, T> => {
    if (!currentNodeId) return null;
    const allNodes = getAllNodes(state);
    const foundNodes: Record<string, T> = {};

    const findNodes = (currentNode: INode) => {
        const childNodes = _.pickBy(allNodes, (node) => node.parentId == currentNode.id);
        _.forEach(childNodes, (node, key) => {
            if (!templateNames || _.includes(templateNames, node.templateName)) foundNodes[key] = node as T;
            findNodes(node);
        });
    };

    const currentNode = getNodeById(state, currentNodeId);

    if (includeSelf && (!templateNames || _.includes(templateNames, currentNode.templateName))) {
        foundNodes[currentNode.id] = currentNode as T;
    }

    findNodes(currentNode);

    return foundNodes;
};

export const getNodesChildrenRecursive = <T extends INode>(
    state: IStoreState,
    currentNodeId: string,
    includeSelf: boolean = false
): Record<string, T> => (
    getNodesChildrenOfTypeRecursive(state, currentNodeId, null, includeSelf)
);

export const getNodeTreeIds = createSelector(
    (state: IStoreState) => _.map(getNodesChildrenRecursive(state, state.currentMillNodeId), (n) => n.id),
    (treeNodeIds) => treeNodeIds
);

export const getNodeChildrenByParentId = (state: IStoreState, parentNodeId: string) => _.filter(getAllNodes(state), ({ parentId }) => parentId == parentNodeId);

export const getNodesByParentId = (state: IStoreState, parentId: string, neededPermissions?: IPermission[]): INodes => {
    const allNodes = getAllNodes(state);
    return (
        neededPermissions
            ? _.pickBy(allNodes, (n) => n.parentId == parentId && UserModule.selectors.hasPermission(state, neededPermissions, getMillNode(state, n.id)?.id))
            : _.pickBy(allNodes, (n) => n.parentId == parentId)
    );
};

export const getNodesByTemplateName = <T extends INode>(state: IStoreState, templateName: ITemplate["name"]): Record<string, T> => (
    _.pickBy(getAllNodes(state), (node) => node?.templateName == templateName) as Record<string, T>
);

export const getNodesHierarchical = memoize((state: IStoreState) => {
    const nodes: Record<string, INodeHierarchical> = _.mapValues(getAllNodes(state), (node) => ({ ...node, childrenIds: [] }));
    _.forEach(nodes, (node) => {
        if (node.parentId) {
            nodes[node.parentId]?.childrenIds.push(node.id);
        }
    });
    return nodes;
});

export const makeGetWorstBranchStatus = () => memoize(({ state, nodeId, includeSelf }: { state: IStoreState; nodeId: string; includeSelf?: boolean; }): INodeStateStyle => {
    const allBranchNodeIds: string[] = includeSelf ? [nodeId] : [];
    const nodes = getAllNodes(state);
    findAllChildrenIds(nodes, nodeId, allBranchNodeIds);
    const allStatus = _.map(allBranchNodeIds, (childrenId) => getNodeStateStyle(nodes[childrenId].nodeState));
    if (allStatus.includes("error")) return "error";
    else if (allStatus.includes("warn")) return "warn";
    else if (allStatus.includes("ok")) return "ok";
    else if (allStatus.includes("success")) return "success";
    else return "unknown";
});

export const makeGetWorstBranchConnexionStatus = () => memoize(({ state, nodeId, includeSelf }: { state: IStoreState; nodeId: string; includeSelf?: boolean; }): IConnexionStatusState => {
    const allBranchNodeIds: string[] = includeSelf ? [nodeId] : [];
    const nodes = getAllNodes(state);
    findAllChildrenIds(nodes, nodeId, allBranchNodeIds);
    const allStatus = _.map(allBranchNodeIds, (childrenId) => getNodeConnexionStatusById(state, childrenId)?.status);
    if (allStatus.includes("DISCONNECTED")) return "DISCONNECTED";
    else if (allStatus.includes("DISCONNECTED_RETRYING")) return "DISCONNECTED_RETRYING";
    else return "CONNECTED";
});

const findAllChildrenIds = (nodes: INodes, parentNodeId: string, allBranchIds: string[]) => {
    const childrenIds = _.chain(nodes)
        .filter(({ parentId }) => parentId == parentNodeId)
        .map(({ id }) => id)
        .value();
    allBranchIds.push(...childrenIds);
    _.forEach(childrenIds, (childrenId) => findAllChildrenIds(nodes, childrenId, allBranchIds));
    return childrenIds;
};

/**
 * Returns the ids of the nodes in the same branch as the node currently selected
 */
export const getCurrentNodeBranchIds = memoize((state: IStoreState) => {
    const { currentNodeId } = state;
    const allIds: string[] = [currentNodeId];
    const nodes = getAllNodes(state);
    let newParentId = currentNodeId;
    while (newParentId) {
        newParentId = nodes[newParentId].parentId;
        allIds.push(newParentId);
    }
    return _.reverse(allIds);
});

export const makeGetNodesHierarchicalTree = (neededPermissions?: IPermission[]) => memoize(({ state, allowedTemplateNames }: { state: IStoreState; allowedTemplateNames: ITemplate["name"][]; }) => {
    let allNodes = getAllNodes(state);
    if (allowedTemplateNames) {
        allNodes = _.pickBy(allNodes, (n) => allowedTemplateNames.includes(n.templateName));
    }

    // Remove nodes that you can't see because of missing permissions
    if (neededPermissions) {
        allNodes = _.pickBy(allNodes, (node) => UserModule.selectors.hasPermission(state, neededPermissions, getMillNode(state, node.id)?.id));
    }

    const nodes = _.mapValues(allNodes, (node) => ({
        id: node.id,
        name: node.name,
        parentId: node.parentId,
        type: null,
        childrenIds: [],
    } as INodeHierarchicalTree & { childrenIds: string[]; }));

    _.forEach(nodes, (node) => {
        if (node.parentId) {
            nodes[node.parentId]?.childrenIds.push(node.id);
        }
    });
    return nodes;
});

const _getNodesHierarchicalTree = makeGetNodesHierarchicalTree();
export const getNodesHierarchicalTree = (state: IStoreState) => _getNodesHierarchicalTree({ state, allowedTemplateNames: null });

/**
 * Get a simplified version of all nodes to cause less re-renders
 */
export const getAllNodeSimplified = memoize((state: IStoreState) => _.mapValues(getAllNodes(state), ({ id, name, parentId, templateId, templateName, templateVersion }) => (
    { id, name, parentId, templateId, templateName, templateVersion }
)));

export const getMillNameWithLocation = memoize(({ state, millId }: { state: IStoreState; millId: string; }) => {
    const location = getNodeFirstParentOfType(state, millId, "location");
    const name = getNodeById(state, millId).name;
    return location ? `${location.name} - ${name}` : name;
});