import { getLocalizedText, t } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { ILabelTypes } from "../labels/model";

export const getLabelGroupsByType = ({ labelGroups }: IStoreState, type: ILabelTypes) => {
    if (!labelGroups) return null;
    return _.chain(labelGroups)
        .filter((labelGroup) => labelGroup.type == type)
        .keyBy(({ id }) => id)
        .value();
};

export const getLabelGroupByIdAndType = ({ labelGroups }: IStoreState, id: string, type: ILabelTypes) => _.find(labelGroups, (g) => g.id == id && g.type == type);

export const makeGetLabelsGroupNameByType = (type: ILabelTypes) => memoize(({ labelGroups }: IStoreState) => (
    _.chain(labelGroups)
        .filter((l) => l.type == type)
        .keyBy((l) => l.id)
        .mapValues((l) => getLocalizedText(l?.name))
        .value()
));

export const getGroupName = ({ labelGroups }: IStoreState, groupId: string) => {
    if (!labelGroups) return null;
    const group = labelGroups[groupId];
    return group ? getLocalizedText(group.name) : t("listBuilder.group.noGroup");
};
